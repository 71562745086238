import React, { useState, useMemo } from 'react'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import PartyDetailsContext from './PartyDetailsContext.js'
import PartyDetailsForm from './PartyDetailsForm.js'
import { components } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText.js'

const { CngDialog, CngDialogTitle } = components

function PartyDetailsDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  currentRowIdx,
  partyListField,
  setPartyListField,
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = ComplianceTranslationText()

  const contextValue = useMemo(
    () => ({
      closeDialog,
      showNotification,
      currentRowIdx,
      partyListField,
      setPartyListField,
      form: {
        isSubmitting,
        setSubmitting,
      },
    }),
    [
      closeDialog,
      showNotification,
      currentRowIdx,
      partyListField,
      setPartyListField,
      isSubmitting,
    ]
  )

  return (
    <PartyDetailsContext.Provider value={contextValue}>
      <CngDialog
        customDialogContent={
          <Paper>
            <PartyDetailsForm />
          </Paper>
        }
        dialogTitle={
          <>
            <b>{translatedTextsObject.addParty}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='md'
      />
    </PartyDetailsContext.Provider>
  );
}

export default PartyDetailsDialog
