import {
    AccordionSummary,
    Card,
    Grid
} from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import React, { useEffect, useState } from 'react'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { components } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText'
import DeclarantAutocompleteField from 'src/components/autocomplete/DeclarantAutocompleteField'

const {
    form: {
        adapter: {
        useFormAdapter: { useField, useFormikContext }
        },
        field: {
        CngCodeMasterAutocompleteField,
        CngTextField,
        CngSelectField
        }
    },
    CngGridItem,
    table: { useDefaultNotification }
} = components

const initialValues = Object.freeze({
  uniqueCrNo: '',
  carType: '',
  decType: '',
  particularsDeclaration: '',
  remarkLine: '',
  declarantId: '',
  payment: false
})

const FormBody = (props) => {
    const ComplianceTranslatedObject = ComplianceTranslationText()
    const [carType, setCarType] = useState('')
    const [inPaymentDec, setInPaymentDec] = useState(false)
    const { setFieldValue } = useFormikContext()
    const [codeMKey, setCodeMKey] = useState(uuid())
    
    useEffect(() => {
        if (props.isEdit) {
            setCodeMKey(uuid())
        }

        if(props.permitTypeData.carType != null){

            setCarType(props.permitTypeData.carType)
        

        switch (props.permitTypeData.carType) {
            case "IPTDEC":
                setCarType("In-payment Declaration")
                setInPaymentDec(true)
            break
            case "IPTUPD":
                setCarType("In-payment Update Application")
                setInPaymentDec(true)
            break
            case "IPTPMT":
                setCarType("In-payment Permit")
                setInPaymentDec(true)
            break
            case "IPTUPT":
                setCarType("In-payment Updated Permit")
                setInPaymentDec(true)
            break
            case "INPDEC":
                setCarType("In-non-payment Declaration ")
                setInPaymentDec(false)
            break
            case "INPUPD":
                setCarType("In-non-payment Update Application ")
                setInPaymentDec(false)
            break
            case "INPPMT":
                setCarType("In-non-payment Permit")
                setInPaymentDec(false)
            break
            case "INPUPT":
                setCarType("In-non-payment Updated Permit")
                setInPaymentDec(false)
            break
            case "OUTDEC":
                setCarType("Out (with or without Certificate of Origin) Declaration ")
            break
            case "OUTUPD":
                setCarType("Out (with or without Certificate of Origin) Update Application")
            break
            case "OUTPMT":
                setCarType("Out (with or without Certificate of Origin) Permit")
            break
            case "OUTUPT":
                setCarType("Out (with or without Certificate of Origin) Update Permit")
            break

            default:
              break
          }
        }

        if(props.permitTypeData.complianceStatus==5404){
            if (props.permitTypeData.payment){
                setCarType("In-payment Updated Permit")
                setFieldValue('carType', "IPTUPD")
            } else {
                setCarType("In-non-payment Updated Permit")
                setFieldValue('carType', "INPUPD")
            }
        }
        
    }, [props.isEdit])
    
    const onChangeUniqueCrNoAutoComplete = (value, option) => {
        if (option) {
            setFieldValue('uniqueCrNo', option.data.declarantCrNo)
            setFieldValue('declarantId', option.data.declarantId)
        } else {
            setFieldValue('uniqueCrNo', '')
            setFieldValue('declarantId', '')
        }
    }

    const handleIsPaymentChange = (event) => {
        const newValue = event.target.value
        
        // Update carType and decType field based on the selected value of isPayment
        if (newValue == true) {
            setFieldValue('decType', '')
            setCarType("In-payment Declaration")
            setFieldValue('carType', "IPTDEC")
            setInPaymentDec(true)
            
        } else {
            setFieldValue('decType', '')
            setCarType("In-non-payment Declaration")
            setFieldValue('carType', "INPDEC") 
            setInPaymentDec(false)

        }

        setFieldValue('payment', newValue)
    }

    return (
        <Card>
            <AccordionSummary>
                <Grid container>
                    <CngGridItem xs={6} sm={6}>
                        <AccordionHeaderComponent 
                            title = {ComplianceTranslatedObject.permitTypeTitle}
                        />
                    </CngGridItem>
        
                    <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
                        <CngGridItem xs={4} sm={4}>
                            <DeclarantAutocompleteField
                                name="uniqueCrNo"
                                label={ComplianceTranslatedObject.declarantCrNo}
                                onChange={onChangeUniqueCrNoAutoComplete}
                                isRequired
                                defaultValue={props.permitTypeData.uniqueCrNo ?? ''}
                            />
                            </CngGridItem>

                        {!props.inPermit &&(
                            <>
                                <CngGridItem xs={4} sm={4}>
                                    <CngCodeMasterAutocompleteField
                                        name='carType'
                                        label={ComplianceTranslatedObject.messageType}
                                        codeType='CAL_COMP_CAR_TYPE'
                                        key={codeMKey}
                                        isRequired
                                        disabled
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngCodeMasterAutocompleteField
                                        name='decType'
                                        label={ComplianceTranslatedObject.declarationType}
                                        codeType='CAL_COMP_OUT_DEC_TYPE'
                                        key={codeMKey}
                                        isRequired
                                        disabled={props.permitTypeData.complianceStatus == 5404 || props.permitTypeData.complianceStatus == 5406}
                                    //defaultValue={props.permitTypeData.decType ?? ''}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}/>
                                <CngGridItem xs={4} sm={4}/>
                            </>
                        )}

                        {props.inPermit &&(
                            <>
                                <CngGridItem xs={4} sm={4}>
                                    <CngSelectField
                                        name='payment'
                                        label='Payment'
                                        items={[
                                            {text: 'With payment', value: true},
                                            {text: 'Non-payment', value: false}
                                        ]}
                                        onChange= {handleIsPaymentChange}
                                        disabled={props.permitTypeData.complianceStatus == 5404 || props.permitTypeData.complianceStatus == 5406}
                                    />
                                </CngGridItem>

                                <CngGridItem xs={4} sm={4}>
                                    <CngCodeMasterAutocompleteField
                                        name='carType'
                                        label={ComplianceTranslatedObject.messageType}
                                        codeType='CAL_COMP_CAR_TYPE'
                                        key={codeMKey}
                                        value={carType}
                                        isRequired
                                        disabled
                                    />
                                </CngGridItem>

                                <CngGridItem xs={4} sm={4}/>

                                {inPaymentDec &&(
                                    <CngGridItem xs={4} sm={4}>
                                        <CngCodeMasterAutocompleteField
                                            name='decType'
                                            label={ComplianceTranslatedObject.declarationType}
                                            codeType='CAL_COMP_IN_IPT_DEC_TYPE'
                                            key={codeMKey}
                                            isRequired
                                            disabled={props.permitTypeData.complianceStatus == 5404 || props.permitTypeData.complianceStatus == 5406}
                                        />
                                    </CngGridItem>
                                )}
                                {!inPaymentDec &&(
                                    <CngGridItem xs={4} sm={4}>
                                        <CngCodeMasterAutocompleteField
                                            name='decType'
                                            label={ComplianceTranslatedObject.declarationType}
                                            codeType='CAL_COMP_IN_INP_DEC_TYPE'
                                            key={codeMKey}
                                            isRequired
                                            disabled={props.permitTypeData.complianceStatus == 5404 || props.permitTypeData.complianceStatus == 5406}
                                        />
                                    </CngGridItem>
                                )}
                            </>
                        )}
                        
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'remarkLine'
                                label = {ComplianceTranslatedObject.remarks}
                                inputProps = {{ maxLength: 512 }}
                                defaultValue={props.permitTypeData.remarkLine ?? ''}
                                disabled={props.permitTypeData.complianceStatus == 5404 || props.permitTypeData.complianceStatus == 5406}
                            >
                            </CngTextField>
                        </CngGridItem>
                    </Grid>
                </Grid>
            </AccordionSummary>
        </Card>
    )
}

const PermitTypeComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default PermitTypeComponent;