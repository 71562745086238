import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ComplianceTranslationText from '../ComplianceTranslationText'
import { components } from 'cng-web-lib'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'

const {
    form: {
      adapter: {
        useFormAdapter: { useField, useFormikContext }
      }
    },
    CngGridItem
} = components

const FormBody = (props) => {

    const ComplianceTranslatedTextsObject = ComplianceTranslationText()
    const [shouldRender, setShouldRender] = useState(false)

    useEffect(() => {

        setShouldRender(true)
    })

    if (!shouldRender) {
        return null
    }

    return(

        <Grid container xs={12} sm={12}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={6} sm={6}>
                                {ComplianceTranslatedTextsObject.licenseTitle}
                            </CngGridItem>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={2} sm={2}>
                                <LabelValueVertical
                                label={ComplianceTranslatedTextsObject.licenseNo1}
                                value={props.licenceData.shippingInfoComplianceLicence.licNo1}
                                />
                            </CngGridItem>
                            <CngGridItem xs={2} sm={2}>
                                <LabelValueVertical
                                label={ComplianceTranslatedTextsObject.licenseNo2}
                                value={props.licenceData.shippingInfoComplianceLicence.licNo2}
                                />
                            </CngGridItem>
                            <CngGridItem xs={2} sm={2}>
                                <LabelValueVertical
                                label={ComplianceTranslatedTextsObject.licenseNo3}
                                value={props.licenceData.shippingInfoComplianceLicence.licNo3}
                                />
                            </CngGridItem>
                            <CngGridItem xs={2} sm={2}>
                                <LabelValueVertical
                                label={ComplianceTranslatedTextsObject.licenseNo4}
                                value={props.licenceData.shippingInfoComplianceLicence.licNo4}
                                />
                            </CngGridItem>
                            <CngGridItem xs={2} sm={2}>
                                <LabelValueVertical
                                label={ComplianceTranslatedTextsObject.licenseNo5}
                                value={props.licenceData.shippingInfoComplianceLicence.licNo5}
                                />
                            </CngGridItem>
                        </Grid>
                    </Box>
                </Card>
            </CngGridItem>  
        </Grid>
    )
}

const initialValues = Object.freeze({})

const ViewLicenceComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewLicenceComponent
