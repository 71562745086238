import useComplianceCargoValidationSchema from "../shared/validation/useComplianceCargoValidationSchema"
import useCompliancePermitTypeValidationSchema from "../shared/validation/useCompliancePermitTypeValidationSchema"
import useComplianceLicenceValidationSchema from "../shared/validation/useComplianceLicenceValidationSchema.js"
import useComplianceTransportValidationInSchema from "../shared/validation/useComplianceTransportValidationInSchema"
import useComplianceContainerValidationSchema from "../shared/validation/usComplianceContainerValidationSchema"

function useCreatePermitMakeValidationInSchema() {
    return useCompliancePermitTypeValidationSchema()
        .concat(useComplianceCargoValidationSchema())
        .concat(useComplianceContainerValidationSchema())
        .concat(useComplianceTransportValidationInSchema())
        .concat(useComplianceLicenceValidationSchema())
}
export default useCreatePermitMakeValidationInSchema