import {
  AccordionSummary,
  Card,
  Grid
} from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import React, { useEffect, useState } from 'react'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { components, constants } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: {
      CngSelectField,
      CngSwitchField,
      CngCodeMasterAutocompleteField,
      CngTextField
    }
  },
  CngGridItem,
  table: { useDefaultNotification }
} = components

const { CodeMaintenanceType } = constants

const initialValues = Object.freeze({
  shippingInfoComplianceCargo: {
    packType: '9',
    releaseLocCode: '',
    //releaseLocName: '',
    receiptLocCode: '',
    //receiptLocName: '',
    storageLocCode: ''
  }
})

const FormBody = (props) => {
  const ComplianceTranslatedObject = ComplianceTranslationText()
  const [codeMKey, setCodeMKey] = useState(uuid())
  useEffect(() => {
    if (props.isEdit) {
      setCodeMKey(uuid())
    }

  }, [props.isEdit])
  return (
    <Card>
      <AccordionSummary>
        <Grid container>
          <CngGridItem xs={6} sm={6}>
            <AccordionHeaderComponent
              title={ComplianceTranslatedObject.cargoTitle}
            />
          </CngGridItem>
          <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
            <CngGridItem xs={4} sm={4}>
              <CngCodeMasterAutocompleteField
                name='shippingInfoComplianceCargo.packType'
                label={ComplianceTranslatedObject.cargoPackingType}
                codeType='CAL_COMP_PACK_TYPE'
                key={codeMKey}
                isRequired
                // defaultValue={props.cargoData?.shippingInfoComplianceCargo?.packType ?? ''}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngCodeMasterAutocompleteField
                name='shippingInfoComplianceCargo.releaseLocCode'
                label={ComplianceTranslatedObject.releaseLocation}
                codeType='CAL_COMP_LOC_CODE'
                key={codeMKey}
                isRequired
              //defaultValue={props.cargoData?.shippingInfoComplianceCargo?.releaseLocCode ?? ''}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <CngCodeMasterAutocompleteField
                name='shippingInfoComplianceCargo.receiptLocCode'
                label={ComplianceTranslatedObject.receiptLocation}
                codeType='CAL_COMP_LOC_CODE'
                key={codeMKey}
                isRequired
              //defaultValue={props.cargoData?.shippingInfoComplianceCargo?.receiptLocCode ?? ''}
              />
            </CngGridItem>
          </Grid>
        </Grid>
      </AccordionSummary>
    </Card>
  )
}

const CargoComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default CargoComponent
