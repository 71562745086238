import { Box, Button, Grid } from '@material-ui/core'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import React from 'react'

const SaveDraftButton = ({ buttonText, onSaveDraft }) => {
  return (
    <Box pr={2}>
      <Button
        variant='contained'
        className='button-blue originalText'
        onClick={onSaveDraft}
      >
        {buttonText}
      </Button>
    </Box>
  )
}

const ConfirmReviewButton = ({ buttonText, onConfirmReview }) => {
  return (
    <Box>
      <Button
        variant='contained'
        className='button-light-green originalText'
        onClick={onConfirmReview}
      >
        {buttonText}
      </Button>
    </Box>
  )
}

const ComplianceButtonComponent = (props) => {
  const translatedTextsObject = CalistaUiComponentTranslationText();

  const handleSaveDraft = () => {
    props.onSaveDraft();
  }

  const handleConfirmReview = () => {
    props.onConfirmReview();
  }

  return (
    <Box pl={5} pr={5} pt={4} pb={4}>
      <Grid container xs={12} sm={12}>
        <Grid container xs={6} sm={6} justify='flex-start'>
          <Button
            variant='contained'
            color='secondary'
            onClick={props.onDiscard}
            classes={{ root: 'ng-button-filled-secondary' }}
          >
            {translatedTextsObject.discard}
          </Button>
        </Grid>
        <Grid container xs={6} sm={6} justify='flex-end'>
          <SaveDraftButton buttonText={translatedTextsObject.saveDraft} onSaveDraft={handleSaveDraft} />
          <ConfirmReviewButton buttonText={translatedTextsObject.confirmReview} onConfirmReview={handleConfirmReview} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComplianceButtonComponent
