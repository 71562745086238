import React from 'react'

function ShowCountComponent(props) {
  return (
    <span className={`summary-count-label-wrapper`}>
      <label>
        Showing {props.shownCount} out of {props.totalCount} results
      </label>
    </span>
  )
}

export default ShowCountComponent
