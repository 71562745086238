import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  makeStyles,
  Box
} from '@material-ui/core'
import { v4 as uuid } from 'uuid'
import React from 'react'
import Utils from 'src/views/common/utils/Utils'
import ComplianceTranslationText from '../ComplianceTranslationText'
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import HtmlTooltip from 'src/views/freightbooking/components/HtmlTooltip'
import CountryIconViewField from 'src/components/field/CountryIconViewField'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      borderSpacing: '0 5px',
      borderCollapse: 'separate',
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        backgroundColor: '#fafbfc',
        borderBottom: 'none',
        '&:nth-child(1)': {
          width: 'auto  !important'
        },
        '&:nth-child(n + 2):nth-last-child(n + 2)': {
          width: 'auto !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: 'auto !important'
          },
          '&:nth-child(n + 2):nth-last-child(n + 2)': {
            width: '20% !important'
          },
          '&:nth-last-child(1)': {
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important',
            '& .MuiIconButton-root': {
              border: '1px solid #f4f4f7',
              borderRadius: '10px'
            }
          }
        }
      }
    }
  },
  trashIcon: {
    color: theme.palette.text.error,
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}))

const PartiesTable = (props) => {
  const translatedTextsObject = ComplianceTranslationText()
  const partyTranslatedTextObject = CompanyPreferenceTranslationText()
  const classes = useStyles()
  const editMode = props.editMode
  const isUpdatable = props.itemData?.complianceStatus !== 5404

  if (props.list == null || props.list.length <= 0) {
    return <br />
  } else {
    return (
      <Box m='8px' className={classes.div}>
        <TableContainer component={Paper}>
          <Table id='table'>
            <TableHead>
              <TableRow>
                <TableCell>{translatedTextsObject.itemsHSCode}<span style={{ color: 'red' }}>*</span></TableCell>
                <TableCell>{translatedTextsObject.hsQuantity}<span style={{ color: 'red' }}>*</span></TableCell>
                <TableCell>{translatedTextsObject.hsUnit}<span style={{ color: 'red' }}>*</span></TableCell>
                <TableCell>{translatedTextsObject.grossWeight}<span style={{ color: 'red' }}>*</span></TableCell>
                <TableCell>{translatedTextsObject.itemsAmount}<span style={{ color: 'red' }}>*</span></TableCell>
                <TableCell>{partyTranslatedTextObject.issuePlace}<span style={{ color: 'red' }}>*</span></TableCell>
                {editMode && (
                  <TableCell>{translatedTextsObject.action}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.list.map((m, i) => {
                const rowKey = uuid()
                return (
                  <TableRow
                    key={rowKey}
                    hover
                    onClick={props.handleRowClick(i)}
                  >
                    <TableCell>

                      <HtmlTooltip
                        title={m.hsCode + '/' + m.itemDesc}
                        placement='right'
                      >
                        <div>
                          {Utils.trimString(m.hsCode + '/' + m.itemDesc, 17)}
                        </div>
                      </HtmlTooltip>
                    </TableCell>

                    <TableCell>{m.hsQtyValue}</TableCell>
                    <TableCell>
                      {props.getPackageDescFromCode(m.hsQtyUom)}
                    </TableCell>
                    <TableCell>{m.grossWeight}</TableCell>
                    <TableCell>{m.cifFobAmt}</TableCell>
                    <TableCell>
                      <CountryIconViewField
                        countryCode={m.ctyOrigin}
                      />
                    </TableCell>

                    <TableCell align='right'>
                      {editMode && isUpdatable && (
                        <>
                          <IconButton
                            onClick={props.editRowClick(i)}
                            aria-label='edit'
                          >
                            <EditOutlinedIcon fontSize='small' />
                          </IconButton>
                          {/* items are not allowed to delete
                        reserved for future useage
                        {props.list?.length > 1 &&(<IconButton
                          aria-label='remove'
                          onClick={props.removeRowClick(i)}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButton>
                        )} */}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}

export default PartiesTable
