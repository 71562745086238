import React, { useEffect, useState } from 'react'
import { Box, Card, Grid } from '@material-ui/core'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import ComplianceTranslationText from '../ComplianceTranslationText'
import { components } from 'cng-web-lib'

const {
    form: {
      adapter: {
        useFormAdapter: { useField, useFormikContext }
      }
    },
    CngGridItem
} = components

const FormBody = (props) => {

    const ComplianceTranslatedTextsObject = ComplianceTranslationText()
    const [shouldRender, setShouldRender] = useState(false)

    useEffect(() => {

        setShouldRender(true)
    })

    if (!shouldRender) {
        return null
    }

    return (

        <Grid container xs={12} sm={12}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={6} sm={6}>
                                {ComplianceTranslatedTextsObject.cargoTitle}
                            </CngGridItem>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={4} sm={4}>
                                <Box className='view-card-content'>
                                    <Box className='view-card-content-label'>
                                        {ComplianceTranslatedTextsObject.cargoPackingType}
                                    </Box>
                                    <Box className='view-card-content-value'>
                                        <CodeMasterViewField
                                        codeType='CAL_COMP_PACK_TYPE'
                                        code={props.cargoData?.shippingInfoComplianceCargo?.packType}
                                        />
                                    </Box>
                                </Box>
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <Box className='view-card-content'>
                                    <Box className='view-card-content-label'>
                                        {ComplianceTranslatedTextsObject.releaseLocation}
                                    </Box>
                                    <Box className='view-card-content-value'>
                                        <CodeMasterViewField
                                        codeType='CAL_COMP_LOC_CODE'
                                        code={props.cargoData?.shippingInfoComplianceCargo?.releaseLocCode}
                                        />
                                    </Box>
                                </Box>
                            </CngGridItem>

                            <CngGridItem xs={4} sm={4}>
                                <Box className='view-card-content'>
                                    <Box className='view-card-content-label'>
                                        {ComplianceTranslatedTextsObject.receiptLocation}
                                    </Box>
                                    <Box className='view-card-content-value'>
                                        <CodeMasterViewField
                                        codeType='CAL_COMP_LOC_CODE'
                                        code={props.cargoData?.shippingInfoComplianceCargo?.receiptLocCode}
                                        />
                                    </Box>
                                </Box>
                            </CngGridItem>
                        </Grid>
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>
    )
}

const initialValues = Object.freeze({})

const ViewCargoComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewCargoComponent