import React from 'react'
import { Box, Grid} from '@material-ui/core'

import { components } from 'cng-web-lib'

const {
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

function ActionMenuSIDetail(props) {
 
    return (
        <Box pt={5}>
            <Grid container xs={12} sm={12}>
                <Grid xs={3} sm={3} justify='flex-start'>
                    {/* <BackToButton /> */}
                </Grid>
            </Grid>
        </Box>
    )
}

export default ActionMenuSIDetail