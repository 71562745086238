import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import ComplianceTranslationText from '../ComplianceTranslationText'
import { components } from 'cng-web-lib'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'

const {
    form: {
      adapter: {
        useFormAdapter: { useField, useFormikContext }
      }
    },
    CngGridItem
} = components

const FormBody = (props) => {

    const ComplianceTranslatedTextsObject = ComplianceTranslationText()
    const [shouldRender, setShouldRender] = useState(false)

    useEffect(() => {

        console.log('Check permit type', props.permitTypeData)
        console.log('Check permit in', props.inPermit)

        setShouldRender(true)
    })

    if (!shouldRender) {
        return null
    }

    return(
        <Grid container xs={12} sm={12}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={6} sm={6}>
                                {ComplianceTranslatedTextsObject.permitTypeTitle}
                            </CngGridItem>
                        </Grid>
                    </Box>

                    <Box>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical
                                label={ComplianceTranslatedTextsObject.declarantCrNo}
                                value={props.permitTypeData.uniqueCrNo}
                                />
                            </CngGridItem>

                            {!props.inPermit &&(
                                <>
                                    <CngGridItem xs={4} sm={4}>
                                        <Box className='view-card-content'>
                                            <Box className='view-card-content-label'>
                                                {ComplianceTranslatedTextsObject.messageType}
                                            </Box>
                                            <Box className='view-card-content-value'>
                                                <CodeMasterViewField
                                                codeType='CAL_COMP_CAR_TYPE'
                                                code={props.permitTypeData.carType}
                                                />
                                            </Box>
                                        </Box>
                                    </CngGridItem>

                                    <CngGridItem xs={4} sm={4}>
                                        <Box className='view-card-content'>
                                            <Box className='view-card-content-label'>
                                                {ComplianceTranslatedTextsObject.declarationType}
                                            </Box>
                                            <Box className='view-card-content-value'>
                                                <CodeMasterViewField
                                                codeType='CAL_COMP_OUT_DEC_TYPE'
                                                code={props.permitTypeData.decType}
                                                />
                                            </Box>
                                        </Box>
                                    </CngGridItem>
                                    <CngGridItem xs={4} sm={4}/>
                                    <CngGridItem xs={4} sm={4}/>
                                </>
                            )}

                            {props.inPermit &&(
                                <>
                                    <CngGridItem xs={4} sm={4}>
                                        <Box className='view-card-content'>
                                            <Box className='view-card-content-label'>
                                                {ComplianceTranslatedTextsObject.messageType}
                                            </Box>
                                            <Box className='view-card-content-value'>
                                                <CodeMasterViewField
                                                codeType='CAL_COMP_CAR_TYPE'
                                                code={props.permitTypeData.carType}
                                                />
                                            </Box>
                                        </Box>
                                    </CngGridItem>

                                    {props.withPay &&(
                                        <>
                                            <CngGridItem xs={4} sm={4}>
                                                <Box className='view-card-content'>
                                                    <Box className='view-card-content-label'>
                                                        {ComplianceTranslatedTextsObject.declarationType}
                                                    </Box>
                                                    <Box className='view-card-content-value'>
                                                        <CodeMasterViewField
                                                        codeType='CAL_COMP_IN_IPT_DEC_TYPE'
                                                        code={props.permitTypeData.decType}
                                                        />
                                                    </Box>
                                                </Box>
                                            </CngGridItem>
                                        </>
                                    )}

                                    {!props.withPay &&(
                                        <>
                                            <CngGridItem xs={4} sm={4}>
                                                <Box className='view-card-content'>
                                                    <Box className='view-card-content-label'>
                                                        {ComplianceTranslatedTextsObject.declarationType}
                                                    </Box>
                                                    <Box className='view-card-content-value'>
                                                        <CodeMasterViewField
                                                        codeType='CAL_COMP_IN_INP_DEC_TYPE'
                                                        code={props.permitTypeData.decType}
                                                        />
                                                    </Box>
                                                </Box>
                                            </CngGridItem>
                                        </>
                                    )}  
                                    <CngGridItem xs={4} sm={4}/>
                                    <CngGridItem xs={4} sm={4}/>
                                </>
                            )}

                            <CngGridItem xs={4} sm={4}>
                                <LabelValueVertical
                                label={ComplianceTranslatedTextsObject.remarks}
                                value={props.permitTypeData.remarkLine}
                                />
                            </CngGridItem>
                        </Grid>
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>
    )

}

const initialValues = Object.freeze({})

const ViewPermitTypeComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewPermitTypeComponent