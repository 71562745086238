import { components } from 'cng-web-lib'
import React from 'react'
import DeclarantMaintenanceApiUrls from 'src/apiUrls/DeclarantMaintenanceApiUrls'
import DeclarantMapping_FormProperties from './DeclarantMapping_FormProperties'
import ComplianceTranslationText from '../ComplianceTranslationText'

const {
      table: {
            useFetchCustomLookup,
            CngServerModeDialogFormTable
      }
} = components


function DeclarantMappingTable(props) {
      const showNotification = props.showNotification
      const fieldsTranslatedTextObject = ComplianceTranslationText();
      const partyId = props.partyId;
      console.log('this is partyId ' + partyId)

      const columns = [
            {
                  field: "declarantId",
                  title: fieldsTranslatedTextObject.declarantMaintenanceDeclarantId,
            },
            {
                  field: "declarantCrNo",
                  title: fieldsTranslatedTextObject.declarantMaintenanceDeclarantCrNo,
            }
            
      ]

      return (
            <CngServerModeDialogFormTable
                  fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
                  disabled={false}
                  showNotification={showNotification}
                  tableColumns={columns}
                  formProperties={{
                        ...DeclarantMapping_FormProperties,
                        formikProps: {
                              ...DeclarantMapping_FormProperties.formikProps,
                              initialValues: {
                                    ...DeclarantMapping_FormProperties.formikProps.initialValues,
                                    partyId: props.partyId
                              }
                        },
                        customFieldsProps: { partyId: props.partyId }
                  }}
                  toClientDataFormat={(serverData) => {
                        return serverData;
                  }}
                  toServerDataFormat={(localDatum) => {
                        return localDatum;
                  }}
                  fetch={{
                        url: DeclarantMaintenanceApiUrls.GET_DECLARANT_MAINTENANCE,
                        body: {
                              partyId: partyId
                        }
                  }}
                  create={{ 
                        url: DeclarantMaintenanceApiUrls.CREATE_DECLARANT_MAINTENANCE,
                        onSuccess: (serverResponsedDatum, tableFunction) => {
                              tableFunction.refreshTable()
                        }
                  }}
                  update={{
                        url: DeclarantMaintenanceApiUrls.UPDATE_DECLARANT_MAINTENANCE,
                        onSuccess: (serverResponsedDatum, datum, tableFunction) => {
                              tableFunction.refreshTable()
                        }
                  }}
                  del={{
                        url: DeclarantMaintenanceApiUrls.DELETE_DECLARANT_MAINTENANCE,
                        onSuccess: (datum, tableFunction) => {
                              tableFunction.refreshTable()
                        }
                  }}
                  idAccessor='id'
            />
      )
}

export default DeclarantMappingTable