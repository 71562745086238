import { Box, Card, Grid } from '@material-ui/core'
import React from 'react'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import ComplianceTranslationText from '../ComplianceTranslationText'
import ShippingInstructionTranslationText from '../../shippinginstruction/ShippingInstructionTranslationText'
import { components } from 'cng-web-lib'
import Utils from 'src/views/common/utils/Utils'
import PortViewField from 'src/components/field/PortViewField'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'

const {
    form: {
      adapter: {
        useFormAdapter: { useField, useFormikContext }
      }
    },
    CngGridItem
} = components

const FormBody = (props) => {

    const ComplianceTranslatedTextsObject = ComplianceTranslationText()
    const siTranslatedTextsObject = ShippingInstructionTranslationText()
    const {transportData} = props


    return(

        <Grid container xs={12} sm={12}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        {ComplianceTranslatedTextsObject.tranportTitle}
                    </Box>

                    {props.transportData?.shippingInfoComplianceTransport?.outTptMode == 1 &&(
                        <Box>
                            <Grid container xs={12} sm={12}>
                                <CngGridItem xs={4} sm={4}>
                                    <Box className='view-card-content'>
                                        <Box className='view-card-content-label'>
                                            {ComplianceTranslatedTextsObject.transportMode}
                                        </Box>
                                        <Box className='view-card-content-value'>
                                            <CodeMasterViewField
                                            codeType='CAL_COMP_TPT_MODE'
                                            code={props.transportData?.shippingInfoComplianceTransport?.outTptMode.toString()}
                                            />
                                        </Box>
                                    </Box>
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <LabelValueVertical
                                    label={siTranslatedTextsObject.voyageNumber}
                                    value={props.transportData?.shippingInfoComplianceTransport?.voyNo}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <LabelValueVertical
                                    label={siTranslatedTextsObject.vesselName}
                                    value={props.transportData?.shippingInfoComplianceTransport?.vessName}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <LabelValueVertical
                                    label={siTranslatedTextsObject.blNo}
                                    value={props.transportData?.shippingInfoComplianceTransport?.outMasterShipDoc}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <Box
                                        className='view-card-content'
                                    >
                                        <Box className='view-card-content-label'>
                                            {siTranslatedTextsObject.polPlannedEtd}
                                        </Box>
                                        <Box className='view-card-content-value'>
                                            {Utils.formatDate(
                                                transportData.shippingInfoComplianceTransport?.deptDate,
                                                Utils.FORMAT_DATE,
                                                Utils.DASH
                                            )}
                                        </Box>
                                    </Box>
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <Box
                                        className='view-card-content'
                                    >
                                        <Box className='view-card-content-label'>
                                            {siTranslatedTextsObject.portOfDischarge}
                                        </Box>
                                        <Box className='view-card-content-value'>
                                        <PortViewField
                                            portCode={transportData.shippingInfoComplianceTransport?.dischargePort}
                                        />
                                        </Box>
                                    </Box>
                                </CngGridItem>
                            </Grid>
                        </Box>
                    )}
                    {props.transportData?.shippingInfoComplianceTransport?.inTptMode == 1 &&(
                        <Box>
                            <Grid container xs={12} sm={12}>
                                <CngGridItem xs={4} sm={4}>
                                    <Box className='view-card-content'>
                                        <Box className='view-card-content-label'>
                                            {ComplianceTranslatedTextsObject.transportMode}
                                        </Box>
                                        <Box className='view-card-content-value'>
                                            <CodeMasterViewField
                                            codeType='CAL_COMP_TPT_MODE'
                                            code={props.transportData?.shippingInfoComplianceTransport?.inTptMode.toString()}
                                            />
                                        </Box>
                                    </Box>
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <LabelValueVertical
                                    label={siTranslatedTextsObject.voyageNumber}
                                    value={props.transportData?.shippingInfoComplianceTransport?.voyNo}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <LabelValueVertical
                                    label={siTranslatedTextsObject.vesselName}
                                    value={props.transportData?.shippingInfoComplianceTransport?.vessName}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <LabelValueVertical
                                    label={siTranslatedTextsObject.blNo}
                                    value={props.transportData?.shippingInfoComplianceTransport?.inMasterShipDoc}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <Box
                                        className='view-card-content'
                                    >
                                        <Box className='view-card-content-label'>
                                            {siTranslatedTextsObject.podPlannedEta}
                                        </Box>
                                        <Box className='view-card-content-value'>
                                            {Utils.formatDate(
                                                transportData.shippingInfoComplianceTransport?.arrivalDate,
                                                Utils.FORMAT_DATE,
                                                Utils.DASH
                                            )}
                                        </Box>
                                    </Box>
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <Box
                                        className='view-card-content'
                                    >
                                        <Box className='view-card-content-label'>
                                            {siTranslatedTextsObject.portOfLoading}
                                        </Box>
                                        <Box className='view-card-content-value'>
                                        <PortViewField
                                            portCode={transportData.shippingInfoComplianceTransport?.loadingPort}
                                        />
                                        </Box>
                                    </Box>
                                </CngGridItem>
                            </Grid>
                        </Box>
                    )}
                </Card>
            </CngGridItem>
        </Grid>

    )
}

const initialValues = Object.freeze({})

const ViewTransportComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewTransportComponent