import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import FooterAddComponent from '../../common/ui/FooterAddComponent'
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import DeleteIcon from '@material-ui/icons/Delete'
import { components, constants } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import CommonUtils from 'src/views/common/utils/Utils'
import ComplianceTranslationText from '../ComplianceTranslationText'
import { useFieldArray, useFormContext } from 'react-hook-form'
import moment from 'moment'

const {
    form: {
        adapter: {
        useFormAdapter: { useField, useFormikContext }
        },
        field: {
        CngCodeMasterAutocompleteField,
        CngTextField,
        CngSelectField,
        CngDateField
        }
    },
    CngGridItem,
    table: { useDefaultNotification }
} = components

const {
    component: {
      textField: {
        customType: { PLAIN_NUMBER }
      }
    }
} = constants

const initialValues ={
    shippingInfoComplianceInvoices: [
        {
            invNo: '',
            invDate: moment().format(CommonUtils.FORMAT_DATE_SERVER_DATE_2),
            invQty: 1,
            supMfrPersonCode: '',
            supMfrPersonName1: '',
            invTermType: '',
            totInvAmt: '',
            totInvAmtCurrCode:'',
            frcChargeAmt:'',
            frcChargePercent: '',
            frcChargeCurrCode:'',
            insChargeAmt:'',
            insChargePercent: '',
            insChargeCurrCode:'',
            othChargeAmt:'',
            othChargeCurrCode:'',
            othChargePercent: '',
            invoiceExpanded: false,
        }
    ]
  }

const FormBody = (props) => {
    const { watch } = useFormContext()
    const {setFieldValue} = useFormikContext()
    const ComplianceTranslatedObject = ComplianceTranslationText()
    const SG_DATE_FORMAT = CommonUtils.UI_FORMAT_DATE

    const { fields } = useFieldArray({name: "shippingInfoComplianceInvoices"})
    
    //const invoiceList = useRef([])
    const [invoiceList, setInvoiceList] = useState([])
    const draftInvoiceId = useRef(-1)

    const invoiceTypeKeyRef = useRef(uuid())
    const invoiceStatusKeyRef = useRef(uuid())

    const [invDateField] = useState(moment().format(CommonUtils.FORMAT_DATE_SERVER_DATE_2))

    const [isPaymentField] = useField('payment')
    const isPaymentWatch = watch('payment')
    const [withPay, setWithPay] = useState(false)

    const invoiceItem = {
        invId: '', //for react key reference, actual field is "invId"
        id: uuid(), //as the key of each component
        invQty: 1,
        invNo: '',
        invDate: moment().format(CommonUtils.FORMAT_DATE_SERVER_DATE_2),
        supMfrPersonCode: '',
        supMfrPersonName1: '',
        invTermType: '',
        totInvAmt: '',
        totInvAmtCurrCode:'',
        frcChargeAmt:'',
        frcChargePercent: '',
        frcChargeCurrCode:'',
        insChargeAmt:'',
        insChargePercent: '',
        insChargeCurrCode:'',
        othChargeAmt:'',
        othChargeCurrCode:'',
        othChargePercent: '',
        invoiceExpanded: false,
    }

    const onChangeInvDate = (evt, index, name) => {
        const list = [...invoiceList]

        if(evt){
            let date = moment(evt).format(CommonUtils.FORMAT_DATE_SERVER_DATE_2)
            list[index][name] = date

            setFieldValue(`shippingInfoComplianceInvoices[${index}].${name}`, date)
        }else{
            list[index][name] = ''
            setFieldValue(`shippingInfoComplianceInvoices[${index}].${name}`, '', true)
        }
        setInvoiceList(list)
    }

    const handleInvoiceExpand = (idx) => () =>{

        const isInvoiceExpanded = invoiceList[idx].invoiceExpanded
        console.log('InvoiceList isInvoiceExpanded > ' +idx+ ' ' +isInvoiceExpanded)
        const temp = [...invoiceList]
        temp[idx].invoiceExpanded = !isInvoiceExpanded

        setInvoiceList([].concat([], temp))
    }

    const handleInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...invoiceList]
    
        console.log(name, " is updating")
        console.log("Value is: ", value)
    
        list[index][name.split('.')[1]] = value
    
    
        if (name.endsWith('Percent')) {
            // Clear the amount and currency code fields
            console.log('Percent value entered, clearing amount and currency')

            let amtField = name.replace('Percent', 'Amt')
            let currField = name.replace('Percent', 'CurrCode')

            amtField = amtField.split('.')[1]
            currField = currField.split('.')[1]

            list[index][amtField] = ''
            list[index][currField] = ''

            setFieldValue(`shippingInfoComplianceInvoices[${index}].${amtField}`, null)
            setFieldValue(`shippingInfoComplianceInvoices[${index}].${currField}`, '')

        } else if (name.endsWith('Amt')) {
            // Clear the percentage field
            console.log('Amount value entered, clearing percent')

            let percentField = name.replace('Amt', 'Percent')
            percentField = percentField.split('.')[1]

            list[index][percentField] = ''
            setFieldValue(`shippingInfoComplianceInvoices[${index}].${percentField}`, '')
        }

        setInvoiceList(list)
        setFieldValue('shippingInfoComplianceInvoices', list)
    }

    const handleRemoveInvoice = (index) => {
        const list = [...invoiceList]
    
        list.splice(index, 1)
        invoiceTypeKeyRef.current = uuid()
        invoiceStatusKeyRef.current = uuid()
    
        setInvoiceList(list)
        setFieldValue('shippingInfoComplianceInvoices', list)
    
        //invoiceList.current = list
    }

    const handleAddInvoice = () => {
        const addInvoice = { ...invoiceList }
        addInvoice.invId = draftInvoiceId.current
        addInvoice.invQty = 1
        addInvoice.invDate = moment().format(CommonUtils.FORMAT_DATE_SERVER_DATE_2);
        draftInvoiceId.current = draftInvoiceId.current - 1
        setInvoiceList([...invoiceList, addInvoice])
       // setContainersField([...containerList, addContainer])
       setFieldValue("shippingInfoComplianceInvoices", [...invoiceList, addInvoice], true)
    }

    useEffect(() => {
        const invoices = { ...invoiceItem }
        invoices.invId = 'invoice' + draftInvoiceId.current
        invoices.invQty = 1
        draftInvoiceId.current = draftInvoiceId.current - 1
        console.log('props.isEdit',props.isEdit)
        console.log('props.isUpdate',props.isUpdate)
        if(props.isEdit||props.isUpdate) {

            invoiceList.current = props.invoiceData?.shippingInfoComplianceInvoices
            setFieldValue('shippingInfoComplianceInvoices', props.invoiceData?.shippingInfoComplianceInvoices)

        }else{
            setInvoiceList([invoices])
            setFieldValue("shippingInfoComplianceInvoices", [invoices])
        }
      }, [props.isEdit, props.isUpdate])

    useEffect(() => {
        if (props.isEdit && fields != null) {
            console.log('List updated')
            setInvoiceList(fields)
        }
    }, [fields])

    useEffect(() => {
        console.log('Is Payment value for invoice: ', isPaymentField.value)
        if(isPaymentField.value == true){
            setWithPay(true)
        }else
        {
            setWithPay(false)
        }
    }, [isPaymentWatch])

    return(
        <Box>
            {withPay &&(
                <Card>
                    <Box px={2} my={1.5}>
                        <Grid container justify='flex-end'>
                            <AccordionHeaderComponent 
                                title = {ComplianceTranslatedObject.invoiceTitle}
                            />
                        </Grid>

                        <Grid container xs={12} sm={12} spacing={2}>
                            {invoiceList.map((x, i) => {
                                return (
                                    <CngGridItem xs={12} sm={12} key={x.id}>
                                        <Card>
                                            <Accordion expanded={x.invoiceExpanded || false}>
                                                <AccordionSummary
                                                    aria-controls='contactDetailsComponentContent'
                                                    id='contactDetailsComponentHeader'
                                                >
                                                    <Grid container spacing={2} justify='flex-start'>
                                                        <CngGridItem xs={4} sm={4}>
                                                            <CngTextField
                                                                type= 'text'
                                                                name = {`shippingInfoComplianceInvoices[${i}].invNo`}
                                                                label = {ComplianceTranslatedObject.invoiceNo}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                                inputProps = {{ maxLength: 35 }}
                                                                isRequired
                                                            />
                                                        </CngGridItem>

                                                        <CngGridItem xs={4} sm={4}>
                                                            <CngDateField
                                                                name = {`shippingInfoComplianceInvoices[${i}].invDate`}
                                                                label = {ComplianceTranslatedObject.invoiceDate}
                                                                inputProps = {{ maxLength: 35 }}
                                                                format={SG_DATE_FORMAT}
                                                                onChange={(e) =>
                                                                    onChangeInvDate(e, i, 'invDate')
                                                                }
                                                                value={x.invDate || invDateField}
                                                                isRequired
                                                            />
                                                        </CngGridItem>

                                                        <CngGridItem xs={4} sm={4}/>

                                                        <CngGridItem xs={4} sm={4}>
                                                            <CngTextField
                                                                type= 'text'
                                                                name = {`shippingInfoComplianceInvoices[${i}].supMfrPersonCode`}
                                                                label = {ComplianceTranslatedObject.invoicePersonCode}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                                inputProps = {{ maxLength: 35 }}
                                                                disabled={props.invoiceData?.complianceStatus == 5404 || props.permitTypeData?.complianceStatus == 5406}
                                                            />
                                                        </CngGridItem>

                                                        <CngGridItem xs={4} sm={4}>
                                                            <CngTextField
                                                                type= 'text'
                                                                name = {`shippingInfoComplianceInvoices[${i}].supMfrPersonName1`}
                                                                label = {ComplianceTranslatedObject.invoicePersonName}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                                inputProps = {{ maxLength: 35 }}
                                                                isRequired
                                                            />
                                                        </CngGridItem>

                                                        <CngGridItem xs={4} sm={4}>
                                                            <CngCodeMasterAutocompleteField
                                                                name = {`shippingInfoComplianceInvoices[${i}].invTermType`}
                                                                label = {ComplianceTranslatedObject.invoiceTermType}
                                                                codeType='CAL_COMP_INV_TERM_TYPE'
                                                            />
                                                        </CngGridItem>

                                                        <CngGridItem xs={12} sm={12}>
                                                            <Card className="inner-card-group">
                                                                <Grid container xs={12} sm={12} spacing={2}>
                                                                    
                                                                <CngGridItem xs={4} sm={4}>
                                                                    <Typography className="page-content-groupLabel">
                                                                        {ComplianceTranslatedObject.totalInvoiceTitle}
                                                                    </Typography>
                                                                    <Box fontSize={10} paddingBottom={2}>
                                                                        <span style={{ color: 'red' }}>*</span>
                                                                        {"Amount and currency are required together"}
                                                                    </Box>
                                                                </CngGridItem>

                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <CngTextField
                                                                            type= 'text'
                                                                            name = {`shippingInfoComplianceInvoices[${i}].totInvAmt`}
                                                                            label = {ComplianceTranslatedObject.totalInvoiceAmount}
                                                                            onChange={(e) => handleInputChange(e, i)}
                                                                            inputProps = {{ maxLength: 35 }}
                                                                        />
                                                                    </CngGridItem>

                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <CngCodeMasterAutocompleteField
                                                                            name = {`shippingInfoComplianceInvoices[${i}].totInvAmtCurrCode`}
                                                                            label = {ComplianceTranslatedObject.totalInvoiceCurrency}
                                                                            codeType='CAL_COMP_CURR_CODE'
                                                                        />
                                                                    </CngGridItem>
                                                                </Grid>
                                                            </Card>
                                                        </CngGridItem>
                                                    </Grid>
                                                </AccordionSummary>

                                                <AccordionDetails>
                                                    <Grid container xs={12} sm={12}>
                                                        <CngGridItem xs={12} sm={12}>
                                                            <Card className="inner-card-group">
                                                                <Grid container xs={12} sm={12} spacing={2}>

                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <Typography className="page-content-groupLabel">
                                                                            {ComplianceTranslatedObject.freightChargesTitle}
                                                                        </Typography>
                                                                        <Box fontSize={10} paddingBottom={2}>
                                                                            <span style={{ color: 'red' }}>*</span>
                                                                            {"Amount and currency are required together"}
                                                                        </Box>
                                                                    </CngGridItem>

                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <CngTextField
                                                                            type= 'text'
                                                                            name = {`shippingInfoComplianceInvoices[${i}].frcChargeAmt`}
                                                                            label = {ComplianceTranslatedObject.freightChargesAmount}
                                                                            value={x.frcChargeAmt}
                                                                            onChange={(e) => handleInputChange(e, i)}
                                                                            inputProps = {{ maxLength: 35 }}
                                                                        />
                                                                    </CngGridItem>

                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <CngCodeMasterAutocompleteField
                                                                            name = {`shippingInfoComplianceInvoices[${i}].frcChargeCurrCode`}
                                                                            label = {ComplianceTranslatedObject.freightChargesCurrency}
                                                                            codeType='CAL_COMP_CURR_CODE'
                                                                        />
                                                                    </CngGridItem>

                                                                    <CngGridItem xs={4} sm={4}/>

                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <CngTextField
                                                                            type= 'text'
                                                                            name = {`shippingInfoComplianceInvoices[${i}].frcChargePercent`}
                                                                            label = {ComplianceTranslatedObject.freightChargesPercent}
                                                                            value={x.frcChargePercent}
                                                                            onChange={(e) => handleInputChange(e, i)}
                                                                            inputProps = {{ maxLength: 5 }}
                                                                        />
                                                                    </CngGridItem>
                                                                </Grid>
                                                            </Card>
                                                        </CngGridItem>

                                                        <CngGridItem xs={12} sm={12}>
                                                            <Card className="inner-card-group">
                                                                <Grid container xs={12} sm={12} spacing={2}>
                                                                    
                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <Typography className="page-content-groupLabel">
                                                                            {ComplianceTranslatedObject.otherChargesTitle}
                                                                        </Typography>
                                                                        <Box fontSize={10} paddingBottom={2}>
                                                                            <span style={{ color: 'red' }}>*</span>
                                                                            {"Amount and currency are required together"}
                                                                        </Box>
                                                                    </CngGridItem>

                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <CngTextField
                                                                            type= 'text'
                                                                            name = {`shippingInfoComplianceInvoices[${i}].othChargeAmt`}
                                                                            label = {ComplianceTranslatedObject.otherChargesAmount}
                                                                            value={x.othChargeAmt}
                                                                            onChange={(e) => handleInputChange(e, i)}
                                                                            inputProps = {{ maxLength: 35 }}
                                                                        />
                                                                    </CngGridItem>

                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <CngCodeMasterAutocompleteField
                                                                            name = {`shippingInfoComplianceInvoices[${i}].othChargeCurrCode`}
                                                                            label = {ComplianceTranslatedObject.otherChargesCurrency}
                                                                            codeType='CAL_COMP_CURR_CODE'
                                                                        />
                                                                    </CngGridItem>

                                                                    <CngGridItem xs={4} sm={4}/>

                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <CngTextField
                                                                            type= 'text'
                                                                            name = {`shippingInfoComplianceInvoices[${i}].othChargePercent`}
                                                                            label = {ComplianceTranslatedObject.otherChargesPercent}
                                                                            value={x.othChargePercent}
                                                                            onChange={(e) => handleInputChange(e, i)}
                                                                            inputProps = {{ maxLength: 5 }}
                                                                        />
                                                                    </CngGridItem>
                                                                </Grid>
                                                            </Card>
                                                        </CngGridItem>

                                                        <CngGridItem xs={12} sm={12}>
                                                            <Card className="inner-card-group">
                                                                <Grid container xs={12} sm={12} spacing={2}>
                                                                    
                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <Typography className="page-content-groupLabel">
                                                                            {ComplianceTranslatedObject.insuranceTitle}
                                                                        </Typography>
                                                                        <Box fontSize={10} paddingBottom={2}>
                                                                            <span style={{ color: 'red' }}>*</span>
                                                                            {"Amount and currency are required together"}
                                                                        </Box>
                                                                    </CngGridItem>

                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <CngTextField
                                                                            type= 'text'
                                                                            name = {`shippingInfoComplianceInvoices[${i}].insChargeAmt`}
                                                                            label = {ComplianceTranslatedObject.insuranceAmount}
                                                                            value={x.insChargeAmt}
                                                                            onChange={(e) => handleInputChange(e, i)}
                                                                            inputProps = {{ maxLength: 35 }}
                                                                        />
                                                                    </CngGridItem>

                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <CngCodeMasterAutocompleteField
                                                                            name = {`shippingInfoComplianceInvoices[${i}].insChargeCurrCode`}
                                                                            label = {ComplianceTranslatedObject.insuranceCurrency}
                                                                            codeType='CAL_COMP_CURR_CODE'
                                                                        />
                                                                    </CngGridItem>

                                                                    <CngGridItem xs={4} sm={4}/>

                                                                    <CngGridItem xs={4} sm={4}>
                                                                        <CngTextField
                                                                            type= 'text'
                                                                            name = {`shippingInfoComplianceInvoices[${i}].insChargePercent`}
                                                                            label = {ComplianceTranslatedObject.insurancePercent}
                                                                            value={x.insChargePercent}
                                                                            onChange={(e) => handleInputChange(e, i)}
                                                                            inputProps = {{ maxLength: 5 }}
                                                                        />
                                                                    </CngGridItem>
                                                                </Grid>
                                                            </Card>
                                                        </CngGridItem>                                                       
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>

                                            <CngGridItem 
                                            xs={12} 
                                            sm={12}
                                            style={
                                                x.invoiceExpanded
                                                    ? { marginTop: -16, marginBottom: 16 }
                                                    : {}
                                            }
                                            >
                                                {invoiceList.length > 1 && (
                                                    <IconButton aria-label='remove'>
                                                        <DeleteIcon onClick={() => handleRemoveInvoice(i)} />
                                                    </IconButton>
                                                )}
                                            </CngGridItem>

                                            
                                            
                                        </Card>
                                        <AccordionFooterControlComponent
                                                handleAccordionExpand={handleInvoiceExpand(i)}
                                                expanded={x.invoiceExpanded}
                                                footerText={ComplianceTranslatedObject.additionalInvoiceDetails}
                                            />
                                    </CngGridItem>
                                )
                            })}
                        </Grid>
                    </Box>
                    <FooterAddComponent
                        handleAddChild={handleAddInvoice}
                        footerText= "Add a invoice"
                    />
                </Card>
            )}
            {!withPay &&(
                <></>
            )}
        </Box>
    )
}

const InvoiceComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default InvoiceComponent