import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'

import ComplianceTranslationText from '../ComplianceTranslationText'

const {
      table: {
            CngCrudTable,
            useDefaultNotification,
            useFetchCustomLookup
      }
} = components


function TablePage(props) {
      const {
            location: { pathname },
            showNotification,
      } = props
      const translatedTextObject = ComplianceTranslationText()
      const notification = useDefaultNotification(showNotification)

      const columns = [
            {
                  field: "name",
                  title: translatedTextObject.declarantMaintenancePartyName,
                  filtering: true,
            }
      ]

      return (
            <Card>
                  <CardContent>
                        <Grid container spacing={3}>
                              <CngCrudTable
                                    {...props}
                                    fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
                                    columns={columns}
                                    editRoute={`${pathname}/edit`}
                                    fetch={{ url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get` }}
                                    idAccessor="id"
                                    notification={notification}
                              />
                        </Grid>
                  </CardContent>
            </Card >
      )


}

export default TablePage