import useComplianceCargoValidationSchema from "../shared/validation/useComplianceCargoValidationSchema"
import useCompliancePermitTypeValidationSchema from "../shared/validation/useCompliancePermitTypeValidationSchema"
import useComplianceLicenceValidationSchema from "../shared/validation/useComplianceLicenceValidationSchema.js"
import useComplianceContainerValidationSchema from "../shared/validation/usComplianceContainerValidationSchema"

function useCreatePermitMakeValidationInBlanketSchema() {
    return useCompliancePermitTypeValidationSchema()
        .concat(useComplianceCargoValidationSchema())
        .concat(useComplianceContainerValidationSchema())
        .concat(useComplianceLicenceValidationSchema())
}
export default useCreatePermitMakeValidationInBlanketSchema