import {
  Box,
  Card,
  CardActionArea,
  Grid
} from '@material-ui/core'

import LabelValueVerticalSimple from 'src/views/freightbooking/components/LabelValueVerticalSimple'
import ComplianceTranslationText from '../ComplianceTranslationText'
import PortDetailComponent from 'src/views/compliance/components/PortDetailComponent'
import LabelAndChipComponent from './LabelAndChipComponent'
import React, { useEffect, useState } from 'react'
import SIDetailBottom from './SIDetailBottom'
import SIDetailTop from './SIDetailTop'
import { components } from 'cng-web-lib'
import pathMap from 'src/paths/PathMap_Compliance'
import { useHistory } from 'react-router-dom'
import Utils from 'src/views/common/utils/Utils'

const { CngGridItem } = components
const initialValues = Object.freeze({})

const FormBody = (props) => {
  const translatedTextsObject = ComplianceTranslationText()
  const { permit } = props
  const history = useHistory()
  const [packType, setPackType] = useState()
  const [moveType, setMoveType] = useState()
  const [outPermit, setOutPermit] = useState()
  const [, setInPermit] = useState()

  let tptMode = outPermit?permit.shippingInfoComplianceTransport.outTptModeDesc:permit.shippingInfoComplianceTransport.inTptModeDesc + ' / ' + permit.shippingInfoComplianceTransport.voyNo;

  //View Permit Details
  const gotoReviewPM = () => {
    console.log('click to go to view permit details')
    console.log(permit)

    let outPermit = false
    let inPermit = false

    if (permit.carType.startsWith("OUT")){
      outPermit = true
    }else if (permit.carType.startsWith("IN") || permit.carType.startsWith("IP") ){
      inPermit = true
    }

    history.push({
      pathname: pathMap.MANAGE_VIEW_PERMIT,
      state: {
        complianceId: permit.complianceId,
        inPermit: inPermit,
        outPermit: outPermit
      }
    })
  }

  function processPermitData(permit){
    if (permit.shippingInfoComplianceCargo.packType == 9) {
      setPackType("Containerized")
    } else {
      setPackType("Other non-containerized")
    }

    if (permit.shippingInfoComplianceTransport.outTptMode == 1) {
      setMoveType("Outward transport")
    } else {
      setMoveType("Inward transport")
    }

    if (permit.carType.startsWith("OUT")){
      setOutPermit(true)
    }else if (permit.carType.startsWith("IN") || permit.carType.startsWith("IP") ){
      setInPermit(true)
    }
  }

  useEffect(() => {

    processPermitData(permit)

  })
  

  return (
    <Card className={`summary-list-item--card`}>
      <SIDetailTop permit={permit} {...props} />
      <CardActionArea
        onClick={gotoReviewPM}
        className={`cng-summary-card--body-box`}
      >
        <Grid container xs={12} className={`cng-summary-card--body-grid`}>
          <CngGridItem xs={4} sm={4} style={{ paddingLeft: 20 }}>
            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.declarantCrNo}
                value={permit.uniqueCrNo}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>
            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.cargoPackingType}
                value={packType}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>

            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.costType}
                value={moveType}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>

          </CngGridItem>

          <CngGridItem xs={4} sm={4} style={{ paddingLeft: 20 }}>
            <Box pt={0.5}>
              <LabelAndChipComponent
                title={translatedTextsObject.messageType}
                name={permit.carTypeDesc}
                code={permit.carType}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>
            <Box pt={0.5}>
              <LabelAndChipComponent
                title={translatedTextsObject.releaseLocation}
                name={permit.shippingInfoComplianceCargo.releaseLocName}
                code={permit.shippingInfoComplianceCargo.releaseLocCode}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>

            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.transportModeVoyage}
                value={tptMode}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>

            {outPermit ? (
               <Box pt={0.5}>
               <LabelValueVerticalSimple
                 label={translatedTextsObject.polPlannedETD}
                 value={
                   Utils.formatDate(
                     permit.shippingInfoComplianceTransport.deptDate,
                     Utils.FORMAT_DATE_TIME,
                     Utils.DASH)
                 }
                 defaultValue='N/A'
                 labelStyle={'frb-card-label'}
                 valueStyle={'frb-card-content'}
               />
             </Box>
            ) : 
            <Box pt={0.5}>
            <LabelValueVerticalSimple
              label={translatedTextsObject.podPlannedETA}
              value={
                Utils.formatDate(
                  permit.shippingInfoComplianceTransport.arrivalDate,
                  Utils.FORMAT_DATE_TIME,
                  Utils.DASH)
              }
              defaultValue='N/A'
              labelStyle={'frb-card-label'}
              valueStyle={'frb-card-content'}
            />
          </Box>
            }

           
          </CngGridItem>

          <CngGridItem xs={4} sm={4} style={{ paddingLeft: 20 }}>
            <Box pt={0.5}>
              <LabelAndChipComponent
                title={translatedTextsObject.declarationType}
                name={permit.decTypeDesc}
                code={permit.decType}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>
            <Box pt={0.5}>
              <LabelAndChipComponent
                title={translatedTextsObject.receiptLocation}
                name={permit.shippingInfoComplianceCargo.receiptLocName}
                code={permit.shippingInfoComplianceCargo.receiptLocCode}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>

            <Box pt={0.5}>
              <LabelValueVerticalSimple
                label={translatedTextsObject.bolNo}
                value={permit.bol_number}
                defaultValue='N/A'
                labelStyle={'frb-card-label'}
                valueStyle={'frb-card-content'}
              />
            </Box>

            {outPermit ? (
              <Box pt={0.5}>
                <PortDetailComponent
                  title={translatedTextsObject.pod}
                  portCode={permit.shippingInfoComplianceTransport.dischargePort}
                  portName={permit.shippingInfoComplianceTransport.dischargePortName}
                />
              </Box>
            ) : 
              <Box pt={0.5}>
                <PortDetailComponent
                  title={translatedTextsObject.pol}
                  portCode={permit.shippingInfoComplianceTransport.loadingPort}
                  portName={permit.shippingInfoComplianceTransport.loadingPortName}
                />
              </Box>
            }

          </CngGridItem>
        </Grid>
      </CardActionArea>
      <SIDetailBottom
        dockey={permit.dockey}
        updatedDate={permit.updatedDate}
        createdDate={permit.createdDate}
      />
    </Card>
  )
}

const PermitDetailComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default PermitDetailComponent
