import React, { useState, useMemo } from 'react'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import ViewItemDetailsContext from './ViewItemDetailsContext.js'
import ViewItemDetailsForm from './ViewItemDetailsForm.js'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function ViewItemDetailsDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  currentItemInfo
}) {
  const [isSubmitting, setSubmitting] = useState(false)

  const value = useMemo(() => {
    return {
      closeDialog,
      showNotification,
      currentItemInfo,
      form: {
        isSubmitting,
        setSubmitting
      }
    }
  }, [closeDialog, showNotification, currentItemInfo, isSubmitting])

  return (
    <ViewItemDetailsContext.Provider value={value}>
      <CngDialog
        customDialogContent={
          <Paper>
            <ViewItemDetailsForm />
          </Paper>
        }
        dialogTitle={
          <>
            <b>{'Item'}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='md'
      />
    </ViewItemDetailsContext.Provider>
  )
}

export default ViewItemDetailsDialog
