import { Chip, Typography } from '@material-ui/core'

import React from 'react'

const PortDetailComponent = (props) => {
  const { title, portCode, portName } = props

  const portDetailsTemplate = function () {
    return (
      <div className={`cng-summary-card--country-value`}>
        <Typography className={`field-label`}>{title}</Typography>
        <div className={`field-country-details`}>
          {portCode != null && (
            <img
              className={`field-country-flag`}
              src={`${process.env.PUBLIC_URL
                }/static/images/countries/${portCode.substring(0, 2)}.svg`}
            ></img>
          )}
          <label className={`field-country-name field-value dark`}>
            {portName}
          </label>
          <Chip
            label={<b style={{ fontSize: 10 }}>{portCode}</b>}
            size='small'
            sizeSmall
            style={{ marginLeft: 5 }}
            className={`field-country-code`}
          />
        </div>
      </div>
    )
  }

  return portDetailsTemplate()
}

export default PortDetailComponent
