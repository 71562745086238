import React, { useContext, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ViewItemDetailsContext from './ViewItemDetailsContext.js'
import { Grid, Card } from '@material-ui/core'
import { components, constants } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText.js'
import ShippingInstructionTranslationText from '../../shippinginstruction/ShippingInstructionTranslationText.js'
import { v4 as uuid } from 'uuid'
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import CountryIconViewField from 'src/components/field/CountryIconViewField'
import CodeMasterMiscViewField from 'src/components/field/CodeMasterMiscViewField'

const {
  form: { CngViewForm },
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngPackageUomAutocompleteField,
      CngCodeMasterAutocompleteField
    }
  },
  CngGridItem,
  button: { CngPrimaryButton }
} = components

const { CodeMaintenanceType } = constants

function ViewItemDetailsForm(props) {
  const dialogContext = useContext(ViewItemDetailsContext)

  const {
    closeDialog,
    currentItemInfo,
    form: { isSubmitting }
  } = dialogContext

  function onSubmit() {
    //do nothing for onsubmit
    closeDialog()
  }

  return (
    <CngViewForm
      formikProps={{
        initialValues: {},
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          currentItemInfo={currentItemInfo}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({ isSubmitting, currentItemInfo }) {
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const translatedTextsObject = ComplianceTranslationText()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const partyTranslatedTextObject = CompanyPreferenceTranslationText()
  const { submitForm } = useFormikContext()
  const key = useRef(uuid())

  useEffect(() => {
    console.log('currentItemInfo', currentItemInfo)

    key.current = uuid()
  }, [currentItemInfo])

  return (
    <Box m={1.5}>
      <Card>
        <Grid container xs={12} sm={12}>
          <CngGridItem xs={12} sm={12}>
            <Box className='view-card-header'>
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid xs={6} sm={6} container justify='flex-start'>
                  {'Item Information'}
                </Grid>
              </Grid>
            </Box>
          </CngGridItem>

          <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
            <CngGridItem xs={12} sm={12}>
              <LabelValueVertical
                label={siTranslatedTextsObject.cargoDesc}
                value={currentItemInfo.itemDesc}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <LabelValueVertical
                label={siTranslatedTextsObject.hsCode}
                value={currentItemInfo.hsCode}
              />
            </CngGridItem>

            <CngGridItem xs={4} sm={4}>
              <Box className='view-card-content'>
                <Box className='view-card-content-label'>
                  {partyTranslatedTextObject.issuePlace}
                </Box>
                <Box
                  className='view-card-content-value'
                  style={{ paddingRight: 0 }}
                >
                  <CountryIconViewField
                    countryCode={currentItemInfo.ctyOrigin}
                  />
                </Box>
              </Box>
            </CngGridItem>

            <CngGridItem xs={4} sm={4}>
              <LabelValueVertical
                label={translatedTextsObject.brandName}
                value={currentItemInfo.brand}
              />
            </CngGridItem>

            <CngGridItem xs={4} sm={4}>
              <LabelValueVertical
                label={translatedTextsObject.model}
                value={currentItemInfo.model}
              />
            </CngGridItem>

            <CngGridItem xs={4} sm={4}>
              <LabelValueVertical
                label={translatedTextsObject.hsQuantity}
                value={currentItemInfo.hsQtyValue}
              />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <Box className='view-card-content'>
                <Grid container justify='space-between'>
                  <Grid item xs={12} sm={12}>
                    <Box className='view-card-content-label'>{translatedTextsObject.hsUnit}</Box>
                  </Grid>
                  <Box className='view-card-content-value'>
                    <CodeMasterMiscViewField
                      codeType={CodeMaintenanceType.PACKAGE_UOM}
                      code={currentItemInfo.hsQtyUom}
                    />
                  </Box>
                </Grid>
              </Box>
            </CngGridItem>

            <CngGridItem xs={4} sm={4}>
              <LabelValueVertical
                label={siTranslatedTextsObject.grossWeightT}
                value={currentItemInfo.grossWeight}
              />
            </CngGridItem>

            <CngGridItem xs={4} sm={4}>
              <LabelValueVertical
                label={translatedTextsObject.itemsAmount}
                value={currentItemInfo.cifFobAmt}
              />
            </CngGridItem>

            <CngGridItem xs={4} sm={4}>
              <LabelValueVertical
                label={siTranslatedTextsObject.marksAndNos}
                value={currentItemInfo.shippingMark}
              />
            </CngGridItem>
          </Grid>
        </Grid>
      </Card>

      <Box display='flex' mt={2}>
        <Grid xs={6} sm={6} justify='flex-start'></Grid>

        <Grid container xs={6} sm={6} justify='flex-end'>
          <Box>
            <CngPrimaryButton
              type='button'
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {uiTranslatedTextsObject.ok}
            </CngPrimaryButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyType: '',
  name: '',
  address: '',
  city: '',
  country: '',
  state: '',
  postalCode: ''
})

const initialValues = {
  ...DEFAULT_INITIAL_VALUES
}

export default ViewItemDetailsForm
