import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  makeStyles,
  Box
} from '@material-ui/core'

import React, { useEffect, useState } from 'react'
import { useServices } from 'cng-web-lib'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import CodeMasterApi from 'src/views/common/api/CodeMasterApi'
import ComplianceTranslationText from '../ComplianceTranslationText'
import Paper from '@material-ui/core/Paper'
import { v4 as uuid } from 'uuid'

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      borderSpacing: '0 5px',
      borderCollapse: 'separate',
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        backgroundColor: '#fafbfc',
        borderBottom: 'none',
        '&:nth-child(1)': {
          width: 'auto  !important'
        },
        '&:nth-child(n + 2):nth-last-child(n + 2)': {
          width: 'auto !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: 'auto !important'
          },
          '&:nth-child(n + 2):nth-last-child(n + 2)': {
            width: '20% !important'
          },
          '&:nth-last-child(1)': {
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important',
            '& .MuiIconButton-root': {
              border: '1px solid #f4f4f7',
              borderRadius: '10px'
            }
          }
        }
      }
    }
  },
  trashIcon: {
    color: theme.palette.text.error,
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}))

const PartiesTable = (props) => {
  const translatedTextsObject = ComplianceTranslationText()
  const classes = useStyles()
  const editMode = props.editMode
  const { fetchRecords } = useServices()
  const [, setImoItems] = useState([])
  const nonUpdatable = ['Declaring Agent', 'Declarant', 'Exporter']
  const isApprovedPermit = props.partiesData?.complianceStatus === 5404

  useEffect(() => {
    CodeMasterApi.fetchCodeMaster(
      fetchRecords,
      'CAL_DG_IMO',
      false,
      onLoadIMOCodeSuccess
    )
  }, [props.editMode])

  function onLoadIMOCodeSuccess(data) {
    setImoItems(data)
  }

  if (props.list == null || props.list.length <= 0) {
    return <br />
  } else {
    return (
      <Box m='8px' className={classes.div}>
        <TableContainer component={Paper}>
          <Table id='table'>
            <TableHead>
              <TableRow>
                <TableCell>{translatedTextsObject.partyType}</TableCell>
                <TableCell>{translatedTextsObject.partyName}</TableCell>
                <TableCell>{translatedTextsObject.partyName}</TableCell>
                <TableCell>{translatedTextsObject.partyAddress}</TableCell>
                <TableCell>{translatedTextsObject.partyAddress}</TableCell>
                <TableCell>{translatedTextsObject.phoneNo}</TableCell>
                <TableCell>{translatedTextsObject.partyCrNo}</TableCell>
                {editMode && (
                  <TableCell>{translatedTextsObject.action}</TableCell>
                )}
              </TableRow>
            </TableHead>
              <TableBody>
              {props.list.map((m, i) => {
                const itemKey = uuid(); // Generate a unique key for each item
                let updatable = true
                if (isApprovedPermit) {
                  updatable = !nonUpdatable.includes(m.partyType)
                }

                return (
                  <TableRow
                    key={itemKey}
                    hover
                    onClick={props.handleRowClick(i)}
                  >
                    <TableCell>{m.partyType}</TableCell>
                    <TableCell>{m.name}</TableCell>       
                    <TableCell>{m.name2}</TableCell>
                    <TableCell>{m.address}</TableCell>
                    <TableCell>{m.address2}</TableCell>
                    <TableCell>{m.phone}</TableCell>
                    <TableCell>{m.crno}</TableCell>
                    


                    <TableCell align='right'>
                      {editMode && updatable && (
                        <>
                          <IconButton
                            onClick={props.editRowClick(i)}
                            aria-label='edit'
                          >
                            <EditOutlinedIcon fontSize='small' />
                          </IconButton>
                          {props.list.length > 1 && (
                            <IconButton
                              aria-label='remove'
                              onClick={props.removeRowClick(i)}
                            >
                              <DeleteOutlineOutlinedIcon />
                            </IconButton>
                          )}
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}

export default PartiesTable
