import { Card, Grid, Typography } from '@material-ui/core'
import React from 'react'
import Utils from 'src/views/shippinginstruction/shared/Utils'
import { components } from 'cng-web-lib'

const { CngGridItem } = components

const HeaderBarTop = (props) => {

    const {permit} = props
    const permitStatus = permit.complianceStatus || ''
    let bgColor =
        permitStatus !== ''
            ? Utils.getPermitStatusById(permitStatus).color
            : ''
    let status =
        permitStatus !== ''
            ? Utils.getPermitStatusById(permitStatus).name.toUpperCase()
            : ''
    
    return(

        <Card className={'titleBox'} style={{ backgroundColor: bgColor }}>
            <Grid container xs={12} alignItems='center'>
                <CngGridItem xs={9}>
                    <Typography className={'boldLabel'} style={{ fontSize: 16 }}>
                        {status}
                    </Typography>
                </CngGridItem>
            </Grid>
        </Card>
    )
}

export default HeaderBarTop