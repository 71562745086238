import * as CalistaConstant from 'src/constants/CalistaConstant'

import {
  Box,
  Card,
  Grid
} from '@material-ui/core'
import React, { useEffect, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import { components, constants } from 'cng-web-lib'
import ContainerDetailsComponent from './ComplianceContainerDetailsComponent'
import ShippingInstructionTranslationText from '../../shippinginstruction/ShippingInstructionTranslationText'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { useFieldArray } from 'react-hook-form'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  CngGridItem,
  table: { useDefaultNotification }
} = components

const { CodeMaintenanceType } = constants

const initialValues = {
  shippingInfoComplianceCargo:{
      shippingInfoComplianceContainers: [
      {
        ctnNo: '',
        ctnSealNo: '',
        ctnTypeSize: '',
        ctnWtValue: '',
      }
    ]
  }
}

const FormBody = (props) => {
  const { setFieldValue } = useFormikContext()
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const contTypeKeyRef = useRef(uuid())
  const contStatusKeyRef = useRef(uuid())

  const containerItem = {
    containerId: '', //for react key reference, actual field is "contId"
    id: uuid(), //as the key of each component
    containerQty: '',
    containerStatus: CalistaConstant.FULL_CONTAINER_LOAD,
    ctnNo: '',
    ctnSealNo: '',
    ctnTypeSize: '',
    ctnWtValue: '',

  }

  //useField containers for handle formik valiation and submission
  const { fields: siContainersField } = useFieldArray({
    name: 'shippingInfoContainerBoxes'
  })
  //useRef containerList for handle containerList dynamically
  const siContainerBoxList = useRef([])

  const draftContId = useRef(-1)

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    console.log('Check if value change', siContainerBoxList.current)
    const list = [...siContainerBoxList.current]
    let field = name.split('.')[1]
    console.log('handleInputChange field', field)
    list[index][field] = value

    setFieldValue('shippingInfoComplianceContainers', list)
    setFieldValue('shippingInfoComplianceCargo.shippingInfoComplianceContainers', list)
  }

  const handleRemoveContainer = (index) => (e) => {
    const list = siContainerBoxList.current

    list.splice(index, 1)
    contTypeKeyRef.current = uuid()
    contStatusKeyRef.current = uuid()

    setFieldValue('shippingInfoComplianceCargo.shippingInfoComplianceContainers', list)

    siContainerBoxList.current = list
  }

  const handleDropdownChange = (val, index, name) => {
    // const { name} = e.target
    const list = [...siContainerBoxList.current]
    // // const list = [...containersField]
    // alert('list[index][name] : ' + index + "." + name)
    list[index][name] = val
    // console.log('handleInputChange : ' + JSON.stringify(list) )
    setFieldValue('shippingInfoComplianceContainers', list)
    setFieldValue('shippingInfoComplianceCargo.shippingInfoComplianceContainers', list)
};

  useEffect(() => {
    /**
     * there are 1 scenarios so far, which  is scenario 2.
     * 1. isEdit is false, then this is a new permit
     *   not sure what to display
     *
     * 2. isEdit is true, then this is a editing permit
     *    popullate container list from si field
     */
    const cont = { ...containerItem }
    cont.containerId = 'cont' + draftContId.current
    cont.containerQty = 1
    cont.containerStatus = CalistaConstant.FULL_CONTAINER_LOAD
    draftContId.current = draftContId.current - 1

    if (props.isEdit) {

      if(props.isUpdate){
        siContainerBoxList.current = props.containerData?.shippingInfoComplianceCargo?.shippingInfoComplianceContainers
        setFieldValue('shippingInfoComplianceContainers', props.containerData?.shippingInfoComplianceCargo?.shippingInfoComplianceContainers)
      }
      else{
        if (siContainersField == null) {
          console.log("Empty container")
        } else {
          if(props.fromSI){
            siContainerBoxList.current = props.containerData?.shippingInfoComplianceCargo?.shippingInfoComplianceContainers
            setFieldValue('shippingInfoComplianceContainers', props.containerData?.shippingInfoComplianceCargo?.shippingInfoComplianceContainers)
          }else{
            siContainerBoxList.current = props.containerData?.shippingInfoComplianceCargo?.shippingInfoComplianceContainers
          }
        }
      }
    }
  }, [props.isEdit, props.isUpdate])

  return (
    <Box>
      {siContainerBoxList.current?.length > 0 ? (
      <Card>
        <Box px={2} my={1.5}>
          <Grid container justify='flex-end'>
            <AccordionHeaderComponent
              title={siTranslatedTextsObject.containerDetails}
            />
          </Grid>

          <ContainerDetailsComponent
            list={siContainerBoxList.current}
            removeRowClick={handleRemoveContainer}
            handleInputChange={handleInputChange}
            handleDropdownChange = {handleDropdownChange}
          />
        </Box>
      </Card>
      ) : null}
    </Box>
  )
}

const ComplianceContainerComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ComplianceContainerComponent
