import { BasePage as CngBasePage } from 'cng-web-lib'
import CreatePermitPage from './createpermit/CreatePermitPage'
import ReviewPermitPage from './reviewpermit/ReviewPermitPage'
import ViewPermitPage from './viewpermit/ViewPermitPage'
import TablePage from './declarantmaintenance/TablePage'
import EditPage from './declarantmaintenance/EditPage'
import React from 'react'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Compliance'
import ComplianceTranslationText from './ComplianceTranslationText'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'

function WrappedCreatePermitPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ComplianceTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <CreatePermitPage
          showNotification={showSnackbar}
          {...props}
          prevMenu={SIUtils.Menus.mySI}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedCreatePermitReviewPermitPage(props) {
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ComplianceTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ReviewPermitPage 
          showNotification={showSnackbar} 
          {...props} 
          prevMenu={SIUtils.Menus.createPermit}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedViewPermitPage(props){
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ComplianceTranslationText()
  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
        <ViewPermitPage 
          showNotification={showSnackbar} 
          {...props} 
          prevMenu={SIUtils.Menus.managePermit}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

function WrappedCreatePermitUpdateDraftPage(props){
  const breadcrumbNameMap = useBreadcrumbNameMap()
  const translatedTextsObject = ComplianceTranslationText()
  return(
    <CngBasePage
      moduleTitle={translatedTextsObject.title}
      renderPage={(showSnackbar) => (
      <CreatePermitPage
        showNotification={showSnackbar}
        {...props}
        prevMenu={SIUtils.Menus.managePermit}
      />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}


export {
  WrappedCreatePermitPage as CreatePermitPage,
  WrappedCreatePermitReviewPermitPage as ReviewPermitPage,
  WrappedViewPermitPage as ViewPermitPage,
  WrappedCreatePermitReviewPermitPage as CreatePermitNewPermitSubmitPage,
  WrappedCreatePermitUpdateDraftPage as UpdateDraftPermitPage,
}
