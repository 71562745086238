import { Chip, Grid, Typography } from '@material-ui/core'
import SIActionMenu from 'src/views/compliance/components/SIActionMenu'
import React from 'react'
import Utils from 'src/views/shippinginstruction/shared/Utils'

const SIDetailTop = (props) => {
  const { permit } = props
  const { complianceStatus } = permit

  let color = Utils.getPermitStatusById(complianceStatus).color
  let complianceStatusDesc = Utils.getPermitStatusById(complianceStatus).name
  return (
    <header className={`cng-summary-card--header-box`}>
      <Grid container xs={12} style={{ height: '100%' }}>
        <Grid item xs={12} sm={6}>
          <div className={`cng-summary-card--header-box--left`}>
            <Chip
              label={
                <Typography className={'boldLabel'} style={{ fontSize: 16 }}>
                  {complianceStatusDesc ? complianceStatusDesc.toUpperCase() : ''}

                </Typography>
              }
              style={{ backgroundColor: color }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={`cng-summary-card--header-box--right`}>
            <div className={`header-box--right-col-1`}>
            </div>
            <div className={`header-box--right-col-2 cta-box`}>

              <div className={`cng-summary-card--more-actions-wrapper`}>

                <SIActionMenu si={permit} {...props} page={Utils.Page.PermitList} />

              </div>

            </div>
          </div>
        </Grid>
      </Grid>
    </header>
  )
}

export default SIDetailTop
