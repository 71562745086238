import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core'

import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useEffect, useState } from 'react' 
import { components, useServices } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'
import DocumentInfoDecoratorComponent from '../../common/ui/DocumentInfoDecoratorComponent'
import ComplianceTranslationText from '../ComplianceTranslationText'
import DocumentApi from 'src/views/common/api/DocumentApi'
import DocumentDetailsActionMenu from '../components/DocumentsDetailsActionMenu'

import Paper from '@material-ui/core/Paper'

const {
  form: {
    adapter: {
      useFormAdapter:{ useField, useFormikContext }
    },
    field: { CngTextField, CngDocumentAutocompleteField }
  },
  CngGridItem
} = components

const DocumentDetailsComponents = (props) => {

  const [files, setFiles] = useState([])
  const { securedSendRequest } = useServices()

  const downloadRowClick = (id) => () => {
    console.log('download uploaded: ' + id)

    DocumentApi.downloadComplianceDocument(
      securedSendRequest,
      files[id],
      'CLOGS_SI'
    )
  }

  const siTranslatedTextsObject = ComplianceTranslationText()

  useEffect(() => {
    console.log('file details change 2')

    setFiles(props.tile)
    console.log(files)
  }, [props.tile])

  if (props.tile == null || props.tile.length <= 0) {
    return <br />
  } else {
    return (
      <TableContainer component={Paper}>
        <Table id='table'>
          <TableBody>
          {props.tile.map((elem, i) => {
            const rowKey = uuid() // Generate a unique key for each row

            return (
              <TableRow key={rowKey}>
                <TableCell>
                  <FileCopyIcon />
                </TableCell>
                <TableCell>
                  <DocumentInfoDecoratorComponent 
                  name={elem.filename ? elem.filename : elem.fileName}
                  size={elem.filesize ? elem.filesize : elem.fileSize} 
                  />
                </TableCell>
                <TableCell>
                  <CngDocumentAutocompleteField
                    name={`shippingInfoComplianceSupportDoc[${i}].complianceDocId`}
                    label={siTranslatedTextsObject.fileType}
                    onChange={(val, evt) => props.handleDropdownChange(val, evt, i, 'docType')}
                    codeType='CLOGS_SI'
                    disabled={!elem.editable}
                    error={props.getFieldError(i, 'shippingInfoComplianceSupportDoc.docType')}
                    helperText={props.getFieldError(i, 'shippingInfoComplianceSupportDoc.docType')}
                    key={props.docTypeKeyRef}
                    isRequired
                  />
                </TableCell>
                <TableCell>
                  <CngTextField
                    type='text'
                    name={`shippingInfoComplianceSupportDoc[${i}].description`}
                    label={siTranslatedTextsObject.fileDesc}
                    onChange={(e) => props.handleInputChange(e, i)}
                    inputProps={{ maxLength: 1024 }}
                    disabled={!elem.editable}
                    rows={4}
                    error={props.getFieldError(i, 'shippingInfoComplianceSupportDoc.description')}
                    helperText={props.getFieldError(i, 'shippingInfoComplianceSupportDoc.description')}
                    isRequired
                  />
                </TableCell>
                <TableCell>
                  <DocumentDetailsActionMenu
                    id={i}
                    removeRowClick={() => props.removeRowClick(i)}
                    editRowClick={props.editRowClick}
                    downloadRowClick={downloadRowClick(i)}
                    hideDownloadLog
                  />
                </TableCell>
              </TableRow>
            )
          })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
}

export default DocumentDetailsComponents
