import { Card, CardContent, Grid } from '@material-ui/core'

import FilterComponent from 'src/views/compliance/components/FilterComponent'
import DateRangeFilterComponent from 'src/views/freightbooking/components/DateRangeFilterComponent'
import FilterHeaderComponent from 'src/views/freightbooking/components/FilterHeaderComponent'
import React from 'react'
import ComplianceTranslationText from 'src/views/compliance/ComplianceTranslationText'
import { components } from 'cng-web-lib'

const {
  form: {
    field: { CngSwitchField }
  },
  CngGridItem
} = components

function ComplianceFilterPanelComponent(props) {
  const translatedTextsObject = ComplianceTranslationText()
  return (
    <Grid md={12}>
      <Card>
        <CardContent className={`cng-filter-pane--wrapper`}>
          <FilterHeaderComponent
            handleClearAllButtonClick={props.handleClearAllButtonClick}
          />

          <DateRangeFilterComponent
            dateRange={props.dateRange}
            dateRangeChange={props.dateRangeChange}
          />


          <FilterComponent
            checkBoxList={props.status}
            onChange={props.onStatusCheckBoxChange}
            headerLabel={translatedTextsObject.status}
          />

          <FilterComponent
            checkBoxList={props.messageTypes}
            onChange={props.onMessTypeCheckBoxChange}
            headerLabel={translatedTextsObject.messageType}
          />



          <FilterComponent
            checkBoxList={props.decTypes}
            onChange={props.onDecTypeCheckBoxChange}
            headerLabel={translatedTextsObject.declarationType}
          />

        </CardContent>
      </Card>
    </Grid>
  )
}

export default ComplianceFilterPanelComponent
