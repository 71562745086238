import {
  Box,
  Typography
} from '@material-ui/core'

import CheckboxGroup from 'src/views/compliance/components/CheckboxGroup'
import React from 'react'

const FilterComponent = (props) => {

  return (
    <section className={'cng-filter-pane--section-container'}>
      <header>
        <Box className={`cng-filter-pane--section-header-label-box`}>
          <Typography class={'cng-filter-pane--section-header-label'}>
            {props.headerLabel}
          </Typography>
        </Box>
      </header>

      <article>
        <Box className={`cng-filter-pane--controls-wrapper`}>
          <CheckboxGroup
            checkBoxList={props.checkBoxList}
            onChange={props.onChange}
            filterName={''}
          />
        </Box>
      </article>
    </section>
  )
}

export default FilterComponent
