import {  Box, Button, Grid } from '@material-ui/core'
import ExportMenuComponent from 'src/views/helpdesk/components/ExportMenuComponent'
import React from 'react'
import ShowCountComponent from './ShowCountComponent'
import { components } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText'

const {
  form: {
    field: { CngSelectField }
  },
  CngGridItem
} = components

const FormBody = (props) => {
  const translatedTextsObject = ComplianceTranslationText()

  return (
    <Grid container spacing={1}>
      <CngGridItem item xs={12} sm={12}>
        <div className={`cng-summary--header`}>
          <div className={`cng-summary--header-col auto`}>
            <Box
                display='flex'
                justifyContent='flex-start'
                className={`frb-tabs-btn-wrapper`}
            >
                <Button
                    onClick={props.clickApprovedSI}
                    variant={props.approvedSIClicked ? 'outlined' : 'text'}
                    className={props.approvedSIClicked ? `tab-active` : `tab-normal`}
                >
                    Approved SI
                    <em>({props.approvedSICount})</em>
                </Button>

                <Button
                    onClick={props.clickPermit}
                    variant={!props.approvedSIClicked ? 'outlined' : 'text'}
                    className={!props.approvedSIClicked ? `tab-active` : `tab-normal`}
                >
                    Compliance Permit
                    <em>({props.permitCount})</em>
                </Button>
            </Box>
          </div>
          <div className={`cng-summary--header-col w-190px`}>
            {props.approvedSIClicked && (
              <CngSelectField
                name='sortCode'
                label='Sort By'
                items={props.sortByListSI}
                onClick={props.handleSortByChangeSI}
                inlinelabel
              />
            )} 
            {!props.approvedSIClicked && (
              <CngSelectField
                name='sortCode'
                label='Sort By'
                items={props.sortByList}
                onClick={props.handleSortByChange}
                inlineLabel
              />
            )} 
          </div>
          {props.exportData && (
            <div className={`cng-summary--header-col cta-46`}>
              <ExportMenuComponent
                showNotification={props.showNotification}
                columns={props.columns}
                fileName={props.fileName}
                apiurl={props.apiurl}
                searchCriteria={props.searchCriteria}
              />
            </div>
          )}
        </div>
      </CngGridItem>
      {props.approvedSIClicked && (
        <CngGridItem item xs={12} sm={12}>
          <span className={`summary-count-label-wrapper mt-25 mb-10`}>
              <label>
                  {translatedTextsObject.shown} &#40;{props.shownCountSI}&#41;
              </label>
          </span>
        </CngGridItem>
      )} 
      {!props.approvedSIClicked && (
        <CngGridItem item xs={12} sm={12}>
          <span className={`summary-count-label-wrapper mt-25 mb-10`}>
              <label>
                  {translatedTextsObject.shown} &#40;{props.shownCount}&#41;
              </label>
          </span>
        </CngGridItem>
      )} 
    </Grid>
  )
}

const ButtonTabComponent = Object.freeze({
  FormBody: FormBody
})

export default ButtonTabComponent
