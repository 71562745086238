import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Utils from 'src/views/shippinginstruction/shared/Utils'
import pathMap from 'src/paths/PathMap_ShippingInstruction'
import compliancePathMap from 'src/paths/PathMap_Compliance'
import helpdeskPathMap from 'src/paths/PathMap_Helpdesk'
import { components, useServices } from 'cng-web-lib'
import ActionMenuSIList from './ActionMenuSIList'
import ActionMenuSIDetail from './ActionMenuSIDetail'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ComplianceTranslationText from 'src/views/compliance/ComplianceTranslationText'
import ShippingInstructionApiUrls from 'src/apiUrls/ShippingInstructionApiUrls.js'
import ComplianceApiUrls from 'src/apiUrls/ComplianceApiUrls'
import CommonUtils from 'src/views/common/utils/Utils'
import DocumentApi from 'src/views/common/api/DocumentApi'
import * as CalistaConstant from 'src/constants/CalistaConstant'

const {
  button: { CngPrimaryButton, CngSecondaryButton },
  table: { useDefaultNotification }
} = components

function SIActionMenu(props) {
  const history = useHistory()

  const { si, page, refreshAction } = props
  console.log("si si xx", si)

  const complianceStatus = si.complianceStatus || ''

  let actions = complianceStatus !== '' ? Utils.getPermitStatusById(complianceStatus).actions : []
  let actionsH = complianceStatus !== '' ? Utils.getPermitStatusById(complianceStatus).actionsH : []

  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const complianceTranslationText = ComplianceTranslationText()
  const { createRecord } = useServices()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [, setPrintDialogOpen] = useState(false)
  const [, setBlForPrint] = useState()
  const [remainingPrint, setRemainingPrint] = useState()
  const [, setIsPil] = useState(false)
  const [confirmAction, setConfirmAction] = useState(Utils.Action.delete) //need to initiable a value
  const [, setLoading] = useState(false)
  const { showNotification } = props
  const {fetchRecords,securedSendRequest } = useServices()
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const gotoMySI = () => {
    history.push({
      pathname: pathMap.MY_SI
    })
  }

  const gotoCreateNewSI = () => {
    history.push({
      pathname: pathMap.CREATE_SI
    })
  }

  const gotoHelpdeskViewSI = () => {
    history.push({
      pathname: helpdeskPathMap.SI
    })
  }

  const SIConfirmMessage = [
    {
      action: Utils.Action.delete,
      title: complianceTranslationText.confirmDeletePermitTitle,
      content: complianceTranslationText.confirmDeletePermitContent,
      okMsg: complianceTranslationText.confirmDeletePermitOption,
      cancelMsg: uiTranslatedTextsObject.cancel
    },
    {
      action: Utils.Action.cancel,
      title: complianceTranslationText.confirmCancelPermitTitle,
      content: complianceTranslationText.confirmCancelPermitContent,
      okMsg: complianceTranslationText.confirmCancelPermitOption,
      cancelMsg: uiTranslatedTextsObject.cancel
    },
    {
      action: Utils.Action.archive,
      title: complianceTranslationText.confirmArchivePermitTitle,
      content: complianceTranslationText.confirmArchivePermitContent,
      okMsg: complianceTranslationText.confirmArchivePermitOption,
      cancelMsg: uiTranslatedTextsObject.cancel
    }
  ]

  function getSIDialogMessageByAction(action) {
    return SIConfirmMessage.find((b) => b.action == action)
  }

  const deleteSI = () => {
    console.log('delete NEW')
    setConfirmAction(Utils.Action.delete)
    setConfirmDialogOpen(true)
  }

  const proceedDeleteSI = () => {
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification('Permit deleted')
        window.location.reload()
      }
    }

    createRecord.execute(
      ComplianceApiUrls.DELETE_DRAFT,
      { complianceId: props.si.complianceId},
      onSuccess,
      onError
    )
  }

  const viewSI = () => {
    console.log('viewSI clicked')
  }

  const createSI = () => {
    console.log('createSI clicked')
  }

  const trackContainers = () => {
    console.log('trackContainers clicked')
  }


  const confirmDialog = () => {
    console.log('confirmAction--> ' + confirmAction)
    setConfirmDialogOpen(false) //close the dialog first
    switch (confirmAction) {
      case Utils.Action.archive:
        proceedArchiveSI()
        break

      case Utils.Action.delete:
        proceedDeleteSI()
        break

      default:
        break
    }
  }

  function redirectToMySI() {
    console.log('redirectToMySI page', page)
    if (page === Utils.Page.SIDetail) {
      gotoMySI()
    } else {
      //if action is done from menu list, then just refresh the list
      refreshAction()
    }
  }

  function onError(error) {
    console.log('connection-request error', error.message)
    showErrorNotification(CommonUtils.getErrorMessage(error))
  }

  const archiveSI = () => {
    console.log('archive NEW')
    setConfirmDialogOpen(true)
    setConfirmAction(Utils.Action.archive)
  }

  const proceedArchiveSI = () => {
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${complianceTranslationText.archiveSuccessMsg} `)
        redirectToMySI()
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.ARCHIVE_SI,
      { shippingInfoId: si.shippingInfoId, freightBookingId: si.freightBookingId },
      onSuccess,
      onError
    )
  }

  const manageSI = (prevPage) => (event) => {
    history.push({
      pathname: pathMap.MY_SI_MANAGE_SI,
      state: {
        shippingInfo: props.si,
        prevPage: prevPage,
        action: Utils.Action.edit
      }
    })
  }

  const gotoManagePermit = () =>  {
    history.push({
      pathname: compliancePathMap.MANAGE_PERMIT,
    })
  }

  const createPermit = (prevPage) => (event) => {
    console.log('hi')
    history.push({
      pathname: compliancePathMap.CREATE_NEW_PERMIT,
      state: {
        prevPage: prevPage,
        shippingInfo: props.si,
        action: Utils.Action.createPermit
      }
    })
  }

  const updatePermit = () => {
    console.log('Updating permit',si)

    const complianceId = si.complianceId
    console.log("Compliance ID", complianceId)
    setLoading(true)

    let outPermit = false
    let inPermit = false

    if (si.carType.startsWith("OUT")){
      outPermit = true
    }else if (si.carType.startsWith("IN") || si.carType.startsWith("IP")){
      inPermit = true
    }

    if(si.complianceStatus == 5401){ //draft permit
      history.push({
        pathname: compliancePathMap.CREATE_PERMIT_UPDATE_DRAFT,
        state: {
          fromViewPermit: true,
          fromListView:true,
          action: Utils.Action.edit,
          shippingInfoCompliance: si,
          inPermit: inPermit,
          outPermit: outPermit
        }
      })
    }else{
      fetchRecords.execute(
        ComplianceApiUrls.ENQUIRE_STATUS,
        {
          customData: {complianceId: complianceId}
        },
        (data) => {
          console.log('Enquiry successful', data)
          console.log('Check before enquire status: ',si.complianceStatus)
          console.log('Check after enquire status: ',data.complianceStatus)

          if(si.complianceStatus == data.complianceStatus){
            history.push({
              pathname: compliancePathMap.CREATE_PERMIT_UPDATE_DRAFT,
              state: {
                fromViewPermit: true,
                fromListView:true,
                action: Utils.Action.edit,
                shippingInfoCompliance: data,
                inPermit: inPermit,
                outPermit: outPermit
              }
            }) 
          } else if(si.complianceStatus != data.complianceStatus){
            history.push({
              pathname: compliancePathMap.MANAGE_VIEW_PERMIT,
              state:{
                complianceId: complianceId,
                inPermit: inPermit,
                outPermit: outPermit
              }
            })
          }          
        },
        (error) => {
          console.error('Error enquiring status:', error)
        }
      )
    }
  }

  const enquireStatus = () => {
    console.log(si)

    const complianceId = props.si?.complianceId
    setLoading(true)
    
    fetchRecords.execute(
      ComplianceApiUrls.ENQUIRE_STATUS,
      {
        customData: {complianceId: complianceId}
      },
      (data) => {
        console.log('Enquiry successful', data)
        if (data.errorMessages != null && data.errorMessages.length > 0) {
            console.log("enquiry error");
            setLoading(false);
            showErrorNotification(data.errorMessages);
        } else {
            console.log("enquiry success");
            window.location.reload();
        }
    },
      (error) => {
        console.error('Error enquiring status:', error)
      }
    )
  }

  const downloadClick = () => {
    console.log(si)
    console.log('docid'+ props.si.shippingInfoCompliancePermit.declarationDocId)

    let permitDoc = {docId: props.si.shippingInfoCompliancePermit.declarationDocId,
                    filename: props.si.shippingInfoCompliancePermit.declarationFileName}
    DocumentApi.downloadDocument(
      securedSendRequest,
      permitDoc,
      CalistaConstant.MODULE_SI
    );
  };
  
  const onDownloadPermit = () => {
    downloadClick();
  };

  const deletePermit = () => {
    console.log('delete permit')
    setConfirmAction(Utils.Action.delete)
    setConfirmDialogOpen(true)
  }

  const proceedDeletePermit = () => {
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      // setLoading(false)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(`${complianceTranslationText.deleteSuccessMsg} `)
        gotoManagePermit()
      }
    }

    createRecord.execute(
      ComplianceApiUrls.DELETE_DRAFT,
      { complianceId: props.si.complianceId},
      onSuccess,
      onError
    )
  }

  const printBL = () => {
    console.log('remaining print', remainingPrint)
    if (CommonUtils.isEmpty(remainingPrint)) {
      let remainingPrintNo =
        props.si.noOfOriginals - props.si.noOfOriginalsPrinted
      setRemainingPrint(remainingPrintNo)
    }
    setBlForPrint(props.si)

    setIsPil(Utils.isThisPartyPIL(props.si.carrierName))

    if (
      remainingPrint < 1 ||
      props.si.noOfOriginals - props.si.noOfOriginalsPrinted < 1
    ) {
      showErrorNotification(complianceTranslationText.proxyPrintNoPrintMessage)
    } else {
      setPrintDialogOpen(true)
    }
  }

  

  const surrenderBL = () => {
    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(
          `${complianceTranslationText.surrenderSuccessMsg} `
        )
        redirectToMySI()
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.SHIPPER_SURRENDER_SI,
      { shippingInfoId: si.shippingInfoId, freightBookingId: si.freightBookingId },
      onSuccess,
      onError
    )
  }

  const copySI = () => {
    setConfirmDialogOpen(false) //close the dialog first

    function onSuccess(response) {
      console.log('connection-request onSuccess', response)
      console.log(response)
      if (response.errorMessages != null) {
        showErrorNotification(response.errorMessages)
      } else {
        showSuccessNotification(
          `${complianceTranslationText.copySuccessMsg} ${si.bookingRefNo}`
        )
        history.push({
          pathname: pathMap.CREATE_SI_NEW_SI,
          state: { shippingInfo: response, action: Utils.Action.copy }
        })
      }
    }

    createRecord.execute(
      ShippingInstructionApiUrls.COPY_SI,
      { shippingInfoId: si.shippingInfoId, freightBookingId: si.freightBookingId },
      onSuccess,
      onError
    )
  }

  const eventHandlers = {
    gotoMySI,
    copySI,
    archiveSI,
    manageSI,
    deleteSI,
    viewSI,
    createSI,
    trackContainers,
    printBL,
    surrenderBL,
    updatePermit,
    enquireStatus,
    gotoCreateNewSI,
    gotoHelpdeskViewSI,
    createPermit,
    gotoManagePermit,
    deletePermit,
    proceedDeletePermit,
    onDownloadPermit
  }

  return (
    <div>
      {page === Utils.Page.PermitDetail && (
        <ActionMenuSIDetail
          actions={actionsH}
          si={si}
          eventHandlers={eventHandlers}
          helpdesk={props.helpdesk}
        />
      )}
      {page === Utils.Page.PermitList && (
        <ActionMenuSIList
          actions={actions}
          si={si}
          page={page}
          eventHandlers={eventHandlers}
          helpdesk={props.helpdesk}
        />
      )}

      <ConfirmDialog
        isConfirmDialogOpen={confirmDialogOpen}
        closeDialog={() => setConfirmDialogOpen(false)}
        confirmDialog={confirmDialog}
        content={getSIDialogMessageByAction(confirmAction).content}
        okMsg={getSIDialogMessageByAction(confirmAction).okMsg}
        cancelMsg={getSIDialogMessageByAction(confirmAction).cancelMsg}
        title={getSIDialogMessageByAction(confirmAction).title}
      />
    </div>
  )
}

export default SIActionMenu
