import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid
} from '@material-ui/core'
import React, { useState } from 'react'
import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { components } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText'

const {
    form: {
        adapter: {
        useFormAdapter: { useField, useFormikContext }
        },
        field: {
        CngSelectField,
        CngRadioGroupField,
        CngSwitchField,
        CngCodeMasterAutocompleteField,
        CngTextField,
        CngDateTimeField
        }
    },
    CngGridItem,
    table: { useDefaultNotification }
} = components

const initialValues = Object.freeze({
  shippingInfoComplianceLicence: {
    licNo1: '',
    licNo2: '',
    licNo3: '',
    licNo4: '',
    licNo5: ''
  }
})

const FormBody = (props) => {
    const [licenseDetailsExpanded, setLicenseDetailsExpanded] = useState(false)
    const ComplianceTranslatedObject = ComplianceTranslationText()
    const isUpdatable = props.licenceData?.complianceStatus !== 5404
    const handleLicenseDetailsExpanded = () => {
        setLicenseDetailsExpanded(!licenseDetailsExpanded)
    }

    return(
        <Card>
            <Accordion expanded={licenseDetailsExpanded}>
                <AccordionSummary>
                    <Grid container>
                        <CngGridItem xs={6} sm={6}>
                            <AccordionHeaderComponent 
                                title = {ComplianceTranslatedObject.licenseTitle}
                            />
                        </CngGridItem>

                        <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type= 'text'
                                    name = 'shippingInfoComplianceLicence.licNo1'
                                    label = {ComplianceTranslatedObject.licenseNo1}
                                    isRequired
                                    inputProps = {{ maxLength: 35 }}
                                    disabled={!isUpdatable}
                                >
                                </CngTextField>
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type= 'text'
                                    name = 'shippingInfoComplianceLicence.licNo2'
                                    label = {ComplianceTranslatedObject.licenseNo2}
                                    inputProps = {{ maxLength: 35 }}
                                    disabled={!isUpdatable}
                                >
                                </CngTextField>
                            </CngGridItem>
                            <CngGridItem xs={4} sm={4}>
                                <CngTextField
                                    type= 'text'
                                    name = 'shippingInfoComplianceLicence.licNo3'
                                    label = {ComplianceTranslatedObject.licenseNo3}
                                    inputProps = {{ maxLength: 35 }}
                                    disabled={!isUpdatable}
                                >
                                </CngTextField>
                            </CngGridItem>
                        </Grid>
                    </Grid>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container xs={12} sm={12} spacing={2}>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'shippingInfoComplianceLicence.licNo4'
                                label = {ComplianceTranslatedObject.licenseNo4}
                                inputProps = {{ maxLength: 35 }}
                                disabled={!isUpdatable}
                            >
                            </CngTextField>
                        </CngGridItem>
                        <CngGridItem xs={4} sm={4}>
                            <CngTextField
                                type= 'text'
                                name = 'shippingInfoComplianceLicence.licNo5'
                                label = {ComplianceTranslatedObject.licenseNo5}
                                inputProps = {{ maxLength: 35 }}
                                disabled={!isUpdatable}
                            >
                            </CngTextField>
                        </CngGridItem>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <AccordionFooterControlComponent
                handleAccordionExpand={handleLicenseDetailsExpanded}
                expanded={licenseDetailsExpanded}
                footerText={ComplianceTranslatedObject.additionalLicenseNo}
            />
        </Card>
    )
}

const LicenseDetailsComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default LicenseDetailsComponent;
