import React, { useContext, useEffect, useRef, useState } from 'react'
import Box from '@material-ui/core/Box'
import CancelButton from 'src/components/button/CancelButton.js'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import PartyDetailsContext from './PartyDetailsContext.js'
import { Grid, AccordionSummary, Card, Button } from '@material-ui/core'
import ResetButton from 'src/components/button/ResetButton'
import { components } from 'cng-web-lib'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import CountrySelectAutoComplete from 'src/views/common/ui/CountrySelectAutoComplete'
import { v4 as uuid } from 'uuid'
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'
import useCompliancePartiesValidationSchema from '../shared/validation/useCompliancePartiesValidationSchema'

const {
  form: { CngViewForm },
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngPackageUomAutocompleteField,
      CngCodeMasterAutocompleteField
    }
  },
  CngGridItem,
  button: { CngPrimaryButton },
  table: { useDefaultNotification }
} = components

function PartyDetailsForm(props) {
  const dialogContext = useContext(PartyDetailsContext)

  const {
    closeDialog,
    currentRowIdx,
    partyListField,
    showNotification,
    setPartyListField,
    form: { isSubmitting }
  } = dialogContext

  const { error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onSubmit(data) {
    console.log('submit ', data)
    console.log('partyListField', partyListField)

    //validate if the party type already exist
    let newList = [...partyListField]
    if (currentRowIdx == null) {
      //add new items in the list
      newList.push(data)
    } else {
      //update existing item
      newList[currentRowIdx] = data
    }

    if (partyTypeDuplicate(newList)) {
      showErrorNotification('you cannot add duplicated party')
    } else {
      setPartyListField(newList)
      closeDialog()
    }
  }

  const validationSchema = useCompliancePartiesValidationSchema()

  function partyTypeDuplicate(list) {
    console.log('partyTypeDuplicate', list)
    let valueArr = list.map(function (item) {
      return item.partyType
    })
    let isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx
    })
    console.log(isDuplicate)
    return isDuplicate
  }
  return (
    <CngViewForm
      showNotification={showNotification}
      formikProps={{
        initialValues: { ...initialValues },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          currentRowIdx={currentRowIdx}
          partyListField={partyListField}
          showNotification={showNotification}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({
  closeDialog,
  isSubmitting,
  currentRowIdx,
  partyListField
}) {
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const partyTranslatedTextObject = CompanyPreferenceTranslationText()
  const { setFieldValue, submitForm } = useFormikContext()
  const [showContactField, setShowContactField] = useState(false)

  const [showCityCode, setShowCityCode] = useState(false) 
  const [showCRNo, setShowCRNo] = useState(true)
  const [showClaimantField, setShowClaimantField] = useState(false)
  const [partyTypeField, ,] =useField('partyType')

  const isEdit = useRef(false)
  const key = useRef(uuid())

  function setShowHideField(partyType) {
    console.log("setShowHideField" + partyType)
    console.log(partyTypeField)

    let isDeclarantShown = (partyType === 'Declarant')
    let isCityCodeShown = (partyType === 'Exporter' || partyType === 'Consignee' || partyType === 'End User' || partyType === 'Manufacturer')
    let isConsignee = (partyType === 'Consignee')
    let isClaimant = (partyType ==='Claimant')

    if (isDeclarantShown) {
      setShowContactField(true)
    } else {
      setShowContactField(false)
    }

    if (isCityCodeShown) {
      setShowCityCode(true)
    }
    else {
      setShowCityCode(false)
    }

    if(isConsignee){
      setShowCRNo(false)
    }
    else{
      setShowCRNo(true)
    }
    if(isClaimant){
      setShowClaimantField(true)
    }else {
      setShowClaimantField(false)
    }
  }

  function onPartyTypeChange(e) {

    setShowHideField(e)
  }

  const resetForm = () => {
    const dataArr = Object.entries(DEFAULT_INITIAL_VALUES)
    dataArr.forEach(([key, val]) => {
      setFieldValue(key, val)
    })
  }

  useEffect(() => {
    console.log('currentRowIdx', currentRowIdx)
    if (currentRowIdx != null) {
      populateFormFields()
    }
    key.current = uuid()
  }, [currentRowIdx])

  function populateFormFields() {
    const dataArr = Object.entries(partyListField[currentRowIdx])
    console.log('Check party log', partyListField[currentRowIdx])

    setShowHideField(partyListField[currentRowIdx].partyType)

    dataArr.forEach(([key, val]) => {
      setFieldValue(key, val)
    })
    isEdit.current = true
  }

  return (
    <Box m={1.5}>
      <Card>
        <AccordionSummary>
          <Grid container xs={12} sm={12}>
            <CngGridItem xs={8} sm={8}>
              <AccordionHeaderComponent title={'Party Information'} />
            </CngGridItem>
            <CngGridItem xs={4} sm={4}>
              <Box pr={2} pb={2}>
                {/*reserved for future use <Button
                  variant='contained'
                  classes={{ root: 'ng-button-filled-secondary' }}
                  className='originalText'
                >
                  {siTranslatedTextsObject.partyTemplateButton}
                </Button> */}
              </Box>
            </CngGridItem>
            <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
              <CngGridItem xs={6} sm={6}>
                <CngCodeMasterAutocompleteField
                  name='partyType'
                  label={'Party type'}
                  codeType='CAL_COMP_PARTY_TYPE'
                  key={key.current}
                  onChange={onPartyTypeChange}
                  isRequired
                />
              </CngGridItem>
              
              {showCRNo &&(
              <CngGridItem xs={6} sm={6}>
                <CngTextField
                  type='text'
                  name='crno'
                  label={'Cr no. / Unique identifier'}
                  inputProps={{ maxLength: 50 }}
                  isRequired
                ></CngTextField>
              </CngGridItem>
              )}

              <CngGridItem xs={6} sm={6}>
                <CngTextField
                  type='text'
                  name='name'
                  label={'Name'}
                  inputProps={{ maxLength: 50 }}
                  isRequired
                ></CngTextField>
              </CngGridItem>

              {!showContactField && (
                <><CngGridItem xs={6} sm={6}>
                  <CngTextField
                    type='text'
                    name='name2'
                    label={'Name'}
                    inputProps={{ maxLength: 50 }}
                  ></CngTextField>
                </CngGridItem>
                </>
              )}
              {showContactField && (
                <CngGridItem xs={6} sm={6}>
                  <CngTextField
                    type='text'
                    name='phone'
                    label={'Phone no.'}
                    inputProps={{ maxLength: 17 }}
                    isRequired
                  ></CngTextField>
                </CngGridItem>
              )
              }

              {(showCityCode) && (
                <>
                  <CngGridItem xs={6} sm={6}>
                    <CngTextField
                      type='text'
                      name='address'
                      label={'Address'}
                      inputProps={{ maxLength: 50 }}
                      isRequired
                    ></CngTextField>
                  </CngGridItem>
                  <CngGridItem xs={6} sm={6}>
                    <CngTextField
                      type='text'
                      name='address2'
                      label={'Address'}
                      inputProps={{ maxLength: 50 }}
                    >
                    </CngTextField>
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <CngTextField
                      type='text'
                      name='city'
                      label={'City'}
                      inputProps={{ maxLength: 17 }}
                    ></CngTextField>
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <CountrySelectAutoComplete
                      name='country'
                      label={partyTranslatedTextObject.country}
                      key={key.current}
                      required
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <CngTextField
                      type='text'
                      name='state'
                      label={partyTranslatedTextObject.state}
                      inputProps={{ maxLength: 20 }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={4} sm={4}>
                    <CngTextField
                      type='number'
                      name='postalCode'
                      label={partyTranslatedTextObject.postalCode}
                      inputProps={{ maxLength: 20 }}
                    />
                  </CngGridItem>
                </>
              )
              }
              {showClaimantField && (
                <>
                  <CngGridItem xs={6} sm={6}>
                    <CngTextField
                      type='text'
                      name='claimantPersonCode'
                      label={'Person code'}
                      inputProps={{ maxLength: 17 }}
                      isRequired
                    ></CngTextField>
                  </CngGridItem>

                  <CngGridItem xs={6} sm={6}>
                    <CngTextField
                      type='text'
                      name='claimantPersonName'
                      label={'Person name'}
                      inputProps={{ maxLength: 50 }}
                      isRequired
                    ></CngTextField>
                  </CngGridItem>
                </>
              )
              }
            </Grid>
          </Grid>
        </AccordionSummary>
      </Card>

      <Box display='flex' mt={2}>
        <Grid xs={6} sm={6} justify='flex-start'>
          <ResetButton onClick={resetForm} disabled={isSubmitting} />
        </Grid>

        <Grid container xs={6} sm={6} justify='flex-end'>
          <Box pr={2}>
            <CancelButton onClick={closeDialog} disabled={isSubmitting} />
          </Box>
          <Box>
            <CngPrimaryButton
              type='button'
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {uiTranslatedTextsObject.saveChanges}
            </CngPrimaryButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyType: '',
  name: '',
  address: '',
  name2: '',
  address2: '',
  phone: '',
  city: '',
  country: '',
  state: '',
  postalCode: '',
  crno: ''
})

const initialValues = {
  ...DEFAULT_INITIAL_VALUES
}

export default PartyDetailsForm
