import { Box, Paper, FormControlLabel } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'
import PermitTypeComponent from '../components/PermitTypeComponent'
import CargoComponent from '../components/CargoComponent'
import ComplianceContainerComponent from '../components/ComplianceContainerComponent'
import TransportComponent from '../components/TransportComponent'
import PartiesComponent from '../components/PartiesComponent'
import LicenseDetailsComponent from '../components/LicenseDetailsComponent'
import DocumentComponent from '../components/DocumentsComponent'
import ItemsComponent from '../components/ItemsComponent'
import InvoiceComponent from '../components/InvoiceComponent'
import ItemSummaryComponent from '../components/ItemSummaryComponent'
import ComplianceButtonComponent from '../components/ComplianceButtonComponent'
import ConfirmDialog from 'src/components/dialog/ConfirmDialog'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import ComplianceTranslationText from '../ComplianceTranslationText'
import GeneralStepperComponent from 'src/views/common/ui/GeneralStepperComponent'
import Utils from 'src/views/common/utils/Utils'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import SIApi from 'src/views/shippinginstruction/shared/api'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import WarningDialog from 'src/components/dialog/WarningDialog'
import { v4 as uuid } from 'uuid'
import { useFormContext } from 'react-hook-form'
import pathMap from 'src/paths/PathMap_Compliance'
import ComplianceApi from '../shared/api'
import useCreatePermitMakeValidationSchema from './useCreatePermitMakeValidationSchema'
import useCreatePermitMakeValidationInSchema from './useCreatePermitMakeValidationInSchema'
import useCreatePermitMakeValidationInPaySchema from './useCreatePermitMakeValidationInPaySchema'
import useCreatePermitMakeValidationInBlanketSchema from './useCreatePermitMakeValidationInBlanketSchema'
import useCreatePermitMakeValidationInPayBlankeSchema from './useCreatePermitMakeValidationInPayBlanketSchema'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field:{CngCheckboxField}
  },
 
  table: { useDefaultNotification },
  CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ...PermitTypeComponent.initialValues,
  ...CargoComponent.initialValues,
  ...TransportComponent.initialValues,
  ...LicenseDetailsComponent.initialValues,
  ...ItemSummaryComponent.initialValues,
  ...ComplianceContainerComponent.initialValues,
  particularsDeclaration: false
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES }
}

function FormFields({
  showNotification,
  loading,
  prevMenu,
  setLoading,
  onSubmitForm
}) {
  const { reset, getValues, setError, watch } = useFormContext()
  const history = useHistory()
  const location = useLocation()
  const [fromReview, setFromReview] = useState(location.state.fromViewPermit)
  const [fromSI, setFromSI] = useState(false)
  const stateData = location.state
  const [polEtd, setPolEtd] = useState()
  const podKeyRef = useRef(uuid())
  const totalItemQuantity = useRef()
  const [particularsDeclarationCheckBox, ,] = useField('particularsDeclaration')

  const [si, setSI] = useState({})
  const [SICompliance, setSICompliance] = useState({})

  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const complianceTrasnlatedTextsObject = ComplianceTranslationText()
  const fbTranslatedTextsObject = FreightBookingTranslationText()

  const validationSchema = useCreatePermitMakeValidationSchema()
  const validationSchemaIn = useCreatePermitMakeValidationInSchema()
  const validationSchemaInPay = useCreatePermitMakeValidationInPaySchema()
  const validationSchemaInBlanket = useCreatePermitMakeValidationInBlanketSchema()
  const validationSchemaInPayBlanket = useCreatePermitMakeValidationInPayBlankeSchema()


  const [isPaymentField] = useField('payment')
  const isPaymentWatch = watch('payment')

  const [isBlanketField] = useField('decType')
  const isBlanketWatch = watch('decType')

  const [withValidationSchema, setWithValidationSchema] = useState()


  const isEdit = useRef(false)

  const [state, setState] = useState({
    particularsDeclaration: false,
  })

  function setTotalItemQuantity(value) {
    totalItemQuantity.current = value
    setShouldRender(!shouldRender)
  }

  const handleChange = (event) => {
    setFieldValue(event.target.name, event.target.checked)
    setState({ ...state, [event.target.name]: event.target.checked });
  }

  const { fetchRecords } = useServices()
  const { setFieldValue } = useFormikContext()

  const [shouldRender, setShouldRender] = useState(false)
  const [warningDialogOpen, setWarningDialogOpen] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const setValidatedError = (errorPath, message) => {
    let finalError = null
    let errorAttr = ''
    if (errorPath && errorPath.includes('.')) {
      let errorObj = {}
      const arrErrorPath = errorPath.split('.')
      errorObj[arrErrorPath[1]] = message
      if (arrErrorPath[0].includes('[')) {
        const matches = arrErrorPath[0].match(/\[([^[\]]*)\]/)
        if (matches) {
          errorAttr = arrErrorPath[0].replace(matches[0], '')
          const number = matches[1]
          finalError = []
          for (let i = 0; i < number; i++) {
            finalError.push(null)
          }
          finalError.push(errorObj)
        }
      } else {
        errorAttr = arrErrorPath[0]
        finalError = errorObj
      }
    } else {
      errorAttr = errorPath
      finalError = { message: message }
    }
    if (errorAttr && finalError) {
      setError(errorAttr, finalError)
    }
  }

  const onConfirmReview = async () => {
    if(!particularsDeclarationCheckBox.value){
      alert('please confirm your declaration first!')
      return
    }
    await setFieldValue('saveDraft', false)

    if (onSubmitForm) {
      const values = getValues()
      if(stateData.fromViewPermit == true)
      {
        setFromReview(true)
      }

      if(fromReview == true )
      {
        values.shippingInfoId = SICompliance.shippingInfoId
        values.shipperPartyId = SICompliance.shipperPartyId
        values.carrierPartyId = SICompliance.carrierPartyId
        //if is approved permit
        values.amendPermit = SICompliance.amendPermit || SICompliance.complianceStatus === 5404 || SICompliance.complianceStatus === 5406 || SICompliance.complianceStatus === 5407;
      }else{
        values.shippingInfoId = si.shippingInfoId
        values.shipperPartyId = si.shipperPartyId
        values.carrierPartyId = si.carrierPartyId
      }
      try {

      const validatedData = await withValidationSchema.validate(values)

      onSubmitForm(validatedData)
      } catch (error) {
        console.log('error', error);
        setValidatedError(error.path, error.message)
        setWarningDialogOpen(true)
      }
    }
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }

  const onSaveDraft = async () => {
    await setFieldValue('saveDraft', true)

    if (onSubmitForm) {
      const values = getValues()

      if(stateData.fromViewPermit == true)
      {
        setFromReview(true)
      }

      if(fromReview == true )
      {
        values.shippingInfoId = SICompliance.shippingInfoId
        values.shipperPartyId = SICompliance.shipperPartyId
        values.carrierPartyId = SICompliance.carrierPartyId
        //if is approved permit
        values.amendPermit = SICompliance.amendPermit || SICompliance.complianceStatus === 5404 || SICompliance.complianceStatus === 5406 || SICompliance.complianceStatus === 5407;
      }else{
        values.shippingInfoId = si.shippingInfoId
        values.shipperPartyId = si.shipperPartyId
        values.carrierPartyId = si.carrierPartyId
      }
      
      try {
        const validatedData = await withValidationSchema.validate(values)
        onSubmitForm(validatedData)
      } catch (error) {
        console.log('error', error)
        setValidatedError(error.path, error.message)
        setWarningDialogOpen(true)
      }
    }
  }

  const onDiscard = () => {
    setConfirmDialogOpen(true)
  }

  const discardPermit = () => {
    
    history.push({
      pathname: pathMap.MANAGE_PERMIT
    })

  }

  function populatePermitData(data){        
    setSICompliance(data)
  } 

  const permitRequest = {
    complianceId: stateData.shippingInfoCompliance?.complianceId
  }

  useEffect(() => {
    console.log('new permit', stateData)

    setLoading(true)
    let shippingInfoId = ''

    if(stateData.fromSI){
      setFromSI(true)
    }

    if (stateData.shippingInfo) {
      shippingInfoId = stateData.shippingInfo.shippingInfoId

    }else if (stateData.fromListView) {
      shippingInfoId = stateData.shippingInfoCompliance?.shippingInfoId

      ComplianceApi.fetchPermitById(fetchRecords,permitRequest,populatePermitData)

    }else if (stateData.shippingInfoCompliance) {
      shippingInfoId = stateData.shippingInfoCompliance?.shippingInfoId
    }
  
    const siRequest = {
      shippingInfoId: shippingInfoId
    }
    SIApi.fetchSIDetailsById(fetchRecords, siRequest, populateSIData)
    
    if(stateData?.shippingInfoCompliance != null){
      
      setSICompliance(stateData?.shippingInfoCompliance)

    }

    if(stateData.fromViewPermit == true)
    {
       setFromReview(true)
    }
  }, [])

  function populateSIData(siData) {
    console.log('populateSIData', siData)
    setPolEtd(siData.booking.polEtd)
    setSI(preProcessSIData(siData))
    setLoading(false)
  }

  function preProcessSIData(siData) {

    //Deleting unwanted field
    delete siData.agentAddress
    delete siData.agentName
    delete siData.alertMessages
    delete siData.alignContainerWithCargo
    delete siData.approvedBy
    delete siData.approvedByUserName
    delete siData.approvedDate
    delete siData.baseFilterDTO
    delete siData.billOfLadingAppType
    delete siData.billOfLadingAppTypeDesc
    delete siData.bookingRepresentativeDetail
    delete siData.bookingSubmittedDate
    delete siData.carrierClauseCode
    delete siData.carrierClauseDec
    delete siData.carrierClosed
    delete siData.carrierClosedDate
    delete siData.carrierRemarks
    delete siData.chrgType
    delete siData.closed
    delete siData.closedDate
    delete siData.compRef
    delete siData.conContactNo
    delete siData.conEmail
    delete siData.conId
    delete siData.correcltionLogDesc
    delete siData.createdBy
    delete siData.createdDate
    delete siData.customData
    delete siData.depot
    delete siData.destNotifyAddress
    delete siData.destNotifyName
    delete siData.dstHaulier
    delete siData.dstNotifyContactNo
    delete siData.dstNotifyEmail
    delete siData.dstNotifyId
    delete siData.ediSentDate
    delete siData.errorMessages
    delete siData.fhOutboundId
    delete siData.formLayout
    delete siData.forwardHubOutboundRefNo
    delete siData.forwarderRefNo
    delete siData.freightCharge
    delete siData.freightTerm
    delete siData.hazardous
    delete siData.issueDate
    delete siData.noOfOriginals
    delete siData.noOfOriginalsPrinted
    delete siData.notifyPartnerEmails
    delete siData.orderNo
    delete siData.particularsDeclaration
    delete siData.partnerEmails
    delete siData.payer
    delete siData.paymentLocation
    delete siData.porStatus
    delete siData.preCarriageVesselName
    delete siData.preCarriageVoyageNo
    delete siData.preCarriageVoyageNo
    delete siData.updatedBy
    delete siData.updatedDate
    delete siData.version
    delete siData.versionNo
    
    
    siData.containerNo = ''
    siData.shipperPlatform = 2003
    siData.inPermit = stateData.inPermit

    //Need to update this according to in and out and payment
    if(siData?.complianceStatus == null || siData?.complianceStatus == 5401 || siData?.complianceStatus == 5402 || siData?.complianceStatus == 5403)
    {
      if(!stateData.inPermit)
      {
        siData.carType = "OUTDEC"
      }
      
    }
    else if(siData?.complianceStatus == 5404)
    {
      if(!stateData.inPermit == true)
      {
        siData.carType = "OUTUPD"
      }
    }
    

    if(stateData.inPermit == true){
      let shippingInfoComplianceTransport = {}
      shippingInfoComplianceTransport.vessName = siData.vesselName
      shippingInfoComplianceTransport.inTptMode = "1"
      shippingInfoComplianceTransport.inTptId = siData.vesselName
      shippingInfoComplianceTransport.inCvyRefNo = siData.voyageNo
      shippingInfoComplianceTransport.voyNo = siData.voyageNo
      shippingInfoComplianceTransport.inMasterShipDoc = siData.billOfLadingNo
      shippingInfoComplianceTransport.loadingPort = siData.booking?.polCode
      shippingInfoComplianceTransport.ctyDestn = siData?.podCode?.substring(0,2)
      shippingInfoComplianceTransport.arrivalDate =stateData.shippingInfoCompliance?.shippingInfoComplianceTransport?.arrivalDate
      ? stateData.shippingInfoCompliance?.shippingInfoComplianceTransport?.arrivalDate
      : siData?.booking?.podEta

      siData.shippingInfoComplianceTransport = shippingInfoComplianceTransport
    }
    else if(stateData.inPermit == false){
      let shippingInfoComplianceTransport = {}
      shippingInfoComplianceTransport.vessName = siData.vesselName
      shippingInfoComplianceTransport.outTptMode = "1"
      shippingInfoComplianceTransport.outTptId = siData.vesselName
      shippingInfoComplianceTransport.outCvyRefNo = siData.voyageNo
      shippingInfoComplianceTransport.voyNo = siData.voyageNo
      shippingInfoComplianceTransport.outMasterShipDoc = siData.billOfLadingNo
      shippingInfoComplianceTransport.dischargePort = siData.booking?.podCode
      shippingInfoComplianceTransport.ctyDestn = siData?.podCode?.substring(0,2)
      shippingInfoComplianceTransport.deptDate = stateData.shippingInfoCompliance?.shippingInfoComplianceTransport?.deptDate
      ? stateData.shippingInfoCompliance?.shippingInfoComplianceTransport?.deptDate
      : siData?.booking?.polEtd

      siData.shippingInfoComplianceTransport = shippingInfoComplianceTransport
    }

    let shippingInfoComplianceContainers = []

    if (fromReview) {
      console.log('Test from view or review', siData)

      if(stateData && stateData.shippingInfoCompliance?.shippingInfoComplianceCargo?.shippingInfoComplianceContainers && stateData.shippingInfoCompliance?.shippingInfoComplianceCargo?.shippingInfoComplianceContainers.length > 0){
        for (let container of stateData.shippingInfoCompliance?.shippingInfoComplianceCargo?.shippingInfoComplianceContainers) {

          let ctnTypeSize = container.ctnTypeSize
          let ctnNo = container.ctnNo
          let ctnWtValue = container.ctnWtValue
          let ctnSealNo = container.ctnSealNo
  
          // Create a new object with updated variable names
          let updatedContainer = {
            ctnNo,
            ctnTypeSize,
            ctnWtValue,
            ctnSealNo
          }
  
          shippingInfoComplianceContainers.push(updatedContainer)
          console.log("check new containers", shippingInfoComplianceContainers)
        }
  
        siData.shippingInfoComplianceContainers = [...shippingInfoComplianceContainers]
  
        siData.shippingInfoComplianceCargo = {
          shippingInfoComplianceContainers: [...shippingInfoComplianceContainers]
        }
      }else{
        console.log("No containers")
      }
    }else{
      if(siData && siData.shippingInfoContainerBoxes && siData.shippingInfoContainerBoxes.length > 0){

        for (let container of siData.shippingInfoContainerBoxes) {

          let ctnTypeSize = ''
  
          if(container.containerIsoSizeType == '22G1' 
          || container.containerIsoSizeType == '25G0'
          || container.containerIsoSizeType == '25R1'
          || container.containerIsoSizeType == '22R1'
          || container.containerIsoSizeType == '22T0'
          ){
            ctnTypeSize = 'FCL20'
          }
          else if(container.containerIsoSizeType == '42G1' 
          || container.containerIsoSizeType == '45G0'
          || container.containerIsoSizeType == '45R1'
          || container.containerIsoSizeType == '42R1'
          || container.containerIsoSizeType == '42T0'
          ){
            ctnTypeSize = 'FCL40'
          }
          else if(container.containerIsoSizeType == 'L2G1' 
          || container.containerIsoSizeType == 'L5G0'
          || container.containerIsoSizeType == 'L5R1'
          || container.containerIsoSizeType == 'L2R1'
          || container.containerIsoSizeType == 'L2T0'
          ){
            ctnTypeSize = 'FCL45'
          }  
  
          let ctnNo = container.containerNo
          let ctnWtValue = Math.round(container.grossWeight * 0.001)
          let ctnSealNo = container.sealNo
  
          // Create a new object with updated variable names
          let updatedContainer = {
            ctnNo,
            ctnTypeSize,
            ctnWtValue,
            ctnSealNo
          }
  
          shippingInfoComplianceContainers.push(updatedContainer)
          console.log("check new containers", shippingInfoComplianceContainers)
        }
  
        siData.shippingInfoComplianceCargo = {
          shippingInfoComplianceContainers: [...shippingInfoComplianceContainers]
        }
      }else{
        console.log("No containers")
      }
    }

    return siData
  }

  useEffect(() => {
    console.log('si changed', si)

    PopulateDataFromReview()
    PopulateDataFromReviewPermit()
  }, [si])

  useEffect(() => {
    console.log('Is Payment value for invoice: ', isPaymentField.value)
    if (stateData.inPermit){
      if(isPaymentField.value == true){
        if(isBlanketField.value =="BKT"){
          setWithValidationSchema(validationSchemaInPayBlanket)
        }else{
          setWithValidationSchema(validationSchemaInPay)
        }
      } else {
        if(isBlanketField.value =="BKT"){
          setWithValidationSchema(validationSchemaInBlanket)
        }else{
          setWithValidationSchema(validationSchemaIn)
        }
      }
    } else {
      setWithValidationSchema(validationSchema)
    }
    
    
}, [isPaymentWatch, isBlanketWatch])

  function PopulateDataFromReview() {
    console.log('populateDataFromReview(): si : ', si)
    if (si != null && !isEmpty(si)) {
      const dataArr = Object.entries(si)
      dataArr.forEach(([key, val]) => {
        if (SIUtils.dateTimeFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE_TIME)
        } else if (SIUtils.dateFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE)
        }

        //as we are using useArrayField for cargo/dg cargo, the setFieldValue cannot be null, hence need to convert null to [] for them
        else if (SIUtils.nonNullableArrayFields.includes(key)) {
          val = val === null ? [] : val
        }

        si[key] = val
      })
      resetCurrentForm(si)

      isEdit.current = true
      podKeyRef.current = uuid()
    }
  }

  function PopulateDataFromReviewPermit(){

    console.log('populateDataFromReviewPermit(): permit : ', SICompliance)

    if(SICompliance != null && !isEmpty(SICompliance)){

      const dataArr = Object.entries(SICompliance)
      dataArr.forEach(([key, val]) => {
        if (SIUtils.dateTimeFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE_TIME)
        } else if (SIUtils.dateFields.includes(key)) {
          val = Utils.formatString(val, Utils.UI_FORMAT_DATE)
        }

        else if (SIUtils.nonNullableArrayFields.includes(key)) {
          val = val === null ? [] : val
        }

        SICompliance[key] = val
      })

      //SICompliance.shippingInfoComplianceContainers = SICompliance.shippingInfoComplianceCargo.shippingInfoComplianceContainers

      resetCurrentForm(SICompliance)

      isEdit.current = true

    }
  }

  function resetCurrentForm(data) {
    console.log('reset', data)
    reset(data)
  }

  const newPermitSteps = [
    complianceTrasnlatedTextsObject.stepLabelFillupDetails,
    complianceTrasnlatedTextsObject.stepLabelReviewDetails,
    complianceTrasnlatedTextsObject.stepLabelSubmit
  ]

  return (
    <div>
      {loading ? (
        <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
          <CngBackdrop loading={loading} />
        </CngGridItem>
      ) : (
        <Box>
          <br />

          <CngGridItem xs={12} sm={9} shouldHide={loading ? false : true}>
            <CngBackdrop loading={loading} />
          </CngGridItem>

          <Paper>
            <Box alignItems='center' pr={15} pl={15}>
              <GeneralStepperComponent steps={newPermitSteps} activeStep={0} />
            </Box>

            <Box p={5} className='page-content'>
              <Box>
                <PermitTypeComponent.FormBody
                  isEdit={isEdit.current}
                  prevMenu={prevMenu}
                  showNotification={showNotification}
                  permitTypeData={SICompliance}
                  inPermit = {stateData.inPermit}
                />
              </Box>

              <Box pt={5}>
                <CargoComponent.FormBody
                  isEdit={isEdit.current}
                  prevMenu={prevMenu}
                  showNotification={showNotification}
                  cargoData={SICompliance}
                />
              </Box>

              <Box pt={5}>
                <ComplianceContainerComponent.FormBody
                  showNotification={showNotification}
                  isEdit={isEdit.current}
                  containerData = {fromReview ? SICompliance : si}
                  isUpdate = {fromReview}
                  fromSI = {fromSI}
                />
              </Box>

              <Box pt={5}>
                <TransportComponent.FormBody
                  isEdit={isEdit.current}
                  showNotification={showNotification}
                  transportData={fromReview ? SICompliance : si}
                  transportPolEtd={polEtd}
                  podKeyRef={podKeyRef.current}
                  inPermit={stateData.inPermit}
                />
              </Box>

              <Box pt={5}>
                <PartiesComponent.FormBody
                  isEdit={isEdit.current}
                  showNotification={showNotification}
                  partiesData={SICompliance}
                  inPermit = {stateData.inPermit}
                  isUpdate = {fromReview}
                />
              </Box>

              <Box pt={5}>
                <LicenseDetailsComponent.FormBody
                  isEdit={isEdit.current}
                  showNotification={showNotification}
                  licenceData={SICompliance}
                />
              </Box>

              <Box pt={5}>
                <DocumentComponent.FormBody
                  showNotification={showNotification}
                  isEdit={isEdit.current}
                  isFromViewPermit={fromReview}
                  documentData={fromReview ? SICompliance : si}
                />
              </Box>

              <Box pt={5}>
                <ItemsComponent.FormBody
                  isEdit={isEdit.current}
                  showNotification={showNotification}
                  setTotalItemQuantity={setTotalItemQuantity}
                  itemData={SICompliance}
                  inPermit = {stateData.inPermit}
                />
              </Box>

              {stateData.inPermit && isPaymentField.value ?(
                <>
                  <Box pt={5}>
                    <InvoiceComponent.FormBody
                      isEdit={isEdit.current}
                      isUpdate = {fromReview}
                      showNotification={showNotification}
                      invoiceData={SICompliance}
                    />
                  </Box>
                </>
              ) : null}

              <Box pt={5}>
                <FormControlLabel
                    control={<CngCheckboxField name="particularsDeclaration" checked={state.particularsDeclaration} onChange={handleChange} />}
                    label={complianceTrasnlatedTextsObject.declaration}     
                  />
              </Box>
            </Box>
          </Paper>
          <Paper className='sticky-footer'>
            <Box alignItems='center'>
              <ComplianceButtonComponent
                prevMenu={prevMenu}
                onSaveDraft={onSaveDraft}
                onConfirmReview={onConfirmReview}
                onDiscard={onDiscard}
              />
            </Box>
          </Paper>

          <WarningDialog
            isDialogOpen={warningDialogOpen}
            closeDialog={() => setWarningDialogOpen(false)}
            okDialog={() => setWarningDialogOpen(false)}
            content={fbTranslatedTextsObject.formErrorWarning}
            okMsg={uiTranslatedTextsObject.ok}
          />

          <ConfirmDialog
            isConfirmDialogOpen={confirmDialogOpen}
            closeDialog={() => setConfirmDialogOpen(false)}
            confirmDialog={discardPermit}
            content={complianceTrasnlatedTextsObject.dialogDiscardPermitContent}
            okMsg={uiTranslatedTextsObject.ok}
            cancelMsg={uiTranslatedTextsObject.cancel}
            title={complianceTrasnlatedTextsObject.dialogDiscardPermitTitle}
          />
        </Box>
      )}
    </div>
  )
}

const CreatePermitProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  FormFields: FormFields
})

export default CreatePermitProperties
