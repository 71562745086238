import { Box, Button, Grid } from '@material-ui/core'
import CalistaUiComponentTranslationText from '../../common/CalistaUiComponentTranslationText'
import React from 'react'

function DraftPermitButtonComponent(props){

    const translatedTextsObject = CalistaUiComponentTranslationText()

    return(

        <Box pl={5} pr={5} pt={4} pb={4}>
            <Grid container xs={12} sm={12}>
                <Grid xs={6} sm={6} justify='flex-start'>
                <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                    props.onDiscard()
                    }}
                    classes={{ root: 'ng-button-filled-secondary' }}
                >
                    {translatedTextsObject.discard}
                </Button>
                </Grid>
                <Grid container xs={6} sm={6} justify='flex-end' spacing={2}>
                <Box pr={2}>
                    <Button
                    variant='contained'
                    className='button-blue originalText'
                    onClick={() => {
                        props.onEdit()
                    }}
                    >
                    {translatedTextsObject.backToEditPermit}
                    </Button>
                </Box>
                <Box>
                    <Button
                    variant='contained'
                    className='button-light-green originalText'
                    onClick={() => {
                        props.onSubmitPermit()
                    }}
                    >
                    {translatedTextsObject.submitPermit}
                    </Button>
                </Box>
                </Grid>
            </Grid>
            </Box>

    )
}

export default DraftPermitButtonComponent