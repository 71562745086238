import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ComplianceTranslationText from '../ComplianceTranslationText'
import { components } from 'cng-web-lib'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'

const {
    form: {
      adapter: {
        useFormAdapter: { useField, useFormikContext }
      }
    },
    CngGridItem
} = components

const FormBody = (props) => {

    const ComplianceTranslatedTextsObject = ComplianceTranslationText()
    const [shouldRender, setShouldRender] = useState(false)

    useEffect(() => {

        setShouldRender(true)
    })

    if (!shouldRender) {
        return null
    }

    return(

        <Grid container xs={12} sm={12}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={6} sm={6}>
                                {ComplianceTranslatedTextsObject.itemsSummaryTitle}
                            </CngGridItem>
                        </Grid>
                    </Box>
                    <Box>
                        <Grid container xs={12} sm={12}>
                            <CngGridItem xs={6} sm={6}>
                                <LabelValueVertical
                                    label={ComplianceTranslatedTextsObject.totalItemQuantity}
                                    value={props.summaryData.shippingInfoComplianceSummary.totalItems}
                                />
                            </CngGridItem>
                            <CngGridItem xs={6} sm={6}>
                                <LabelValueVertical
                                    label={ComplianceTranslatedTextsObject.totalAmount}
                                    value={props.summaryData.shippingInfoComplianceSummary.totalCifFobAmount}
                                />
                            </CngGridItem>
                            <CngGridItem xs={3} sm={3}>
                                <LabelValueVertical
                                    label={ComplianceTranslatedTextsObject.totalOuterPackQuantity}
                                    value={props.summaryData.shippingInfoComplianceSummary.totalOutPackQuantityValue}
                                />
                            </CngGridItem>
                            <CngGridItem xs={3} sm={3}>
                                <LabelValueVertical
                                    label={ComplianceTranslatedTextsObject.totalOuterPackUnit}
                                    value={props.summaryData.shippingInfoComplianceSummary.totalOutPackQuantityUom}
                                />
                            </CngGridItem>
                            <CngGridItem xs={3} sm={3}>
                                <LabelValueVertical
                                    label={ComplianceTranslatedTextsObject.totalGrossWeight}
                                    value={props.summaryData.shippingInfoComplianceSummary.totalGrossWeightQuantityValue}
                                />
                            </CngGridItem>
                            <CngGridItem xs={3} sm={3}>
                                <LabelValueVertical
                                    label={ComplianceTranslatedTextsObject.totalGrossWeightUnit}
                                    value={props.summaryData.shippingInfoComplianceSummary.totalGrossWeightQuantityUom}
                                />
                            </CngGridItem>
                        </Grid>
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>

    )   
}   

const initialValues = Object.freeze({})

const ViewItemSummaryComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default ViewItemSummaryComponent