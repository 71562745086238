import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    Grid
  } from '@material-ui/core'
  import React, { useEffect, useRef, useState } from 'react'
  import { components, constants, useServices } from 'cng-web-lib'
  import AccordionFooterControlComponent from '../../common/ui/AccordionFooterControlComponet'
  import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
  import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
  import DocumentDetailsComponents from './DocumentsDetailsComponent'
  import * as CalistaConstant from 'src/constants/CalistaConstant'
  import DocumentApi from 'src/views/common/api/DocumentApi'
  import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
  import { v4 as uuid } from 'uuid'
  import { useFieldArray } from 'react-hook-form'
  import Utils from 'src/views/common/utils/Utils'
  
  const {
    form: {
      adapter: {
        useFormAdapter: { useField, useFormikContext }
      }
    },
    CngGridItem,
    dropzone: { CngFileUpload, HelperTextArea },
    table: { useDefaultNotification }
  } = components
  
  const { CodeMaintenanceType } = constants
  
  const FormBody = (props) => {
    const { fields: uploadedDocsField } = useFieldArray({
      name: 'shippingInfoComplianceSupportDoc'
    })
    const siTranslatedTextsObject = ShippingInstructionTranslationText()
    const {
      fetchRecords,
      securedSendRequest
    } = useServices()
    const [pendingDocs, setPendingDocs] = useState([]) //for the file list below drop zone
    const [uploadedDocs, setUploadedDocs] = useState([]) //for the file list above the drop zone
    const [documentExpanded, setDocumentExpanded] = useState(false)
    const [fieldConfig, setFieldConfig] = useState([])
    const [fieldDict] = useState([]) //for display that fits the wireframe more in uploadedDocs section
    const { errors, touched, setFieldValue } = useFormikContext()
    const docTypeKeyRef = useRef(uuid())
    const [shouldRender, setShouldRender] = useState(false)
  
    const { error: showErrorNotification } =
      useDefaultNotification(props.showNotification)
  
    const handleShipmentExpand = () => {
      setDocumentExpanded(!documentExpanded)
    }
  
    const handleUploadedInputChange = (e, index) => {
      const { name, value } = e.target
      const list = [...uploadedDocs]
  
      list[index][name.split('.')[1]] = value
  
      console.log(list)
      setUploadedDocs(list)
  
      setFieldValue('shippingInfoComplianceSupportDoc', list)
    }
  
    const handleUploadedDropdownChange = (val, evt, index, name) => {
      if (evt) {
        const { value } = evt
        const list = [...uploadedDocs]
        list[index][name] = value
  
        setUploadedDocs(list)
  
        setFieldValue('shippingInfoComplianceSupportDoc', list)
      }
    }
  
    const removeUploadedRowClick = (id) => {
      console.log('remove uploaded: ' + id)
      uploadedDocs.splice(id, 1)
      const newFiles = [].concat([], uploadedDocs)
      setUploadedDocs(newFiles)
      setFieldValue('shippingInfoComplianceSupportDoc', newFiles)
  
      docTypeKeyRef.current = uuid()
    }
  
    const downloadUploadedRowClick = (id) => {
      console.log('download uploaded: ' + id)
      console.log(uploadedDocs[id])
      DocumentApi.downloadDocument(
        securedSendRequest,
        uploadedDocs[id],
        CalistaConstant.MODULE_SI
      )
    }
  
    const editRowClick = (id) => {
      console.log('edit row: ')
      console.log(id)
  
      const newFiles = [].concat([], uploadedDocs)
      newFiles[id].editable = true
      setUploadedDocs(newFiles)
      setFieldValue('shippingInfoComplianceSupportDoc', newFiles)
    }
  
    const getFieldError = (itemIndex, fieldPath) => {
      if (errors) {
        let pathArr = fieldPath.split('.')
  
        if (
          errors[pathArr[0]] &&
          errors[pathArr[0]][itemIndex] &&
          errors[pathArr[0]][itemIndex][pathArr[1]]
        ) {
          if (touched) {
            if (
              touched[pathArr[0]] &&
              touched[pathArr[0]][itemIndex] &&
              touched[pathArr[0]][itemIndex][pathArr[1]]
            ) {
              // return true
              return errors[pathArr[0]][itemIndex][pathArr[1]]
            } else {
              return null
            }
          }
        }
      }
      return null
    }
  
    const showErrorMessages = (errorMessages) => {
      showErrorNotification(errorMessages[0])
    }
  
    const moreActions = [
      {
        action: 'remove',
        name: 'Delete',
        icon: ['fal', 'trash']
      }
    ]
  
    useEffect(() => {
      console.log('doc!')
      CodeMaintainanceApi.fetchCodeMaintainance(
        fetchRecords,
        CodeMaintenanceType.DOCUMENT,
        [
          {
            field: 'docType',
            operator: 'EQUAL',
            value: 'CLOGS_SI'
          }
        ],
        false,
        onLoadDocTypeSuccess
      )
    }, [props.isEdit])
  
    function onLoadDocTypeSuccess(data) {
      console.log('onLoadDocTypeSuccess', props.isEdit)
      let docTypeOptionList = []
      if (data.length == 0) {
        console.log('null')
      } else {
        data.map((singleDocTypeOption) => {
          let detailedOption = {
            value: singleDocTypeOption.code,
            label: singleDocTypeOption.descriptionEn
          }
          docTypeOptionList.push(detailedOption)
        })
      }
  
      let currentFieldConfig = [
        {
          fieldType: 'dropDown',
          fieldName: 'docType',
          label: 'File type',
          mandatory: true,
          error: false,
          errorMessage: 'Required',
          validationRule: '',
          dropDownLov: docTypeOptionList
        },
        {
          fieldType: 'textInput',
          fieldName: 'description',
          label: 'Description',
          mandatory: true,
          error: false,
          errorMessage: 'Max 1024 characters',
          validationRule: /^.{0,1024}$/
        }
      ]
  
      setFieldConfig(currentFieldConfig)
  
      if (props.isEdit && props.isFromViewPermit ) {
        console.log('From review and to update permit', props.documentData.shippingInfoComplianceSupportDocs)
        setFieldValue('shippingInfoComplianceSupportDocs', props.documentData.shippingInfoComplianceSupportDocs)

        let uploadedDocListWithEdiable = props.documentData.shippingInfoComplianceSupportDocs
        setUploadedDocs(uploadedDocListWithEdiable)
        setShouldRender(true)

      }else if (props.isEdit) {
        console.log('shippingInfoComplianceSupportDoc', props.documentData.pendingShippingInfoComplianceSupportDoc)
		    setPendingDocs(props.documentData.pendingShippingInfoComplianceSupportDoc)
		    setFieldValue('pendingShippingInfoComplianceSupportDoc', props.documentData.pendingShippingInfoComplianceSupportDoc)
  
        console.log('uploadedDocsField', uploadedDocsField)
  
        let uploadedDocList = uploadedDocsField
        if (uploadedDocList != null) {
          let uploadedDocListWithEdiable = uploadedDocList.map((v) => ({
            ...v,
            editable: false
          }))
  
          setUploadedDocs(uploadedDocListWithEdiable)
        }
        setShouldRender(true)
      } else {
        console.log('doc should render')
        setShouldRender(true)
      }
    }
  
    const setSelectedFiles = (file) => {
  
      //long file name
      let processedFileObjs = []
      file.forEach((item) => {
  
        if (item.file.name.length > 26) {
          let newFile = new File(
            [item.file],
            Utils.truncLongFileName(item.file.name)
          )
  
          let processedFileObj = {
            file: newFile,
            fields: item.fields
          }
          processedFileObjs.push(processedFileObj)
        } else {
          processedFileObjs.push(item)
        }
      })
      setFieldValue('pendingShippingInfoComplianceSupportDoc', processedFileObjs)
  
    }
  
    if (!shouldRender) {
      return null
    }
  
    return (
      <Card>
        <Accordion expanded={documentExpanded}>
          <AccordionSummary
            aria-controls='shipmentComponentContent'
            id='shipmentComponentHeader'
          >
            <Grid container>
              <Grid container xs={12} sm={12} spacing={2}>
                <CngGridItem xs={12} sm={12}>
                  <AccordionHeaderComponent
                    title={siTranslatedTextsObject.document}
                    hideMandatory
                  />
                </CngGridItem>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container xs={12} sm={12} spacing={2}>
              <CngGridItem xs={12} sm={12}>
                <DocumentDetailsComponents
                  tile={uploadedDocs}
                  removeRowClick={removeUploadedRowClick}
                  handleInputChange={handleUploadedInputChange}
                  handleDropdownChange={handleUploadedDropdownChange}
                  editRowClick={editRowClick}
                  downloadRowClick={downloadUploadedRowClick}
                  getFieldError={getFieldError}
                  docTypeKeyRef={docTypeKeyRef.current}
                  fieldDict={fieldDict}
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={12}>
                <CngFileUpload
                  accept={[
                    '.pdf',
                    '.xls',
                    '.xlsx',
                    '.doc',
                    '.docx',
                    '.png',
                    '.jpg',
                    '.jpeg',
                    '.ppt',
                    '.pptx'
                  ]}
                  maxSize={10485760}
                  onFileSelect={setSelectedFiles}
                  showFormFields={true}
                  files={pendingDocs}
                  fieldConfig={fieldConfig}
                  moreActions={moreActions}
                  onDropRejected={(error) => showErrorMessages(error)}
                  renderHelperText={() => {
                    return (
                      <HelperTextArea
                        accept={['.pdf',
                          '.xls',
                          '.xlsx',
                          '.doc',
                          '.docx',
                          '.png',
                          '.jpg',
                          '.jpeg',
                          '.ppt',
                          '.pptx']}
                        maxSize={10485760}
                      />
                    )
                  }}
                />
              </CngGridItem>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <AccordionFooterControlComponent
          handleAccordionExpand={handleShipmentExpand}
          expanded={documentExpanded}
          footerText={siTranslatedTextsObject.attachDocument}
        />
      </Card>
    )
  }
  
  const initialValues = {
    bookingDocs: {
      description: '',
      docType: '',
      ownerType: 'FRB'
    }
  }
  
  const DocumentComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
  })
  
  export default DocumentComponent
  