import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import ComplianceTranslationText from 'src/views/compliance/ComplianceTranslationText'
import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
import Utils from 'src/views/common/utils/Utils'

const SIDetailBottom = (props) => {
  const { dockey, updatedDate } = props
  const translatedTextsObjects = ComplianceTranslationText()
  const shippingInstructionTranslationText = ShippingInstructionTranslationText()

  return (
    <footer className={`cng-summary-card--footer-box`}>
      <Grid container xs={12} style={{ height: '100%' }}>
        <Grid item xs={12} sm={7}>
          <div className={`cng-summary-card--footer-box--left`}>
            <span>
              <img
                src={`${process.env.PUBLIC_URL}/static/images/Logo_CALISTA.svg`}
              ></img>
            </span>

            <span>
              <label data-label-suffix='' className={`form-label-colon`}>
                {shippingInstructionTranslationText.bookingId}
              </label>
              <label>{dockey}</label>
            </span>
          </div>
        </Grid>

        <Grid item xs={12} sm={5}>
          <div className={`cng-summary-card--footer-box--right`}>
            <div className={`footer-box--right-col-1 text-right-align`}>
              <Typography className={'frb-card-label-large-grey'}>
                {translatedTextsObjects.lastModifiedOn + '  '}
                <b>
                  {Utils.formatDate(
                    updatedDate,
                    Utils.FORMAT_DATE_TIME,
                    Utils.DASH
                  )}
                </b>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </footer>
  )
}

export default SIDetailBottom
