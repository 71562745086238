import {
  FormControlLabel,
  FormGroup
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components } from 'cng-web-lib'
import { v4 as uuid } from 'uuid'

const {
  form: {
    field: { CngTextField, CngSelectField, CngDateField, CngCheckboxField }
  },
  CngGridItem
} = components

const CheckboxGroup = (props) => {

  const [options, setOptions] = useState([])

  useEffect(() => {
    setOptions(props.checkBoxList)
  })

  return (
    <>
      {options != null && (
        <FormGroup
          row={true}
          className={`cng-filter-criteria--chkbox-container`}
        >
          <ul className={`filter-criteria--all`}>
            {options
              .filter((option) => option.statusDesc === 'ALL')
              .map((option) => {
                const key = uuid()
  
                return (
                  <li key={key}>
                    <FormControlLabel
                      key={options.length - 1}
                      control={
                        <CngCheckboxField
                          disabled={!option.eligible}
                          checked={
                            options.filter((option) => option.checked === true)
                              .length === options.length
                          }
                          indeterminate={
                            options.filter((option) => option.checked === true)
                              .length > 0 &&
                            options.filter((option) => option.checked === true)
                              .length < options.length
                          }
                          onChange={props.onChange(options.length - 1)}
                          inputProps={{ 'aria-label': 'indeterminate checkbox' }}
                        />
                      }
                      label={`All ${props.filterName} (${option.totalCount})`}
                    />
                  </li>
                )
              })}
          </ul>
          <ul className={`filter-criteria--others occupy-full-width`}>
            {options
              .filter((option) => option.statusDesc !== 'ALL')
              .map((option, index)=> {
                const key = uuid()
  
                return (
                  <li key={key}>
                    <FormControlLabel
                      key={option.statusCode}
                      control={
                        <CngCheckboxField
                          value={option.statusCode}
                          disabled={!option.eligible}
                          checked={option.checked}
                          onChange={props.onChange(index)}
                        />
                      }
                      label={`${option.statusDesc}`}
                    />
                  </li>
                )
              })}
          </ul>
        </FormGroup>
      )}
    </>
  )
}

export default CheckboxGroup
