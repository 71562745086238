import {
    Box,
    Card,
    Grid
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText'
import PartyDetailsDialog from './PartyDetailsDialog'
import ViewPartyDetailsDialog from './ViewPartyDetailsDialog'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import PartiesTable from './PartiesTable'
import Utils from '../shared/Utils'

const {
    form: {
      adapter: {
        useFormAdapter: { useField, useFormikContext }
      }
    },
    table: { useDefaultNotification }
} = components


const FormBody = (props) => {
    const { setFieldValue } = useFormikContext()
    const [isPartyDetailsDialogOpen, setPartyDetailsDialogOpen] = 
    useState(false)
    const [isViewPartyDetailsDialogOpen, setViewPartyDetailsDialogOpen] =
      useState(false)
    const translatedTextsObject = ComplianceTranslationText()
    const currentRowIdx = useRef(0)
    const [rerender, ] = useState(false)
    const compliancePartyList = useRef([])

    function setPartyListField(list) {
        compliancePartyList.current = list
        setFieldValue('shippingInfoCompliancePartyList', list )
    }

    const handleRowClick = (idx) => () => {
        currentRowIdx.current = idx
        setViewPartyDetailsDialogOpen(true)
    }

    function closeViewDialog() {
        setViewPartyDetailsDialogOpen(false)
    }

    function closeDialog() {
      setPartyDetailsDialogOpen(false)
    }

    useEffect(() => {

        if (props.isReview){
          if(props.partiesData?.shippingInfoComplianceParty?.declarantPersonName != null){
            let party = {
              partyType : Utils.partyType.declarant,
              name : props.partiesData?.shippingInfoComplianceParty?.declarantPersonName,
              crno: props.partiesData?.shippingInfoComplianceParty?.declarantPersonCode,
              phone: props.partiesData?.shippingInfoComplianceParty?.declarantPersonPhone
            }
  
            compliancePartyList.current.push(party)
          }
          if(props.partiesData?.shippingInfoComplianceParty?.declaringAgentName1 != null){
            let party10 = {
              partyType : Utils.partyType.declaringAgent,
              name : props.partiesData?.shippingInfoComplianceParty?.declaringAgentName1,
              name2: props.partiesData?.shippingInfoComplianceParty?.declaringAgentName2, 
              crno: props.partiesData?.shippingInfoComplianceParty?.declaringAgentCrNo,
            }
  
            compliancePartyList.current.push(party10)
          }
          if(props.partiesData?.shippingInfoComplianceParty?.freightForwarderName1 != null){
            let party1 = {
              partyType : Utils.partyType.freightForwarder,
              name : props.partiesData?.shippingInfoComplianceParty?.freightForwarderName1,
              name2: props.partiesData?.shippingInfoComplianceParty?.freightForwarderName2, 
              crno: props.partiesData?.shippingInfoComplianceParty?.freightForwarderCrNo,
            }
  
            compliancePartyList.current.push(party1)
          }
          if(props.partiesData?.shippingInfoComplianceParty?.inCarrierAgentName1 != null){
            let party2 = {
              partyType : Utils.partyType.inCarrierAgent,
              name : props.partiesData?.shippingInfoComplianceParty?.inCarrierAgentName1,
              name2: props.partiesData?.shippingInfoComplianceParty?.inCarrierAgentName2, 
              crno: props.partiesData?.shippingInfoComplianceParty?.inCarrierAgentCrNo,
            }
  
            compliancePartyList.current.push(party2)
          }
          if(props.partiesData?.shippingInfoComplianceParty?.importerName1 != null){
            let party3 = {
              partyType : Utils.partyType.importer,
              name : props.partiesData?.shippingInfoComplianceParty?.importerName1,
              name2: props.partiesData?.shippingInfoComplianceParty?.importerName2, 
              crno: props.partiesData?.shippingInfoComplianceParty?.importerCrNo,
            }
  
            compliancePartyList.current.push(party3)
          }
          if(props.partiesData?.shippingInfoComplianceParty?.outCarrierAgentName1 != null){
            let party4 = {
              partyType : Utils.partyType.outCarrierAgent,
              name : props.partiesData?.shippingInfoComplianceParty?.outCarrierAgentName1,
              name2: props.partiesData?.shippingInfoComplianceParty?.outCarrierAgentName2, 
              crno: props.partiesData?.shippingInfoComplianceParty?.outCarrierAgentCrNo,
            }
  
            compliancePartyList.current.push(party4)
          }
          if(props.partiesData?.shippingInfoComplianceParty?.exporterName1 != null){
            let party5 = {
              partyType: Utils.partyType.exporter,
              crno: props.partiesData?.shippingInfoComplianceParty?.exporterCrNo,
              name: props.partiesData?.shippingInfoComplianceParty?.exporterName1,
              address: props.partiesData?.shippingInfoComplianceParty?.exporterAddress1,
              name2: props.partiesData?.shippingInfoComplianceParty?.exporterName2,
              address2: props.partiesData?.shippingInfoComplianceParty?.exporterAddress2,
  
              city: props.partiesData?.shippingInfoComplianceParty?.exporterCity,
              postalCode: props.partiesData?.shippingInfoComplianceParty?.exporterPstCode,
              country: props.partiesData?.shippingInfoComplianceParty?.exporterCtyCode
            }
  
            compliancePartyList.current.push(party5)
          }
          if(props.partiesData?.shippingInfoComplianceParty?.consigneeName1 != null){
            let party6 = {
              partyType: Utils.partyType.consignee,
              name: props.partiesData?.shippingInfoComplianceParty?.consigneeName1,
              address: props.partiesData?.shippingInfoComplianceParty?.consigneeAddress1,
              name2: props.partiesData?.shippingInfoComplianceParty?.consigneeName2,
              address2: props.partiesData?.shippingInfoComplianceParty?.consigneeAddress2,
  
              city: props.partiesData?.shippingInfoComplianceParty?.consigneeCity,
              postalCode: props.partiesData?.shippingInfoComplianceParty?.consigneePstCode,
              country: props.partiesData?.shippingInfoComplianceParty?.consigneeCtyCode
            }
  
            compliancePartyList.current.push(party6)
          }
          if(props.partiesData?.shippingInfoComplianceParty?.endUserName1 != null){
            let party7 = {
              partyType: Utils.partyType.endUser,
              name: props.partiesData?.shippingInfoComplianceParty?.endUserName1,
              address: props.partiesData?.shippingInfoComplianceParty?.endUserAddress1,
              name2: props.partiesData?.shippingInfoComplianceParty?.endUserName2,
              address2: props.partiesData?.shippingInfoComplianceParty?.endUserAddress2,
  
              city: props.partiesData?.shippingInfoComplianceParty?.endUserCity,
              postalCode: props.partiesData?.shippingInfoComplianceParty?.endUserPstCode,
              country: props.partiesData?.shippingInfoComplianceParty?.endUserCtyCode
            }
  
            compliancePartyList.current.push(party7)
          }
          if(props.partiesData?.shippingInfoComplianceParty?.manufacturerName1 != null){
            let party8 = {
              partyType: Utils.partyType.manufacturer,
              crno: props.partiesData?.shippingInfoComplianceParty?.manufacturerCrNo,
              name: props.partiesData?.shippingInfoComplianceParty?.manufacturerName1,
              address: props.partiesData?.shippingInfoComplianceParty?.manufacturerAddress1,
              name2: props.partiesData?.shippingInfoComplianceParty?.manufacturerName2,
              address2: props.partiesData?.shippingInfoComplianceParty?.manufacturerAddress2,
  
              city: props.partiesData?.shippingInfoComplianceParty?.manufacturerCity,
              postalCode: props.partiesData?.shippingInfoComplianceParty?.manufacturerPstCode,
              country: props.partiesData?.shippingInfoComplianceParty?.manufacturerCtyCode
            }
  
            compliancePartyList.current.push(party8)
          }
          if(props.partiesData?.shippingInfoComplianceParty?.claimantName1 != null){
            let party9 = {
              partyType: Utils.partyType.claimant,
              crno: props.partiesData?.shippingInfoComplianceParty?.claimantCRNumber,
              name: props.partiesData?.shippingInfoComplianceParty?.claimantName1,
              name2: props.partiesData?.shippingInfoComplianceParty?.claimantName2,
  
              personCode: props.partiesData?.shippingInfoComplianceParty?.claimantPersonCode,
              personName: props.partiesData?.shippingInfoComplianceParty?.claimantPersonName
            }
  
            compliancePartyList.current.push(party9)
          }
          setFieldValue('shippingInfoCompliancePartyList', compliancePartyList.current)
        }
    }, [props.isReview])

    return(
      <Box>
        <Card>
          <Box px={2} my={1.5}>
            <Grid container justify='flex-end'>
              <AccordionHeaderComponent
                title={translatedTextsObject.partiesTitle}
                hideMandatory
              />
            </Grid>
          </Box>

          <PartiesTable
            list={compliancePartyList.current}
            handleRowClick={handleRowClick}
            editMode={false}
            rerender={rerender}
          />

          <PartyDetailsDialog
            isDialogOpen={isPartyDetailsDialogOpen}
            closeDialog={closeDialog}
            showNotification={props.showNotification}
            currentRowIdx={currentRowIdx.current}
            partyListField={compliancePartyList.current}
            setPartyListField={setPartyListField}
          />

          <ViewPartyDetailsDialog
            isDialogOpen={isViewPartyDetailsDialogOpen}
            closeDialog={closeViewDialog}
            showNotification={props.showNotification}
            currentPartyInfo={compliancePartyList.current[currentRowIdx.current]}
          />
        </Card>
      </Box>
    )
}   

const initialValues = Object.freeze({})

const ViewPartyComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})


export default ViewPartyComponent