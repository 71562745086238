import useComplianceCargoValidationSchema from "../shared/validation/useComplianceCargoValidationSchema"
import useCompliancePermitTypeValidationSchema from "../shared/validation/useCompliancePermitTypeValidationSchema"
import useComplianceLicenceValidationSchema from "../shared/validation/useComplianceLicenceValidationSchema.js"
import useComplianceTransportValidationSchema from "../shared/validation/useComplianceTransportValidationSchema"
import useComplianceContainerValidationSchema from "../shared/validation/usComplianceContainerValidationSchema"

function useCreatePermitMakeValidationSchema() {
    return useCompliancePermitTypeValidationSchema()
        .concat(useComplianceCargoValidationSchema())
        .concat(useComplianceContainerValidationSchema())
        .concat(useComplianceTransportValidationSchema())
        .concat(useComplianceLicenceValidationSchema())
}
export default useCreatePermitMakeValidationSchema
  