import { Card, CardContent, Grid } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'
import ComplianceTranslationText from '../ComplianceTranslationText'

const {
    form: {
        field: { CngTextField, CngSelectField },
    },
    card: {
        CngSimpleCardHeader
    },
    CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = {
    declarantId: "",
    declarantCrNo: ""
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
}

//* Fields function ------------------------------------------------------------
function Fields({ disabled, showNotification, customFieldsProps, partyId, ...props }) {
    console.log('this is party id in formproperties ' + partyId)
    const fieldsTranslatedTextObject = ComplianceTranslationText();

    return (
        <Card>
            <CngSimpleCardHeader title={fieldsTranslatedTextObject.declarantMappingForm} />
            <CardContent>
                <Grid container spacing={2}>
                    <CngGridItem xs={4} sm={4} >
                        <CngTextField
                            name="declarantId"
                            label={fieldsTranslatedTextObject.declarantMaintenanceDeclarantId}
                            disabled={false}
                        />
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4} >
                        <CngTextField
                            name="declarantCrNo"
                            label={fieldsTranslatedTextObject.declarantMaintenanceDeclarantCrNo}
                            disabled={false}
                        />
                    </CngGridItem>
                    <CngGridItem xs={4} sm={4} shouldHide={true}>
                        <CngTextField
                            name="partyId"
                            type="number"
                            label={fieldsTranslatedTextObject.declarantMaintenancePartyId}
                            disabled={true}
                        />
                    </CngGridItem>
                </Grid>
            </CardContent>
        </Card>
    )
}


const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields
})

export default FormProperties


