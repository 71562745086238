import React, { useState, useMemo } from 'react'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import ItemDetailsContext from './ItemDetailsContext.js'
import ItemDetailsForm from './ItemDetailsForm.js'
import { components } from 'cng-web-lib'

const { CngDialog, CngDialogTitle } = components

function ItemDetailsDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  currentRowIdx,
  itemListField,
  setItemListField,
  inPermit
}) {
  const [isSubmitting, setSubmitting] = useState(false)

  const contextValue = useMemo(
    () => ({
      closeDialog,
      showNotification,
      currentRowIdx,
      itemListField,
      setItemListField,
      form: {
        isSubmitting,
        setSubmitting,
      },
    }),
    [closeDialog, showNotification, currentRowIdx, itemListField, setItemListField, isSubmitting]
  )

  return (
    <ItemDetailsContext.Provider value={contextValue}>
      <CngDialog
        customDialogContent={
          <Paper>
            <ItemDetailsForm
            inPermit={inPermit}
             />
          </Paper>
        }
        dialogTitle={
          <>
            <b>{'Update item'}</b>
            <CloseDialogIconButton onClick={closeDialog} disabled={isSubmitting} />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='md'
      />
    </ItemDetailsContext.Provider>
  )
}

export default ItemDetailsDialog
