import {
  Card,
  Grid,
  Box,
} from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { components, useServices, constants } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText'
import CommonUtils from 'src/views/common/utils/Utils'
import ItemsTable from './ItemsTable'
import ItemDetailsDialog from './ItemDetailsDialog'
import ItemSummaryComponent from './ItemSummaryComponent'
import Utils from 'src/views/compliance/shared/Utils'
import ViewItemDetailsDialog from './ViewItemDetailsDialog'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: {
      CngSelectField,
      CngRadioGroupField,
      CngSwitchField,
      CngCodeMasterAutocompleteField,
      CngTextField,
      CngDateTimeField
    }
  },
  CngGridItem,
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup,
    useFetchCodeMaintenanceLookup
  }
} = components
const { CodeMaintenanceType } = constants

const FormBody = (props) => {
  const { setFieldValue } = useFormikContext()
  const { fetchRecords } = useServices()

  const translatedTextsObject = ComplianceTranslationText()
  const [isItemDetailsDialogOpen, setItemDetailsDialogOpen] = useState(false)
  const [isViewItemDetailsDialogOpen, setViewItemDetailsDialogOpen] =
    useState(false)
  const [rerender, setRerender] = useState(false)
  const complianceItemsList = useRef([])
  const currentRowIdx = useRef(0)
  const [siComplianceItemField, ,] = useField('shippingInfoComplianceItems')
  const [siCargoesField, ,] = useField('shippingInfoCargoes')
  const [totalOutPackQuantity, setTotalOutPackQuantity] = useState(0)
  const [totalCifFobAmount, setTotalCifFobAmount] = useState(0)
  const [totalGrossWeight, setTotalGrossWeight] = useState(0)
  const [outPackUom, setOutPackUom] = useState()
  const outPackUomCode = useRef()
  const uomList = useRef([])

  function closeDialog() {
    setItemDetailsDialogOpen(false)
    console.log('item lisit', complianceItemsList.current)
    recalculateItemSummary()
  }

  function closeViewDialog() {
    setViewItemDetailsDialogOpen(false)
  }

  function recalculateItemSummary() {
    let itemSummary = Utils.recalculateItemSummary(complianceItemsList.current)
    console.log('itemSummary', itemSummary)

    outPackUomCode.current = itemSummary.totalOutPackQuantityUom
    setOutPackUom(getPackageDescFromCode(itemSummary.totalOutPackQuantityUom))
    setTotalOutPackQuantity(itemSummary.totalOutPackQuantityValue)
    setTotalGrossWeight(itemSummary.totalGrossWeightQuantityValue)
    setTotalCifFobAmount(itemSummary.totalCifFobAmount)
  }

  function setItemListField(list) {
    console.log('setItemListField', complianceItemsList.current)
    setFieldValue('shippingInfoComplianceItems', list)
  }

  const removeItemRow = (idx) => (e) => {
    console.log('removeItemRow', idx)
    let newDGCargoList = complianceItemsList.current
    newDGCargoList.splice(idx, 1)
    console.log('newDGCargoList', newDGCargoList)
    complianceItemsList.current = newDGCargoList
    //set field value
    setRerender(!rerender)
    recalculateItemSummary()
    e.stopPropagation()
  }

  const editItemRow = (idx) => (e) => {
    console.log('removeItemRow', idx)
    currentRowIdx.current = idx
    setItemDetailsDialogOpen(true)
    e.stopPropagation()
  }

  const handleRowClick = (idx) => () => {
    currentRowIdx.current = idx
    setViewItemDetailsDialogOpen(true)
  }

  function getPackageDescFromCode(code) {
    let packageDesc = ''
    if (uomList.current.length > 0 && CommonUtils.isNotEmpty(code)) {
      uomList.current
        .filter((uomItem) => uomItem.code === code)
        .map((selected) => {
          packageDesc = selected.descriptionEn
          return null
        })
    }
    return packageDesc
  }

  function onLoadPackageUOMSuccess(data) {
    console.log('onLoadPackageUOMSuccess', data)
    uomList.current = data
    setOutPackUom(getPackageDescFromCode(outPackUomCode.current))
  }

  useEffect(() => {
    //load package list for table display
    if (uomList.current.length == 0) {
      CodeMaintainanceApi.fetchCodeMaintainance(
        fetchRecords,
        CodeMaintenanceType.PACKAGE_UOM,
        [{}],
        false,
        onLoadPackageUOMSuccess
      )
    }

    /**
     * there are 2 scenarios
     * 1. This is a new permit created from si, item list should be cargos from si
     *
     * 2. This is a created permit, item list from the field itself
     */

    console.log('container component props.isEdit > ' + props.isEdit)

    if (props.isEdit || props.isView) {
      console.log('shippingInfoComplianceCargo', siComplianceItemField)
      console.log('shippingInfoCargoes', siCargoesField)

      if (props.isView) {
        //setFieldValue('shippingInfoComplianceItems', props.itemData.shippingInfoComplianceItems)
        siComplianceItemField.value = props.itemData.shippingInfoComplianceItems
      }
      if (siComplianceItemField.value == undefined) {
        if (siCargoesField.value !== undefined) {
          let itemList = []
          let totalQty = 0

          siCargoesField.value.forEach((elem) => {
            let item = {}
            item.itemDesc = elem.description
            item.hsCode = elem.hsCode
            item.hsQtyValue = elem.quantity
            item.hsQtyUom = elem.packageType
            item.cifFobAmt = ''
            item.grossWeight = Math.round(elem.grossWeight * 0.001)
            item.shippingMark = elem.marksAndNumbers
            itemList.push(item)
          })

          setFieldValue('shippingInfoComplianceItems', itemList)
          console.log('cargoes itemList', itemList)
          console.log('totalQty', totalQty)

          complianceItemsList.current = itemList

          let itemSummary = Utils.recalculateItemSummary(itemList)
          console.log('itemSummary', itemSummary)
          outPackUomCode.current = itemSummary.totalOutPackQuantityUom
          setOutPackUom(
            getPackageDescFromCode(itemSummary.totalOutPackQuantityUom)
          )
          setTotalOutPackQuantity(itemSummary.totalOutPackQuantityValue)
          setTotalGrossWeight(itemSummary.totalGrossWeightQuantityValue)
          setTotalCifFobAmount(0) //there is no cif fob amount for new permit
        }
      } else {

        let itemList = []
        let totalQty = 0

        siComplianceItemField.value.forEach((elem) => {
          let item = {}
          item.itemDesc = elem.itemDesc
          item.hsCode = elem.hsCode
          item.hsQtyValue = elem.hsQtyValue
          item.hsQtyUom = elem.hsQtyUom
          item.cifFobAmt = elem.cifFobAmt
          item.grossWeight = elem.grossWeight
          item.shippingMark = elem.shippingMark
          item.ctyOrigin = elem.ctyOrigin
          item.brand = elem.brand
          item.dutQtyUom = elem.dutQtyUom
          item.dutQtyValue = elem.dutQtyValue
          item.model = elem.model
          itemList.push(item)
        })

        setFieldValue('shippingInfoComplianceItems', itemList)
        complianceItemsList.current = itemList

        let itemSummary = Utils.recalculateItemSummary(itemList)

        outPackUomCode.current = itemSummary.totalOutPackQuantityUom
        setOutPackUom(
          getPackageDescFromCode(itemSummary.totalOutPackQuantityUom)
        )
        setTotalOutPackQuantity(itemSummary.totalOutPackQuantityValue)
        setTotalGrossWeight(itemSummary.totalGrossWeightQuantityValue)
        setTotalCifFobAmount(itemSummary.totalCifFobAmount) //there is no cif fob amount for new permit

      }
    }

    setRerender(!rerender)
  }, [props.isEdit, props.isView])

  return (
    <>
      <Box>
        <Card>
          <Box px={2} my={1.5}>
            <Grid container justify='flex-end'>
              <AccordionHeaderComponent
                title={translatedTextsObject.itemsTitle}
                hideMandatory={props.isView}
              />
            </Grid>
          </Box>

          <ItemsTable
            list={complianceItemsList.current}
            removeRowClick={removeItemRow}
            editRowClick={editItemRow}
            handleRowClick={handleRowClick}
            rerender={rerender}
            editMode={props.isEdit}
            getPackageDescFromCode={getPackageDescFromCode}
            itemData={props.itemData}
          />

          <ItemDetailsDialog
            isDialogOpen={isItemDetailsDialogOpen}
            closeDialog={closeDialog}
            showNotification={props.showNotification}
            currentRowIdx={currentRowIdx.current}
            itemListField={complianceItemsList.current}
            setItemListField={setItemListField}
            inPermit={props.inPermit}
          />

          <ViewItemDetailsDialog
            isDialogOpen={isViewItemDetailsDialogOpen}
            closeDialog={closeViewDialog}
            showNotification={props.showNotification}
            currentItemInfo={complianceItemsList.current[currentRowIdx.current]}
          />
        </Card>
      </Box>
      {!props.isView && (

        <Box pt={5}>
          <ItemSummaryComponent.FormBody
            showNotification={props.showNotification}
            totalOutPackQuantity={totalOutPackQuantity}
            totalCifFobAmount={totalCifFobAmount}
            outPackUom={outPackUom}
            totalGrossWeight={totalGrossWeight}
            rerender={rerender}
          />
        </Box>
      )}
    </>
  )
}

const ItemsComponent = Object.freeze({
  FormBody: FormBody
})

export default ItemsComponent
