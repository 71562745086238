import React, { useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import { useHistory, useLocation } from 'react-router-dom'
import CreatePermitProperties from './CreatePermitProperties'
import CommonUtils from 'src/views/common/utils/Utils'
import Utils from 'src/views/compliance/shared/Utils'
import SIUtils from 'src/views/shippinginstruction/shared/Utils'
import ComplianceApiUrls from 'src/apiUrls/ComplianceApiUrls'
import pathMap from 'src/paths/PathMap_Compliance'
import ComplianceTranslationText from '../ComplianceTranslationText'
import useCreatePermitMakeValidationSchema from './useCreatePermitMakeValidationSchema'
import useCreatePermitMakeValidationInSchema from './useCreatePermitMakeValidationInSchema'
import useCreatePermitMakeValidationInPaySchema from './useCreatePermitMakeValidationInPaySchema'

const {
    form: { CngViewForm },
    table: { useDefaultNotification }
  } = components

function CreatePermitPage({ showNotification}){

    const history = useHistory()
    const location = useLocation()
    const stateData = location.state
    const { createRecord } = useServices()
    const [loading, setLoading] = useState(false)
    const [recalculate, setRecalculate] = useState(0)
    const translatedTextsObject = ComplianceTranslationText()
    const [withPay, setWithPay] = useState(false)

    const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)
    const validationSchema = useCreatePermitMakeValidationSchema()
    const validationSchemaIn = useCreatePermitMakeValidationInSchema()

    function onError(error) {
      console.log('connection-request error', error.message)
      setLoading(false)
      setRecalculate(recalculate + 1)
      showErrorNotification(CommonUtils.getErrorMessage(error))
    }

    function saveDraft(data) {
      setLoading(true)
      console.log('saveDraft')
      function onSuccess(response) {
        console.log('connection-request onSuccess', response)
        setLoading(false)
        if (response.errorMessages != null) {
          setRecalculate(recalculate + 1)
          showErrorNotification(response.errorMessages)
        } else {
          showSuccessNotification(
            `${translatedTextsObject.draftSuccessMsg} ${response.complianceId} `
          )
        }
      }
  
      createRecord.execute(
        ComplianceApiUrls.SAVE_DRAFT,
        data,
        onSuccess,
        onError
      )
    }

    //do a checking for decType blanket here if true set the transport field to null
    function onSubmit(data) {
      console.log('Submit Draft', data)
      SIUtils.convertDate(data)
      SIUtils.convertDate(data.shippingInfoComplianceTransport)
      //construct compliance party object
      data = Utils.constructPartyObject(data)
      let summary = Utils.recalculateItemSummary(data.shippingInfoComplianceItems)
      data.shippingInfoComplianceSummary = summary

      console.log('submit processed', data)
      if (data['saveDraft']) {
        //if there is document, save document first then get the doc id and save draft
        console.log(data.pendingShippingInfoComplianceSupportDoc)
        if (
          data.pendingShippingInfoComplianceSupportDoc &&
          !Utils.isEmpty(data.pendingShippingInfoComplianceSupportDoc)
        ) {
          uploadDocumentAndSaveDraft(data)
        } else {
          saveDraft(data)
        }
      } else {
        //go to step 2 page
        //pre save check
        CreateReviewPermit(data)
      }
    }

    //Currenty no api call for upload support docs
    function uploadDocumentAndSaveDraft(data) {
      setLoading(true)
      console.log('uploadDocumentAndSaveDraft')
  
      let formData = new FormData()
      console.log(data.pendingShippingInfoComplianceSupportDoc)
  
      data.pendingShippingInfoComplianceSupportDoc.map((file, i) => {
        let fileBlob = file.file
        console.log(fileBlob)
        formData.append('fileContents', fileBlob) // name of the file upload box
        return null
      })
  
      function onSuccess(response) {
        console.log('connection-request onSuccess', response)
        if (response.errorMessages != null) {
          setRecalculate(recalculate + 1)
          showErrorNotification(response.errorMessages)
        } else {
          let complianceDocs = data.shippingInfoComplianceSupportDocs || [] //to store the final  documents
          if (response && response.length > 0) {
            response.forEach((file, i) => {
              let siDoc = {
                complianceDocId: data.pendingShippingInfoComplianceSupportDoc[i].fields[0].value,
                fileName: file.fileName,
                description: data.pendingShippingInfoComplianceSupportDoc[i].fields[1].value,
                docId: file.id,
                fileSize: file.fileSize
              }
  
              complianceDocs.push(siDoc)
            })
          }
  
          data.shippingInfoComplianceSupportDocs = complianceDocs
  
          console.log(data)
          saveDraft(data)
        }
      }
  
      createRecord.execute(
        ComplianceApiUrls.DOCUMENT_UPLOAD,
        formData,
        onSuccess,
        onError
      )
    }
    

    function CreateReviewPermit(data){

      setLoading(true)
      console.log('reviewPermit')
      function onSuccess(response) {
        console.log('connection-request onSuccess', response)
        setLoading(false)
        if (response.errorMessages != null) {
          setRecalculate(recalculate + 1)
          showErrorNotification(response.errorMessages)
        } else {
          redirectToReviewPage(data)
        }
      }

      createRecord.execute(
        ComplianceApiUrls.CREATE_PERMIT,
        data,
        onSuccess,
        onError
      )

    }

    function redirectToReviewPage(data){
      console.log('redirectToReviewPage', data)
      
      if(data.carType.startsWith('IPT')){
        setWithPay(true)
      }

      history.push({
        pathname: pathMap.CREATE_NEW_PERMIT_REVIEW,
        state:
        {
          data,
          inPermit: stateData.inPermit,
          withPay: withPay
        }
      })
    }

    return (
      // use CngViewForm here for customizable button set
      <CngViewForm
        fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
        history={history}
        showNotification={showNotification}
        bodySection={
          <CreatePermitProperties.FormFields
            disabled={false}
            showNotification={showNotification}
            loading={loading}
            setLoading={setLoading}
            recalculate={recalculate}
            onSubmitForm={onSubmit}
          />
        }
        formikProps={{
          ...CreatePermitProperties.formikProps,
          validationSchema: stateData.inPermit ? validationSchemaIn : validationSchema,
        }}
      />
    )
}

export default CreatePermitPage