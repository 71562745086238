import {
    Box,
    Card,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Checkbox
  } from '@material-ui/core'
  import React, { useEffect, useState } from 'react'
  import moment from 'moment'
  import CodeMasterMiscViewField from 'src/components/field/CodeMasterMiscViewField'
  import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
  import { components, constants, useServices } from 'cng-web-lib'
  import ShippingInstructionTranslationText from 'src/views/shippinginstruction/ShippingInstructionTranslationText'
  import FileCopyIcon from '@material-ui/icons/FileCopy'
  import DocumentInfoDecoratorComponent from '../../common/ui/DocumentInfoDecoratorComponent'
  import Paper from '@material-ui/core/Paper'
  import DocumentDetailsActionMenu from 'src/views/freightbooking/components/DocumentDetailsActionMenu'
  import DocumentApi from 'src/views/common/api/DocumentApi'

  const { 
        CngGridItem
    } = components

  const { CodeMaintenanceType } = constants

  const FormBody = (props) => {
    const translatedTextsObject = FreightBookingTranslationText()
    const [shouldRender, setShouldRender] = useState(false)
    const [documentList, setDocumentList] = useState([])
    const { securedSendRequest } = useServices()
    const siTranslatedTextsObject = ShippingInstructionTranslationText()
  
    useEffect(() => {
      setShouldRender(true)
      setDocumentList(props.document)
    }, [])
  
    if (!shouldRender) {
      return null
    }
  
    const downloadRowClick = (id) => () => {
      console.log('download uploaded: ' + id)
      console.log('module: ', props.module)
  
      DocumentApi.downloadComplianceDocument(
        securedSendRequest,
        documentList[id],
        props.module
      )
    }
  
    return (
      <Grid container xs={12} sm={12}>
        <CngGridItem xs={12} sm={12}>
          <Card>
            <Box className='view-card-header'>
              {translatedTextsObject.document}
            </Box>
            <Box>
              <TableContainer component={Paper}>
                <Table id='table'>
                  <TableBody>
                    {documentList.map((elem, i) => {
                      let highlight = {}
                      return (
                        <TableRow key={elem.docId} style={highlight.document}>                      
                          <TableCell
                            className={`file-format`}
                            style={{ verticalAlign: 'top' }}
                          >
                            {' '}
                            <Box className='view-card-content'>
                              <Box className='view-card-content-value'>
                                <FileCopyIcon />
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell style={{ verticalAlign: 'top' }}>
                            <DocumentInfoDecoratorComponent
                              name={
                                elem.fileName == null
                                  ? elem.file.name
                                  : elem.fileName
                              }
                              size={ elem.fileSize }
                            />{' '}
                          </TableCell>
                          <TableCell style={{ verticalAlign: 'top' }}>
                            <Box
                              className='view-card-content'
                              style={highlight.docType}
                            >
                              <Box className='view-card-content-label'>
                                {translatedTextsObject.fileType}
                              </Box>
                              <Box className='view-card-content-value'>
                                <CodeMasterMiscViewField
                                  codeType={CodeMaintenanceType.DOCUMENT}
                                  code={elem.complianceDocId}
                                />
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box
                              className='view-card-content'
                              style={highlight.description}
                            >
                              <Box className='view-card-content-label'>
                                {translatedTextsObject.fileDesc}
                              </Box>
  
                              <Box
                                className='view-card-content-value'
                                style={{ wordBreak: 'break-word' }}
                              >
                                {elem.description}
                                {/* <LabelValueVertical label={translatedTextsObject.fileDesc}
                                value={elem.description} /> */}
                              </Box>
                            </Box>
                          </TableCell>                 
                            <TableCell>
                              <Box
                                className='view-card-content'
                                style={highlight.description}
                              >
                                <Box className='view-card-content-label'>
                                  {siTranslatedTextsObject.docPushDate}
                                </Box>
  
                                <Box
                                  className='view-card-content-value'
                                  style={{ wordBreak: 'break-word' }}
                                >
                                  {moment(elem.docPushDate).isValid() ? moment(elem.docPushDate).format('DD-MMM-YYYY HH:mm:ss') : "-"}
                                </Box>
                              </Box>
                            </TableCell>
                            <TableCell>
                              {/* download action */}
                              <DocumentDetailsActionMenu
                                id={elem.docId}
                                downloadRowClick={downloadRowClick(i)}
                                hideDownloadLog
                                hideEdit
                                hideDelete
                              />
                            </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
  
              <Grid container xs={12} sm={12}>
                <CngGridItem xs={6} sm={6}></CngGridItem>
              </Grid>
            </Box>
          </Card>
        </CngGridItem>
      </Grid>
    )
  }
  
  const initialValues = Object.freeze({})
  
  const ViewComplianceDocumentComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
  })
  
  export default ViewComplianceDocumentComponent
  