import {
    Accordion,
    AccordionSummary,
    Card,
    Grid,
    IconButton
} from '@material-ui/core'
import React from 'react'
import { components, constants } from 'cng-web-lib'
import ShippingInstructionTranslationText from '../../shippinginstruction/ShippingInstructionTranslationText'
import DeleteIcon from '@material-ui/icons/Delete'
const {
    form: {
        adapter: {
            useFormAdapter: { useField, useFormikContext }
        },
        field: {
            CngSelectField,
            CngSwitchField,
            CngCodeMasterAutocompleteField,
            CngTextField,
            CngPackageUomAutocompleteField,
        }
    },
    CngGridItem,
    table: { useDefaultNotification }
} = components

const {
    component: {
        textField: {
            customType: { PLAIN_NUMBER }
        }
    }
} = constants

const ContainerDetailsComponent = (props) => {
    const siTranslatedTextsObject = ShippingInstructionTranslationText()

    return props.list?.map((x, i) => (
        <Grid container xs={12} sm={12} key={x.id} spacing={2}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Accordion expanded={true}>
                        <AccordionSummary
                            aria-controls='contactDetailsComponentContent'
                            id='contactDetailsComponentHeader'
                        >
                            <Grid container spacing={2} justify='flex-start'>
                                <CngGridItem xs={4} sm={4}>
                                    <CngTextField
                                        type='text'
                                        name={`shippingInfoComplianceContainers[${i}].ctnNo`}
                                        label={siTranslatedTextsObject.containerNo}
                                        value={x.containerNo ? x.containerNo : x.ctnNo}
                                        inputProps={{ maxLength: 15 }}
                                        onChange={(e) => props.handleInputChange(e, i)}
                                        isRequired
                                        disabled
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngCodeMasterAutocompleteField
                                        name={`shippingInfoComplianceContainers[${i}].ctnTypeSize`}
                                        label={siTranslatedTextsObject.containerSizeTypeHeight}
                                        codeType='CAL_COMP_CTN_TYPE_SIZE'
                                        onChange={val => props.handleDropdownChange(val, i, 'containerStatus')}
                                        isRequired
                                        //defaultValue={x.ctnTypeSize || ""}
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngTextField
                                        type='text'
                                        name={`shippingInfoComplianceContainers[${i}].ctnSealNo`}
                                        label={siTranslatedTextsObject.sealNo}
                                        inputProps={{ maxLength: 15 }}
                                        onChange={(e) => props.handleInputChange(e, i)}
                                        isRequired
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngTextField
                                        type='number'
                                        name={`shippingInfoComplianceContainers[${i}].ctnWtValue`}
                                        label={siTranslatedTextsObject.containerGrossWeightTonne}
                                        isRequired
                                        onChange={(e) => props.handleInputChange(e, i)}
                                        customType={PLAIN_NUMBER}
                                        inputProps={{ maxLength: 11 }}
                                    />
                                </CngGridItem>

                                <CngGridItem xs={12} sm={12}>
                                    {props.list.length > 1 && (
                                        <IconButton aria-label='remove'>
                                            <DeleteIcon onClick={props.removeRowClick(i)} />
                                        </IconButton>
                                    )}
                                </CngGridItem>
                            </Grid>
                        </AccordionSummary>
                    </Accordion>
                </Card>
            </CngGridItem>
        </Grid>
    ))
}
export default ContainerDetailsComponent;