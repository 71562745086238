import {
  Card,
  Grid,
  CardContent
} from '@material-ui/core'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { components } from 'cng-web-lib'

const {
  form: {
    field: { CngTextField }
  },
  CngGridItem,
  button: { CngPrimaryButton }
} = components

const FormBody = (props) => {

  const SIPlaceHolder = 'Search for reference #, vessel name, etc    '

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <CngGridItem item xs={9} sm={9}>
            <CngTextField
              type='text'
              name='filterText'
              value={props.filterText}
              placeholder={SIPlaceHolder}
              onChange={(e) => props.handleInputChange(e)}
            />
          </CngGridItem>
          <CngGridItem item xs={2} sm={2}>
            <CngPrimaryButton
              name='search'
              startIcon={<SearchIcon />}
              className={'search-button'}
              onClick={props.handelFreeTextSearch}
            ></CngPrimaryButton>
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

const FreeTextSearchComponent = Object.freeze({
  FormBody: FormBody
})

export default FreeTextSearchComponent
