import {
    AccordionSummary,
    Card,
    Grid
} from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { components } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText'
import ShippingInstructionTranslationText from '../../shippinginstruction/ShippingInstructionTranslationText'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'
import CommonUtils from 'src/views/common/utils/Utils'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import moment from 'moment'
const {
    form: {
        adapter: {
        useFormAdapter: { useField, useFormikContext }
        },
        field: {
        CngSelectField,
        CngRadioGroupField,
        CngSwitchField,
        CngCodeMasterAutocompleteField,
        CngTextField,
        CngDateTimeField
        }
    },
    CngGridItem,
    table: { useDefaultNotification }
} = components

const initialValues = Object.freeze({
  shippingInfoComplianceTransport: {
    inTptMode: '1', //in
    inTptId: '', //in
    inMasterShipDoc: '', //inblNo
    arrivalDate: '', //in
    loadingPort: '', //in
    outTptMode: '1', //out
    outTptId: '', //out
    outMasterShipDoc: '', //outblNo
    vessName: '',
    voyNo: '',
    ctyDestn: '', //in
    nextPort:'',
    dischargePort:'',
    deptDate: ''
},
  transportMode: '',
  tptType: ''
})

const FormBody = (props) => {
    const { watch } = useFormContext()
    const {setFieldValue} = useFormikContext()
    const siTranslatedTextsObject = ShippingInstructionTranslationText()
    const ComplianceTranslatedObject = ComplianceTranslationText()

    const [, ,] = useField('placeOfDelivery')
    const [, ,] = useField('podCode')
    const [, ,] = useField('shippingInfoComplianceTransport.deptDate')

    const [, ,] = useField('polCode')
    const [, ,] = useField('shippingInfoComplianceTransport.arrivalDate')

    const [decTypeField] = useField('decType')
    const isBlanketWatch = watch('decType')
    const [isBlanket, setIsBlanket] = useState(false)
    const [tempInTransportFields, setTempInTransportFields] = useState({     
        inTptMode: '1', //in
        inTptId: '', //in
        inMasterShipDoc: '', //inblNo
        inCvyRefNo: '',
        arrivalDate: '', //in
        loadingPort: '', //in
        vessName: '',
        voyNo: '',
        ctyDestn: ''
    });
    const [tempOutTransportFields, setTempOutTransportFields] = useState({     
        outTptMode: '1', //out
        outTptId: '', //out
        outMasterShipDoc: '', //outblNo
        vessName: '',
        voyNo: '',
        ctyDestn: '', //in
        dischargePort:'',
        deptDate: '',
        outCvyRefNo: ''
    });

    const [vesselNameField, ,] = useField('vesselName')
    const methods = useForm({
        defaultValues: {
          tptType: props.inPermit ? 'IN' : 'OUT' // Set the value based on props.inPermit
        }
      });

    const SG_DATE_FORMAT = CommonUtils.FORMAT_DATE_SERVER_DATE

    useEffect(() => {
        setFieldValue('vesselName', vesselNameField.value)
      }, [props.isEdit])

    useEffect(() => {
        console.log('Declaration type field value watch: ',decTypeField.value)

        if(decTypeField.value == "BKT" && props.inPermit == true){
            setIsBlanket(true)
            setTempInTransportFields({
                inTptMode: props.transportData?.shippingInfoComplianceTransport?.inTptMode, //in
                inTptId: props.transportData?.shippingInfoComplianceTransport?.inTptId, //in
                inMasterShipDoc: props.transportData?.shippingInfoComplianceTransport?.inMasterShipDoc, //inblNo
                inCvyRefNo: props.transportData?.shippingInfoComplianceTransport?.inCvyRefNo,
                arrivalDate: props.transportData?.shippingInfoComplianceTransport?.arrivalDate, //in
                loadingPort: props.transportData?.shippingInfoComplianceTransport?.loadingPort, //in
                vessName: props.transportData?.shippingInfoComplianceTransport?.vessName,
                voyNo: props.transportData?.shippingInfoComplianceTransport?.voyNo,
                ctyDestn: props.transportData?.shippingInfoComplianceTransport?.ctyDestn
            }) // use this to set the existing transport fields to a temporary object, then make it empty 

            props.transportData.shippingInfoComplianceTransport = null
        }else if (decTypeField.value == "BKT" && props.inPermit == false){
            setIsBlanket(true)
            setTempOutTransportFields({
                outTptMode: props.transportData?.shippingInfoComplianceTransport?.outTptMode, //out
                outTptId: props.transportData?.shippingInfoComplianceTransport?.outTptId, //out
                outMasterShipDoc: props.transportData?.shippingInfoComplianceTransport?.outMasterShipDoc, //outblNo
                vessName: props.transportData?.shippingInfoComplianceTransport?.vessName,
                voyNo: props.transportData?.shippingInfoComplianceTransport?.voyNo,
                ctyDestn: props.transportData?.shippingInfoComplianceTransport?.ctyDestn, //in
                dischargePort:props.transportData?.shippingInfoComplianceTransport?.dischargePort,
                deptDate: props.transportData?.shippingInfoComplianceTransport?.deptDate,
                outCvyRefNo: props.transportData?.shippingInfoComplianceTransport?.outCvyRefNo
            })
            props.transportData.shippingInfoComplianceTransport = null
        }
        else{
            setIsBlanket(false)
            if (props.inPermit){
                props.transportData.shippingInfoComplianceTransport = tempInTransportFields
            } else {
                props.transportData.shippingInfoComplianceTransport = tempOutTransportFields
            }
            //set back the existing fields in to the input fields 
        }

    }, [isBlanketWatch])

    const onChangePolEtd = (date) => {
        props.transportData.shippingInfoComplianceTransport.deptDate = date
        setFieldValue('shippingInfoComplianceTransport.deptDate', date, true)
    }

    const onChangePodEta = (date) => {
        props.transportData.shippingInfoComplianceTransport.arrivalDate = date
        setFieldValue('shippingInfoComplianceTransport.arrivalDate', date, true)
    }
    return (
        <Card>
            {isBlanket &&(
                <></>
            )}
            {!isBlanket &&(
                <AccordionSummary>
                    <Grid container xs={12} sm={12}>
                        <CngGridItem xs={6} sm={6}>
                            <AccordionHeaderComponent 
                                title = {ComplianceTranslatedObject.tranportTitle}
                            />
                        </CngGridItem>
                        {!props.inPermit &&(
                            <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
                                <CngGridItem xs={3} sm={3} />

                                <CngGridItem xs={8} sm={8}>
                                    <FormProvider {...methods}>
                                        <CngRadioGroupField
                                            labelSpacing={10}
                                            disabled
                                            name='tptType'
                                            options={
                                                [
                                                    {label: ComplianceTranslatedObject.inwardTransport, value: 'IN'},
                                                    {label: ComplianceTranslatedObject.outwardTransport, value: 'OUT'}
                                                ]
                                            }
                                            direction='row'
                                        >
                                        </CngRadioGroupField>
                                    </FormProvider>
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngCodeMasterAutocompleteField
                                        name = 'shippingInfoComplianceTransport.outTptMode'
                                        label = {ComplianceTranslatedObject.transportMode}
                                        codeType='CAL_COMP_TPT_MODE'
                                        disabled
                                        isRequired
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngTextField
                                        type='text'
                                        name='shippingInfoComplianceTransport.voyNo'
                                        //name='voyageNo'  
                                        label={siTranslatedTextsObject.voyageNumber}
                                        inputProps={{ maxLength: 17 }}
                                    // value = {props.transportData.voyageNo}
                                        required
                                        disabled
                                    >
                                    </CngTextField>
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngTextField
                                        type='text'
                                        name='shippingInfoComplianceTransport.vessName'
                                        label={siTranslatedTextsObject.vesselName}
                                        inputProps={{ maxLength: 35 }}
                                    // value = {props.transportData.vesselName}
                                        required
                                        disabled
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngTextField
                                        type='text'
                                        name='shippingInfoComplianceTransport.outMasterShipDoc'
                                        label={siTranslatedTextsObject.blNo}
                                        inputProps={{ maxLength: 20 }}
                                        required
                                        disabled
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngDateTimeField
                                        label={siTranslatedTextsObject.polPlannedEtd}
                                        name='shippingInfoComplianceTransport.deptDate'
                                        format={SG_DATE_FORMAT}
                                        required
                                        onChange={(event, date) =>
                                            onChangePolEtd(date)
                                        }
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <PortSelectAutoComplete
                                        name='shippingInfoComplianceTransport.dischargePort'
                                        label={siTranslatedTextsObject.portOfDischarge}
                                        keyRef={props.podKeyRef}
                                        required
                                        disabled
                                        value={props.transportData?.booking?.podCode ? 
                                            props.transportData.booking.podCode : props.transportData?.shippingInfoComplianceTransport?.dischargePort}
                                    />
                                </CngGridItem>
                            </Grid>
                        )}
                        {props.inPermit &&(
                            <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
                                <CngGridItem xs={3} sm={3} />

                                <CngGridItem xs={8} sm={8}>
                                    <FormProvider {...methods}>
                                        <CngRadioGroupField
                                            labelSpacing={10}
                                            disabled
                                            name='tptType'
                                            options={
                                                [
                                                    {label: ComplianceTranslatedObject.inwardTransport, value: 'IN'},
                                                    {label: ComplianceTranslatedObject.outwardTransport, value: 'OUT'}
                                                ]
                                            }
                                            direction='row'
                                        >
                                        </CngRadioGroupField>
                                    </FormProvider>
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngCodeMasterAutocompleteField
                                        name = 'shippingInfoComplianceTransport.inTptMode'
                                        label = {ComplianceTranslatedObject.transportMode}
                                        codeType='CAL_COMP_TPT_MODE'
                                        disabled
                                        isRequired
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngTextField
                                        type='text'
                                        name='shippingInfoComplianceTransport.voyNo'
                                        //name='voyageNo'  
                                        label={siTranslatedTextsObject.voyageNumber}
                                        inputProps={{ maxLength: 17 }}
                                    // value = {props.transportData.voyageNo}
                                        required
                                        disabled
                                    >
                                    </CngTextField>
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngTextField
                                        type='text'
                                        name='shippingInfoComplianceTransport.vessName'
                                        label={siTranslatedTextsObject.vesselName}
                                        inputProps={{ maxLength: 35 }}
                                    // value = {props.transportData.vesselName}
                                        required
                                        disabled
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngTextField
                                        type='text'
                                        name='shippingInfoComplianceTransport.inMasterShipDoc'
                                        label={siTranslatedTextsObject.blNo}
                                        inputProps={{ maxLength: 20 }}
                                        required
                                        disabled
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <CngDateTimeField
                                        label={siTranslatedTextsObject.podPlannedEta}
                                        name='shippingInfoComplianceTransport.arrivalDate'
                                        format={SG_DATE_FORMAT}
                                        required
                                        onChange={(event, date) =>
                                            onChangePodEta(date)
                                        }
                                    />
                                </CngGridItem>
                                <CngGridItem xs={4} sm={4}>
                                    <PortSelectAutoComplete
                                        name='shippingInfoComplianceTransport.loadingPort' //IN is loadingPort
                                        label={siTranslatedTextsObject.portOfLoading}
                                        keyRef={props.podKeyRef}
                                        required
                                        disabled
                                        value={props.transportData?.booking?.polCode ? 
                                            props.transportData.booking.polCode : props.transportData?.shippingInfoComplianceTransport?.loadingPort}
                                    />
                                </CngGridItem>
                            </Grid>
                        )}
                    </Grid>
                </AccordionSummary>
            )}
            
        </Card>
    )
}

const TransportComponent = Object.freeze({
    FormBody: FormBody,
    initialValues: initialValues
})

export default TransportComponent;