import Namespace from 'src/constants/locale/Namespace'
import CalistaUiComponentKeys from 'src/constants/locale/key/CalistaUiComponent'
import ComplianceKeys from 'src/constants/locale/key/Compliance'
import { useTranslation } from 'cng-web-lib'

const ComplianceTranslationText = () => {


    const { translate } = useTranslation([
        Namespace.UI_COMPONENT,
        Namespace.COMPLIANCE,
        Namespace.NEW_PERMIT,
        Namespace.MANAGE_PERMIT,
        Namespace.CALISTA_UI_COMPONENT
    ])

    let title = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.TITLE
    )

    let createNewPermitTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CREATE_NEW_PERMIT_TITLE
    )

    let stepLabelFillupDetails = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.FILLUP_DETAILS
    )

    let stepLabelReviewDetails = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.REVIEW_DETAILS
    )

    let stepLabelSubmit = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.SUBMIT
    )

    let stepLabelFinish = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.FINISH
    )

    const back = translate(
        Namespace.CALISTA_UI_COMPONENT,
        CalistaUiComponentKeys.BACK
    )

    let permitTypeTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PERMIT_TYPE_TITLE
    )

    let declarantCrNo = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DECLARANT_CR_NO
    )

    let messageType = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.MESSAGE_TYPE
    )

    let declarationType = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DECLARATION_TYPE
    )

    let declaration = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DECLARATION
    )

    let previousPermitNo = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PREVIOUS_PERMIT_NO
    )
    let permitNo = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PERMIT_NO
    )
    let remarks = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.REMARKS
    )

    let additionalPermitTypeDetails = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ADDITIONAL_PERMIT_TYPE_DETAILS
    )

    let cargoTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CARGO_TITLE
    )

    let cargoPackingType = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CARGO_PACKING_TYPE
    )

    let releaseLocation = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.RELEASE_LOCATION
    )

    let receiptLocation = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.RECEIPT_LOCATION
    )

    let tranportTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.TRANSPORT_TITLE
    )

    let inwardTransport = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INWARD_TRANSPORT
    )

    let outwardTransport = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.OUTWARD_TRANSPORT
    )

    let transportMode = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.TRANSPORT_MODE
    )
    let transportModeVoyage = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.TRANSPORT_MODE_VOYAGE
    )

    let partiesTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PARTY_TITLE
    )

    let addParty = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ADD_PARTY
    )

    let partyType = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PARTY_TYPE
    )

    let partyName = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PARTY_NAME
    )

    let partyAddress = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PARTY_ADDRESS
    )

    let partyCrNo = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PARTY_CR_NO
    )

    let action = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ACTION
    )

    let licenseTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.LICENSE_TITLE
    )

    let licenseNo1 = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.LICENSE_NO_1
    )

    let licenseNo2 = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.LICENSE_NO_2
    )

    let licenseNo3 = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.LICENSE_NO_3
    )

    let licenseNo4 = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.LICENSE_NO_4
    )

    let licenseNo5 = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.LICENSE_NO_5
    )

    let additionalLicenseNo = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ADDITIONAL_LICENSE_NO
    )

    let itemsTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ITEMS_TITLE
    )

    let addItems = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ADD_ITEMS
    )

    let itemsHSCode = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ITEMS_HS_CODE
    )

    let itemsQuantity = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ITEMS_QUANTITY
    )

    let itemsUnit = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ITEMS_UNIT
    )

    let itemsAmount = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ITEMS_AMOUNT
    )

    let shippingMarks = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.SHIPPING_MARKS
    )

    let itemsSummaryTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ITEMS_SUMMARY_TITLE
    )

    let totalItemQuantity = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.TOTAL_ITEM_QUANTITY
    )

    let totalAmount = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.TOTAL_AMOUNT
    )

    let totalOuterPackQuantity = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.TOTAL_OUTER_PACK_QUANTITY
    )

    let totalOuterPackUnit = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.TOTAL_OUTER_PACK_UNIT
    )

    let grossWeight = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.GROSS_WEIGHT
    )

    let totalGrossWeight = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.TOTAL_GROSS_WEIGHT
    )

    let totalGrossWeightUnit = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.TOTAL_GROSS_WEIGHT_UNIT
    )

    let document = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DOCUMENT
    )
    let documents = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DOCUMENTS
    )
    let attachDocument = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ATTACH_DOCUMENT
    )

    let documentInstruction = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DOCUMENT_INSTRUCTION
    )

    let fileType = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.FILE_TYPE
    )

    let fileDesc = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.FILE_DESC
    )

    let draftSuccessMsg = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DRAFT_SUCCESS_MSG
    )

    let enquirySuccessMsg = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DRAFT_SUCCESS_MSG
    )

    let costType = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.COST_TYPE
    )
    let polPlannedETD = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.POL_PLANNED_ETD
    )
    let podPlannedETA = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.POD_PLANNED_ETA
    )
    let bolNo = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.BOL_NO
    )
    let lastModifiedOn = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.LAST_MODIFIED_ON
    )
    let pod = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.POD
    )
    let pol = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.POL
    )
    let enquireStatus = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ENQUIRE_STATUS
    )
    let backToManagePermitPage = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.BACK_TO_MANAGE_PERMIT_PAGE
    )
    let status = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.STATUS
    )


    //Declarant Maintenance
    let declarantMaintenanceTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DECLARANT_MAINTENANCE_TITLE
    )
    let declarantMaintenancePartyId = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DECLARANT_MAINTENANCE_PARTY_ID
    )
    let mappedDeclarants = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.MAPPED_DECLARANTS
    )
    let declarantMaintenancePartyName = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DECLARANT_MAINTENANCE_PARTY_NAME
    )
    let declarantMaintenancePartyDetails = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DECLARANT_MAINTENANCE_PARTY_DETAILS
    )
    let declarantMaintenanceDeclarantId = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DECLARANT_MAINTENANCE_DECLARANT_ID
    )
    let declarantMaintenanceDeclarantCrNo = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DECLARANT_MAINTENANCE_DECLARANT_CR_NO
    )
    let declarantMappingForm = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DECLARANT_MAPPING_FORM
    )

    let updatePermit = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.UPDATE_PERMIT
    )

    let draftedOn = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DRAFTED_ON
    )

    let submittedOn = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.SUBMITTED_ON
    )

    let approvedOn = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.APPROVED_ON
    )

    let rejectedOn = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.REJECTED_ON
    )
    let amendCreatedOn = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.AMEND_CREATED_ON
    )
    let amendSubmittedOn = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.AMEND_SUBMITTED_ON
    )
    let downloadPermit = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DOWNLOAD_PERMIT
    )

    let bookingId = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.BOOKING_ID
    )

    let permitDraftId = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PERMIT_DRAFT_ID
    )

    let permitId = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PERMIT_ID
    )

    let confirmDeletePermitTitle= translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CONFIRM_DELETE_PERMIT_TITLE
    )

    let confirmCancelPermitTitle= translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CONFIRM_CANCEL_PERMIT_TITLE
    )

    let confirmArchivePermitTitle= translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CONFIRM_ARCHIVE_PERMIT_TITLE
    )

    let confirmDeletePermitContent= translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CONFIRM_DELETE_PERMIT_CONTENT
    )

    let confirmCancelPermitContent= translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CONFIRM_CANCEL_PERMIT_CONTENT
    )

    let confirmArchivePermitContent= translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CONFIRM_ARCHIVE_PERMIT_CONTENT
    )

    let confirmDeletePermitOption= translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CONFIRM_DELETE_PERMIT_OPTION
    )

    let confirmCancelPermitOption= translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CONFIRM_CANCEL_PERMIT_OPTION
    )

    let confirmArchivePermitOption= translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CONFIRM_ARCHIVE_PERMIT_OPTION
    )
    let hsUnit = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.HS_UNIT
    )

    let hsQuantity = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.HS_QUANTITY
    )

    let brandName = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.BRAND_NAME
    )
    let model = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.MODEL
    )
    let phoneNo = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PHONE_NO
    )
    let partyTypeDetails = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PARTY_TYPE_DETAILS
    )
    let crNoDetails = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CR_NO_DETAILS
    )
    let nameDetails1 = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.NAME_DETAILS_1
    )
    let nameDetails2 = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.NAME_DETAILS_1
    )
    let phoneNoDetails = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PHONE_NO_DETAILS
    )
    let addressDetails1 = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ADDRESS_DETAILS_1
    )
    let addressDetails2 = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ADDRESS_DETAILS_1
    )
    let cityDetails = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.CITY_DETAILS
    )
    let dialogDiscardPermitTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DIALOG_DISCARD_PERMIT_TITLE
    )
    let dialogDiscardPermitContent = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.DIALOG_DISCARD_PERMIT_CONTENT
    )
    let personCode = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PERSON_CODE
    )
    let personName = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.PERSON_NAME
    )
    let resetFilterDesc = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.RESET_FILTER_DESC
    )
    let invoiceTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INVOICE_TITLE
    )
    let invoiceNo = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INVOICE_NO
    )
    let invoiceDate = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INVOICE_DATE
    )
    let invoicePersonCode = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INVOICE_PERSON_CODE
    )
    let invoicePersonName = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INVOICE_PERSON_NAME
    )
    let totalInvoiceAmount = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INVOICE_AMOUNT
    )
    let totalInvoiceCurrency = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INVOICE_CURRENCY
    )
    let additionalInvoiceDetails = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.ADDITIONAL_INVOICE_DETAILS
    )
    let totalInvoiceTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.TOTAL_INVOICE_TITLE
    )
    let invoiceTermType = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INVOICE_TERM_TYPE
    )
    let freightChargesTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.FREIGHT_CHARGES_TITLE
    )
    let freightChargesAmount = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.FREIGHT_CHARGES_AMOUNT
    )
    let freightChargesPercent = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.FREIGHT_CHARGES_PERCENT
    )
    let freightChargesCurrency = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.FREIGHT_CHARGES_CURRENCY
    )
    let insuranceTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INSURANCE_TITLE
    )
    let insuranceAmount = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INSURANCE_AMOUNT
    )
    let insurancePercent = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INSURANCE_PERCENT
    )
    let insuranceCurrency = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.INSURANCE_CURRENCY
    )
    let otherChargesTitle = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.OTHER_CHARGES_TITLE
    )
    let otherChargesAmount = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.OTHER_CHARGES_AMOUNT
    )
    let otherChargesPercent = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.OTHER_CHARGES_PERCENT
    )
    let otherChargesCurrency = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.OTHER_CHARGES_CURRENCY
    )
    let shown = translate(
        Namespace.COMPLIANCE,
        ComplianceKeys.SHOWN
    )
    return {
        model,
        hsUnit,
        hsQuantity,
        brandName,
        enquireStatus,
        title,
        createNewPermitTitle,
        stepLabelFillupDetails,
        stepLabelFinish,
        stepLabelReviewDetails,
        stepLabelSubmit,
        permitTypeTitle,
        declarantCrNo,
        messageType,
        declarationType,
        declaration,
        previousPermitNo,
        permitNo,
        remarks,
        additionalPermitTypeDetails,
        cargoTitle,
        cargoPackingType,
        releaseLocation,
        receiptLocation,
        tranportTitle,
        inwardTransport,
        outwardTransport,
        transportMode,
        transportModeVoyage,
        partiesTitle,
        addParty,
        partyType,
        partyName,
        partyAddress,
        partyCrNo,
        action,
        licenseTitle,
        licenseNo1,
        licenseNo2,
        licenseNo3,
        licenseNo4,
        licenseNo5,
        additionalLicenseNo,
        itemsTitle,
        addItems,
        itemsHSCode,
        itemsQuantity,
        itemsUnit,
        itemsAmount,
        shippingMarks,
        itemsSummaryTitle,
        totalItemQuantity,
        totalAmount,
        totalOuterPackQuantity,
        totalOuterPackUnit,
        totalGrossWeight,
        totalGrossWeightUnit,
        document,
        documents,
        attachDocument,
        documentInstruction,
        fileType,
        fileDesc,
        draftSuccessMsg,
        costType,
        polPlannedETD,
        podPlannedETA,
        bolNo,
        lastModifiedOn,
        pod,
        pol,
        mappedDeclarants,
        declarantMappingForm,
        back,
        backToManagePermitPage,
        updatePermit,
        draftedOn,
        submittedOn,
        approvedOn,
        rejectedOn,
        amendCreatedOn,
        amendSubmittedOn,
        downloadPermit,
        bookingId,
        permitDraftId,
        permitId,
        declarantMaintenancePartyId,
        declarantMaintenancePartyName,
        declarantMaintenancePartyDetails,
        declarantMaintenanceDeclarantId,
        declarantMaintenanceDeclarantCrNo,
        declarantMaintenanceTitle,
        status,
        confirmDeletePermitTitle,
        confirmCancelPermitTitle,
        confirmArchivePermitTitle,
        confirmDeletePermitContent,
        confirmCancelPermitContent,
        confirmArchivePermitContent,
        confirmDeletePermitOption,
        confirmCancelPermitOption,
        confirmArchivePermitOption,
        grossWeight,
        phoneNo,
        partyTypeDetails,
        crNoDetails,
        nameDetails1,
        nameDetails2,
        phoneNoDetails,
        addressDetails1,
        addressDetails2,
        cityDetails,
        dialogDiscardPermitTitle,
        dialogDiscardPermitContent,
        resetFilterDesc,
        invoiceTitle,
        invoiceNo,
        invoiceDate,
        personCode,
        personName,
        totalInvoiceTitle,
        totalInvoiceAmount,
        totalInvoiceCurrency,
        additionalInvoiceDetails,
        invoiceTermType,
        freightChargesTitle,
        freightChargesAmount,
        freightChargesCurrency,
        insuranceTitle,
        insuranceAmount,
        insuranceCurrency,
        otherChargesTitle,
        otherChargesAmount,
        otherChargesCurrency,
        invoicePersonCode,
        invoicePersonName,
        enquirySuccessMsg,
        freightChargesPercent,
        insurancePercent,
        otherChargesPercent,
        shown
    }

}

export default ComplianceTranslationText
