import { Box, Card, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { components } from 'cng-web-lib'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import Utils from 'src/views/common/utils/Utils'
import { v4 as uuid } from 'uuid'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'
import ComplianceTranslationText from '../ComplianceTranslationText'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'

const {
    form: {
      field: { CngSwitchField, CngTextField, CngCodeMasterAutocompleteField }
    },
    CngGridItem
  } = components
  

const FormBody = (props) => {

    const ComplianceTranslatedObject = ComplianceTranslationText()
    let ComplianceInvoiceList = props.invoices

    console.log('ViewContainerDetailComponent containerList:', ComplianceInvoiceList)

    const getInvoicesTotalCount = () => {
        let totalCount = 0
        if (ComplianceInvoiceList != null && ComplianceInvoiceList.length > 0) {
          totalCount = ComplianceInvoiceList.length
        }
    
        return totalCount
    }

    const initialHighLight = [
        {
            id: 0, //as the key of each component
            invQty: 1,
            invNo: {},
            invDate: {},
            supMfrPersonCode: {},
            supMfrPersonName1: {},
            invTermType: {},
            totInvAmt: {},
            totInvAmtCurrCode:{},
            frcChargeAmt:{},
            frcChargeCurrCode:{},
            frcChargePercent:{},
            insChargeAmt:{},
            insChargeCurrCode:{},
            insChargePercent:{},
            othChargeAmt:{},
            othChargeCurrCode:{},
            othChargePercent:{},
        }
    ]

    const [highlight] = useState(initialHighLight)

    function getHighlight(id, field) {
        let matched = highlight.find((hl) => hl?.id == id)
    
        return Utils.isNotEmpty(matched) ? matched[field] : {}
    }

    return(

        <Grid container xs={12} sm={12}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        {ComplianceTranslatedObject.invoiceTitle}
                        <span className='view-card-header-info'>
                        {' '}
                        / {getInvoicesTotalCount()} {ComplianceTranslatedObject.invoiceTitle}
                        </span>
                    </Box>

                    <Box>
                        {ComplianceInvoiceList.map((row) =>{
                            const rowKey = uuid() 

                            return(
                                <ComponentWrapper
                                    key={rowKey} // Add key prop with a unique identifier
                                    headerStyle='view-card-header-no-bg'
                                    cardStyle={{ margin: 20 }}
                                    backgroundStyle='inner-card-background'
                                >

                                    <Grid
                                        container
                                        xs={12}
                                        sm={12}
                                        spacing={0}
                                        alignItems='top'
                                        style={{
                                        height: '100%',
                                        }}
                                    >
                                        <Grid item xs={12} sm={12}>
                                            <Grid container xs={12} sm={12}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'invNo')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.invoiceNo}
                                                        value={row.invNo}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'invDate')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.invoiceDate}
                                                        value={row.invDate.toString()}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'supMfrPersonCode')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.invoicePersonCode}
                                                        value={row.supMfrPersonCode}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'supMfrPersonName1')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.invoicePersonName}
                                                        value={row.supMfrPersonName1}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'invTermType')}
                                                >
                                                    <Box className="view-card-content">
                                                        <Box className="view-card-content-label">
                                                            <Grid container justify="space-between">
                                                                <Grid item>
                                                                    {ComplianceTranslatedObject.invoiceTermType}
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                        <Box className="view-card-content-value" style={{ wordBreak: 'break-word', whiteSpace: "pre-line" }}>
                                                            <CodeMasterViewField
                                                                codeType='CAL_COMP_INV_TERM_TYPE'
                                                                code={row.invTermType}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={2}/>

                                                {/* Total Invoice */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'totInvAmt')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.totalInvoiceAmount}
                                                        value={row.totInvAmt}
                                                    />
                                                </Grid>
                                               <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'totInvAmtCurrCode')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.totalInvoiceCurrency}
                                                        value={row.totInvAmtCurrCode}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={2}/>

                                                {/* Freight Charges */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'frcChargeAmt')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.freightChargesAmount}
                                                        value={row.frcChargeAmt}
                                                    />
                                                </Grid>
                                               <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'frcChargeCurrCode')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.freightChargesCurrency}
                                                        value={row.frcChargeCurrCode}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'frcChargePercent')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.freightChargesPercent}
                                                        value={row.frcChargePercent}
                                                    />
                                                </Grid>

                                                {/* Other */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'othChargeAmt')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.otherChargesAmount}
                                                        value={row.othChargeAmt}
                                                    />
                                                </Grid>
                                               <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'othChargeCurrCode')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.otherChargesCurrency}
                                                        value={row.othChargeCurrCode}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'othChargePercent')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.otherChargesPercent}
                                                        value={row.othChargePercent}
                                                    />
                                                </Grid>

                                                {/* Insurance */}
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'insChargeAmt')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.insuranceAmount}
                                                        value={row.insChargeAmt}
                                                    />
                                                </Grid>
                                               <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'insChargeCurrCode')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.insuranceCurrency}
                                                        value={row.insChargeCurrCode}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'insChargePercent')}
                                                >
                                                    <LabelValueVertical
                                                        label={ComplianceTranslatedObject.insurancePercent}
                                                        value={row.insChargePercent}
                                                    />
                                                </Grid>                                   
                                            </Grid>
                                        </Grid>     
                                    </Grid>     
                                </ComponentWrapper>
                            )    
                        })}
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>
    )
}

const ViewInvoiceComponent = Object.freeze({
    FormBody: FormBody
    // initialValues: initialValues
  })
  
  export default ViewInvoiceComponent