import React, { useState, useMemo } from 'react'
import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import Paper from '@material-ui/core/Paper'
import ViewPartyDetailsContext from './ViewPartyDetailsContext.js'
import ViewPartyDetailsForm from './ViewPartyDetailsForm.js'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function ViewPartyDetailsDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  currentPartyInfo
}) {
  const [isSubmitting, setSubmitting] = useState(false)

  const value = useMemo(() => {
    return {
      closeDialog,
      showNotification,
      currentPartyInfo,
      form: {
        isSubmitting,
        setSubmitting
      }
    }
  }, [closeDialog, showNotification, currentPartyInfo, isSubmitting])

  return (
    <ViewPartyDetailsContext.Provider value={value}>
      <CngDialog
        customDialogContent={
          <Paper>
            <ViewPartyDetailsForm />
          </Paper>
        }
        dialogTitle={
          <>
            <b>{'Party'}</b>
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='md'
      />
    </ViewPartyDetailsContext.Provider>
  )
}

export default ViewPartyDetailsDialog
