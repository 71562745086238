import {
  Button,
  AccordionSummary,
  Card,
  Grid,
  Typography,
  IconButton
} from '@material-ui/core'
import React from 'react'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import { components } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText'
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined'
import FitnessCenterSharpIcon from '@material-ui/icons/FitnessCenterSharp'
import ArchiveIcon from '@material-ui/icons/Archive'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: {
      CngSelectField,
      CngRadioGroupField,
      CngSwitchField,
      CngCodeMasterAutocompleteField,
      CngTextField,
      CngDateTimeField
    }
  },
  CngGridItem,
  table: { useDefaultNotification }
} = components

const FormBody = (props) => {

  const ComplianceTranslatedObject = ComplianceTranslationText()

  return (
    <Card>
      <AccordionSummary>
        <Grid container>
          <CngGridItem xs={6} sm={6}>
            <AccordionHeaderComponent
              title={ComplianceTranslatedObject.itemsSummaryTitle}
              hideMandatory
            />
            <br></br>
            <br></br>
          </CngGridItem>

          <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
            <CngGridItem xs={6} sm={6}>
              <Card
                className='inner-card-group'
                style={{
                  backgroundColor: props.isReview ? 'white' : '#f4f4fc'
                }}
              >
                <Grid
                  container
                  alignItems='center'
                  justify='space-between'
                  style={{ paddingRight: '4px' }}
                >
                  <Typography
                    style={{ color: '#a2a2a2' }}
                    variant='body2'
                    align='left'
                  >
                    {ComplianceTranslatedObject.totalOuterPackQuantity}
                  </Typography>
                  <Grid>
                    <Button
                      style={{
                        color: '#8e98b0',
                        backgroundColor: 'white',
                        maxWidth: '230px',
                        maxHeight: '25px',
                        minWidth: '30px',
                        minHeight: '25px',
                        marginRight: '5px'
                      }}
                      disabled={true}
                    >
                      {props.outPackUom}
                    </Button>
                    <IconButton
                      style={{
                        color: '#8e98b0',
                        backgroundColor: '#dbdbe2',
                        width: '30px',
                        height: '25px',
                        borderRadius: '20%'
                      }}
                      disabled={true}
                    >
                      <ArchiveIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography
                    style={{ color: '#8e98b0' }}
                    variant='h4'
                    align='left'
                  >
                    {props.totalOutPackQuantity}
                  </Typography>
                </Grid>
              </Card>
            </CngGridItem>

            <CngGridItem xs={6} sm={6}>
              <Card
                className='inner-card-group'
                style={{
                  backgroundColor: props.isReview ? 'white' : '#f4f4fc'
                }}
              >
                <Grid
                  container
                  alignItems='center'
                  justify='space-between'
                  style={{ paddingRight: '4px' }}
                >
                  <Typography
                    style={{ color: '#a2a2a2' }}
                    variant='body2'
                    align='left'
                  >
                    {ComplianceTranslatedObject.totalGrossWeight}
                  </Typography>
                  <Grid>
                    <Button
                      style={{
                        color: '#8e98b0',
                        backgroundColor: 'white',
                        maxWidth: '70px',
                        maxHeight: '25px',
                        minWidth: '35px',
                        minHeight: '25px',
                        marginRight: '5px'
                      }}
                      disabled={true}
                    >
                      TONNES
                    </Button>
                    <IconButton
                      style={{
                        color: '#8e98b0',
                        backgroundColor: '#dbdbe2',
                        width: '30px',
                        height: '25px',
                        borderRadius: '20%'
                      }}
                      disabled={true}
                    >
                      <FitnessCenterSharpIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography
                    style={{ color: '#8e98b0' }}
                    variant='h4'
                    align='left'
                  >
                    {props.totalGrossWeight}
                  </Typography>
                </Grid>
              </Card>
            </CngGridItem>

            <CngGridItem xs={6} sm={6}>
              <Card
                className='inner-card-group'
                style={{
                  backgroundColor: props.isReview ? 'white' : '#f4f4fc'
                }}
              >
                <Grid
                  container
                  alignItems='center'
                  justify='space-between'
                  style={{ paddingRight: '4px' }}
                >
                  <Typography
                    style={{ color: '#a2a2a2' }}
                    variant='body2'
                    align='left'
                  >
                    {ComplianceTranslatedObject.totalAmount}
                  </Typography>
                  <Grid>
                    <Button
                      style={{
                        color: '#8e98b0',
                        backgroundColor: 'white',
                        maxWidth: '40px',
                        maxHeight: '25px',
                        minWidth: '30px',
                        minHeight: '25px',
                        marginRight: '5px'
                      }}
                      disabled={true}
                    >
                      SGD
                    </Button>
                    <IconButton
                      style={{
                        color: '#8e98b0',
                        backgroundColor: '#dbdbe2',
                        width: '30px',
                        height: '25px',
                        borderRadius: '20%'
                      }}
                      disabled={true}
                    >
                      <LocalAtmOutlinedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography
                    style={{ color: '#8e98b0' }}
                    variant='h4'
                    align='left'
                  >
                    {props.totalCifFobAmount}
                  </Typography>
                </Grid>
              </Card>
            </CngGridItem>
          </Grid>
        </Grid>
      </AccordionSummary>
    </Card>
  )
}

const ItemSummaryComponent = Object.freeze({
  FormBody: FormBody
})

export default ItemSummaryComponent
