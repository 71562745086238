import { Box, Card, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { components, useServices } from 'cng-web-lib'
import ShippingInstructionTranslationText from '../../shippinginstruction/ShippingInstructionTranslationText'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import FreightBookingTranslationText from 'src/views/freightbooking/shared/FreightBookingTranslationText'
import SharedServiceApi from 'src/views/common/api/SharedServiceApi'
import Utils from 'src/views/common/utils/Utils'
import { v4 as uuid } from 'uuid'
import ComponentWrapper from 'src/views/freightbooking/components/ComponentWrapper'

const {
    form: {
      field: { CngSwitchField, CngTextField, CngCodeMasterAutocompleteField }
    },
    CngGridItem
  } = components
  

const FormBody = (props) => {

    const { fetchRecords } = useServices()
    const [setContainerTypes] = useState([])
    const frbTranslatedTextsObject = FreightBookingTranslationText()
    const translatedTextsObjects = ShippingInstructionTranslationText()
    let ComplianceCargoList = props.containers

    console.log('ViewContainerDetailComponent containerList:', ComplianceCargoList)

    const getContainerTotalCount = () => {
        let totalCount = 0
        if (ComplianceCargoList != null && ComplianceCargoList.length > 0) {
          totalCount = ComplianceCargoList.length
        }
    
        return totalCount
    }
    
    function onContainerTypeLoadSuccess(data) {
        setContainerTypes(data)
        console.log(data)
    }

    useEffect(() => {
        SharedServiceApi.fetchContainerType(
          fetchRecords,
          false,
          onContainerTypeLoadSuccess
        )
    }, [props])

    const initialHighLight = [
        {
          id:0,
          container: {},
          ctnNo: {},
          ctnTypeSize: {},
          ctnWtValue: {},
          ctnSealNo: {},
          blanketStartDate: {}
        }
    ]

    const [highlight] = useState(initialHighLight)

    function getHighlight(id, field) {
        let matched = highlight.find((hl) => hl?.id == id)
    
        return Utils.isNotEmpty(matched) ? matched[field] : {}
    }

    return(

        <Grid container xs={12} sm={12}>
            <CngGridItem xs={12} sm={12}>
                <Card>
                    <Box className='view-card-header'>
                        {translatedTextsObjects.containerDetails}
                        <span className='view-card-header-info'>
                        {' '}
                        / {getContainerTotalCount()} {frbTranslatedTextsObject.containers}
                        </span>
                    </Box>

                    <Box>
                        {ComplianceCargoList.map((row) =>{
                            const rowKey = uuid() 

                            return(
                                <ComponentWrapper
                                    key={rowKey} // Add key prop with a unique identifier
                                    headerStyle='view-card-header-no-bg'
                                    cardStyle={{ margin: 20 }}
                                    backgroundStyle='inner-card-background'
                                >

                                    <Grid
                                        container
                                        xs={12}
                                        sm={12}
                                        spacing={0}
                                        alignItems='top'
                                        style={{
                                        height: '100%',
                                        }}
                                    >
                                        <Grid item xs={2} sm={2} spacing={0} >
                                                <div>
                                                    <LabelValueVertical
                                                        label={translatedTextsObjects.containerSizeTypeHeight}
                                                        value={row.containerIsoSizeType ? row.containerIsoSizeType : row.ctnTypeSize}
                                                    />
                                                </div>
                                        </Grid>

                                        <Grid item xs={10} sm={10}>
                                            <Grid container xs={12} sm={12}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'ctnNo')}
                                                >
                                                    <LabelValueVertical
                                                        label={translatedTextsObjects.containerNo}
                                                        value={row.containerNo ? row.containerNo : row.ctnNo}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'ctnSize')}
                                                >
                                                    <LabelValueVertical
                                                        label={translatedTextsObjects.size}
                                                        value={
                                                            row.containerIsoSizeType ? row.containerIsoSizeType.substring(3) : row.ctnTypeSize.substring(3)
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'ctnType')}
                                                >
                                                    <LabelValueVertical
                                                        label={translatedTextsObjects.type}
                                                        value={ 'Full container load' }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'ctnHeght')}
                                                >
                                                    <LabelValueVertical
                                                        label={translatedTextsObjects.heightType}
                                                        value={'Standard'}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'sealNo')}
                                                >
                                                    <LabelValueVertical
                                                        label={translatedTextsObjects.sealNo}
                                                        value={row.ctnSealNo ? row.ctnSealNo : row.sealNo}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'ctnType')}
                                                />
                                               <Grid
                                                    item
                                                    xs={12}
                                                    sm={2}
                                                    style={getHighlight(row.contBoxId, 'tareWeight')}
                                                >
                                                    <LabelValueVertical
                                                        label={translatedTextsObjects.containerGrossWeight}
                                                        value={row.ctnWtValue ? row.ctnWtValue : row.grossWeight}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>     
                                    </Grid>     
                                </ComponentWrapper>
                            )    
                        })}
                    </Box>
                </Card>
            </CngGridItem>
        </Grid>
    )
}

const ViewContainerComponent = Object.freeze({
    FormBody: FormBody
    // initialValues: initialValues
  })
  
  export default ViewContainerComponent