import { Chip, Typography } from '@material-ui/core'
import React from 'react'

const LabelAndChipComponent = (props) => {
  const { title, code, name } = props

  const portDetailsTemplate = function () {
    return (
      <div className={`cng-summary-card--country-value`}>
        <Typography className={`field-label`}>{title}</Typography>
        <div className={`field-country-details`}>
          <label className={`field-value dark`}>
            {name}
          </label>
          <Chip
            label={<b style={{ fontSize: 10 }}>{code}</b>}
            size='small'
            sizeSmall
            style={{ marginLeft: 5 }}
            className={`field-country-code`}
          />
        </div>
      </div>
    )
  }

  return portDetailsTemplate()
}

export default LabelAndChipComponent
