import React, { useContext, useEffect, useRef } from 'react'
import Box from '@material-ui/core/Box'
import CancelButton from 'src/components/button/CancelButton.js'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ItemDetailsContext from './ItemDetailsContext.js'
import CountrySelectAutoComplete from 'src/views/common/ui/CountrySelectAutoComplete'
import {
  Grid,
  Card,
  AccordionSummary
} from '@material-ui/core'
import useComplianceItemsValidationSchema from '../shared/validation/useComplianceItemsValidationSchema.js'
import useComplianceInItemsValidationSchema from '../shared/validation/useComplianceInItemsValidationSchema.js'
import ResetButton from 'src/components/button/ResetButton'
import { components } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText.js'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import ShippingInstructionTranslationText from '../../shippinginstruction/ShippingInstructionTranslationText'
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'

import { v4 as uuid } from 'uuid'

const {
  form: { CngViewForm },
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: { CngTextField, CngPackageUomAutocompleteField }
  },

  CngGridItem,
  button: { CngPrimaryButton }
} = components

function ItemDetailsForm(props) {
  const dialogContext = useContext(ItemDetailsContext)

  const {
    closeDialog,
    form: { isSubmitting },
    currentRowIdx,
    itemListField,
    setItemListField
  } = dialogContext

  function onSubmit(data) {
    console.log('submit2 ', data)
    console.log('itemListField', itemListField)
    if (currentRowIdx == null) {
      //add new items in the list
      let newList = itemListField.push(data)
      console.log('newList', newList)
    } else {
      //update existing item
      itemListField[currentRowIdx] = data
    }

    setItemListField(itemListField)

    closeDialog()
  }

  const validationSchema = useComplianceItemsValidationSchema()
  const inValidationSchema = useComplianceInItemsValidationSchema()

  return (
    <CngViewForm
      formikProps={{
        initialValues: { ...initialValues },
        validationSchema: props.inPermit? inValidationSchema : validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          currentRowIdx={currentRowIdx}
          itemListField={itemListField}
          setItemListField={setItemListField}
          inPermit={props.inPermit}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({
  closeDialog,
  isSubmitting,
  currentRowIdx,
  itemListField,
  setItemListField,
  inPermit
}) {
  const siTranslatedTextsObject = ShippingInstructionTranslationText()
  const translatedTextsObject = ComplianceTranslationText()
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const partyTranslatedTextObject = CompanyPreferenceTranslationText()

  const { setFieldValue, submitForm } = useFormikContext()
  const isEdit = useRef(false)
  const key = useRef(uuid())

  //Reset to keep data from si
  const resetForm = () => {
    const dataArr = Object.entries(DEFAULT_INITIAL_VALUES)
    dataArr.forEach(([key, val]) => {
      // console.log('key: ' + key + ' val: ' + val)
      setFieldValue(key, val)
    })
  }

  useEffect(() => {
    console.log('currentRowIdx', currentRowIdx)
    if (currentRowIdx != null) {
      populateFormFields()
    }
    key.current = uuid()
  }, [currentRowIdx])

  function populateFormFields() {
    console.log('populateFormFields', itemListField[currentRowIdx])
    const dataArr = Object.entries(itemListField[currentRowIdx])
    dataArr.forEach(([key, val]) => {
      // console.log("key: " + key + " val: " + val)

      setFieldValue(key, val)
    })
    isEdit.current = true
  }

  return (
    <Box m={1.5}>
      <Card>
        <AccordionSummary>
          <Grid container xs={12} sm={12}>
            <CngGridItem xs={6} sm={6}>
              <AccordionHeaderComponent title={'Item Information'} />
            </CngGridItem>
            <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
              <CngGridItem xs={8} sm={8}>
                <CngTextField
                  type='text'
                  name='itemDesc'
                  label={siTranslatedTextsObject.cargoDesc}
                  inputProps={{ maxLength: 50 }}
                  isRequired
                ></CngTextField>
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='hsCode'
                  label={siTranslatedTextsObject.hsCode}
                  inputProps={{ maxLength: 50 }}
                  isRequired
                ></CngTextField>
              </CngGridItem>

              <CngGridItem xs={4} sm={4}>
                <CountrySelectAutoComplete
                  name='ctyOrigin'
                  label={partyTranslatedTextObject.issuePlace}
                  key={key.current}
                  required
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='brand'
                  label={translatedTextsObject.brandName}
                  inputProps={{ maxLength: 50 }}
                  isRequired = {inPermit}
                ></CngTextField>
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='model'
                  label={translatedTextsObject.model}
                  inputProps={{ maxLength: 50 }}
                />
              </CngGridItem>
            
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='number'
                  name='hsQtyValue'
                  label={translatedTextsObject.hsQuantity}
                  inputProps={{ maxLength: 20 }}
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngPackageUomAutocompleteField
                  name='hsQtyUom'
                  label={translatedTextsObject.hsUnit}
                  key={key.current}
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='number'
                  name='grossWeight'
                  label={siTranslatedTextsObject.grossWeightT}
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='number'
                  name='cifFobAmt'
                  label={translatedTextsObject.itemsAmount}
                  inputProps={{ maxLength: 20 }}
                  isRequired
                />
              </CngGridItem>
              <CngGridItem xs={4} sm={4}>
                <CngTextField
                  type='text'
                  name='shippingMark'
                  label={siTranslatedTextsObject.marksAndNos}
                  inputProps={{ maxLength: 17 }}
                />
              </CngGridItem>
            </Grid>
          </Grid>
        </AccordionSummary>
      </Card>

      <Box display='flex' mt={2}>
        <Grid xs={6} sm={6} justify='flex-start'>
          <ResetButton onClick={resetForm} disabled={isSubmitting} />
        </Grid>

        <Grid container xs={6} sm={6} justify='flex-end'>
          <Box pr={2}>
            <CancelButton onClick={closeDialog} disabled={isSubmitting} />
          </Box>
          <Box>
            <CngPrimaryButton
              type='button'
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {uiTranslatedTextsObject.saveChanges}
            </CngPrimaryButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({
  ctyOrigin: '',
  brand: '',
  model: '',
  dutQtyValue: '',
  dutQtyUom: '',
  cifFobAmt: '',
  shippingMark: '',
  hsCode: '',
  hsQtyValue: '',
  grossWeight: '',
  hsQtyUom: ''
})

const initialValues = {
  ...DEFAULT_INITIAL_VALUES
}

export default ItemDetailsForm
