import React, { useContext, useEffect, useRef, useState } from 'react'
import Box from '@material-ui/core/Box'
import CalistaUiComponentTranslationText from 'src/views/common/CalistaUiComponentTranslationText'
import ViewPartyDetailsContext from './ViewPartyDetailsContext.js'
import { Grid, Card} from '@material-ui/core'
import { components } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText.js'
import { v4 as uuid } from 'uuid'
import CompanyPreferenceTranslationText from 'src/views/company-preference/shared/CompanyPreferenceTranslationText'
import LabelValueVertical from 'src/views/freightbooking/components/LabelValueVertical'
import CodeMasterViewField from 'src/components/field/CodeMasterViewField'
import CountryIconViewField from 'src/components/field/CountryIconViewField'

const {
  form: { CngViewForm },
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    },
    field: {
      CngTextField,
      CngPackageUomAutocompleteField,
      CngCodeMasterAutocompleteField
    }
  },
  CngGridItem,
  button: { CngPrimaryButton }
} = components

function ViewPartyDetailsForm(props) {
  const dialogContext = useContext(ViewPartyDetailsContext)

  const {
    closeDialog,
    currentPartyInfo,
    form: { isSubmitting }
  } = dialogContext

  function onSubmit() {
    //do nothing for onsubmit
    closeDialog()
  }

  return (
    <CngViewForm
      formikProps={{
        initialValues: {},
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      bodySection={
        <FormBody
          closeDialog={closeDialog}
          isSubmitting={isSubmitting}
          currentPartyInfo={currentPartyInfo}
        />
      }
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({ isSubmitting, currentPartyInfo }) {
  const uiTranslatedTextsObject = CalistaUiComponentTranslationText()
  const translatedTextsObject = ComplianceTranslationText()
  const partyTranslatedTextObject = CompanyPreferenceTranslationText()
  const { submitForm } = useFormikContext()
  const key = useRef(uuid())

  const [showContactField, setShowContactField] = useState(false)
  const [showCityCode, setShowCityCode] = useState(false) 
  const [showCRNo, setShowCRNo] = useState(true)
  const [showClaimantField, setShowClaimantField] = useState(false)

  function setShowHideField(currentPartyInfo){

    let isDeclarantShown = (currentPartyInfo.partyType == 'Declarant')
    let isCityCodeShown = (currentPartyInfo.partyType == 'Exporter' || currentPartyInfo.partyType == 'Consignee' || currentPartyInfo.partyType == 'End User' || currentPartyInfo.partyType == 'Manufacturer')
    let isConsignee = (currentPartyInfo.partyType == 'Consignee')
    let isClaimant = (currentPartyInfo.partyType =='Claimant')

    if (isDeclarantShown) {
      setShowContactField(true)
    } else {
      setShowContactField(false)
    }

    if (isCityCodeShown) {
      setShowCityCode(true)
    }
    else {
      setShowCityCode(false)
    }

    if(isConsignee){
      setShowCRNo(false)
    }
    else{
      setShowCRNo(true)
    }
    if(isClaimant){
      setShowClaimantField(true)
    }else {
      setShowClaimantField(false)
    }
  }

  useEffect(() => {
    console.log('currentPartyInfo', currentPartyInfo)

    setShowHideField(currentPartyInfo)

    key.current = uuid()
  }, [currentPartyInfo])

  return (
    <Box m={1.5}>
      <Card>
        <Grid container xs={12} sm={12}>
          <CngGridItem xs={12} sm={12}>
            <Box className='view-card-header'>
              <Grid container xs={12} sm={12} spacing={0}>
                <Grid xs={6} sm={6} container justify='flex-start'>
                  {'Party Information'}
                </Grid>
              </Grid>
            </Box>
          </CngGridItem>

          <Grid container xs={12} sm={12} spacing={2} alignItems='center'>
            <CngGridItem xs={4} sm={4}>
              <Grid container justify='space-between'>
                <Grid item xs={12} sm={12}>
                  <Box className='view-card-content-label'>
                    {translatedTextsObject.partyTypeDetails}
                  </Box>
                </Grid>
                <Box className='view-card-content-value'>
                  <CodeMasterViewField
                    codeType='CAL_COMP_PARTY_TYPE'
                    code={currentPartyInfo.partyType}
                  />
                </Box>
              </Grid>
            </CngGridItem>

            {showCRNo &&(
              <CngGridItem xs={4} sm={4}>
                <LabelValueVertical
                  label={translatedTextsObject.crNoDetails}
                  value={currentPartyInfo.crno}
                />
              </CngGridItem>
            )}

            <CngGridItem xs={4} sm={4}>
              <LabelValueVertical
                label={translatedTextsObject.nameDetails1}
                value={currentPartyInfo.name}
              />
            </CngGridItem>

            {!showContactField && (
              <CngGridItem xs={4} sm={4}>
                <LabelValueVertical
                  label={translatedTextsObject.nameDetails1}
                  value={currentPartyInfo.name2}
                />
              </CngGridItem>
            )}

            {showContactField && (
              <CngGridItem xs={4} sm={4}>
                <LabelValueVertical
                  label={translatedTextsObject.phoneNoDetails}
                  value={currentPartyInfo.phone}
                />
              </CngGridItem>
            )}   

            {(showCityCode) && (
              <>
                <CngGridItem xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObject.addressDetails1}
                    value={currentPartyInfo.address}
                  />
                </CngGridItem>

                <CngGridItem xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObject.addressDetails1}
                    value={currentPartyInfo.address2}
                  />
                </CngGridItem>

                <CngGridItem xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObject.cityDetails}
                    value={currentPartyInfo.city}
                  />
                </CngGridItem>

                <CngGridItem xs={4} sm={4}>
                  <Box className='view-card-content-label'>
                    {partyTranslatedTextObject.country}
                  </Box>
                  <CountryIconViewField countryCode={currentPartyInfo.country} />
                </CngGridItem>

                <CngGridItem xs={4} sm={4}>
                  <LabelValueVertical
                    label={partyTranslatedTextObject.postalCode}
                    value={currentPartyInfo.postalCode}
                  />
                </CngGridItem>
              </>
            )}

            {showClaimantField && (
                <>
                <CngGridItem xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObject.personCode}
                    value={currentPartyInfo.personCode}
                  />
                </CngGridItem>

                <CngGridItem xs={4} sm={4}>
                  <LabelValueVertical
                    label={translatedTextsObject.personName}
                    value={currentPartyInfo.personName}
                  />
                </CngGridItem>
                </>
              )}
          </Grid>
        </Grid>
      </Card>

      <Box display='flex' mt={2}>
        <Grid xs={6} sm={6} justify='flex-start'></Grid>

        <Grid container xs={6} sm={6} justify='flex-end'>
          <Box>
            <CngPrimaryButton
              type='button'
              disabled={isSubmitting}
              onClick={submitForm}
            >
              {uiTranslatedTextsObject.ok}
            </CngPrimaryButton>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyType: '',
  crno: '',
  name: '',
  name2: '',
  address: '',
  address2: '',
  city: '',
  country: '',
  state: '',
  postalCode: ''
})

const initialValues = {
  ...DEFAULT_INITIAL_VALUES
}

export default ViewPartyDetailsForm
