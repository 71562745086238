import { Card, CardContent, Grid, Box } from '@material-ui/core'
import { components } from 'cng-web-lib'
import { default as React } from 'react'
import AccordionHeaderComponent from 'src/views/common/ui/AccordionHeaderComponent'
import ComplianceTranslationText from '../ComplianceTranslationText'
import DeclarantMappingTable from './DeclarantMappingTable'

const {
    form: {
        field: { CngTextField, CngSelectField, CngLookupAutocompleteField },
        adapter: {
            useFormAdapter: { useFormikContext }
        }
    },
    CngGridItem
} = components

const DEFAULT_INITIAL_VALUES = {
    id: "",
    name: ""
}

//* ----------------------------------------------------------------------------
const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
}

//* Fields function ------------------------------------------------------------
function Fields({ ...props }) {
    const { disabled } = props;
    const fieldsTranslatedTextObject = ComplianceTranslationText();
    const partyId = props.id;
    const showNotification = props.showNotification

    return (
        <>
            <Grid container spacing={3}>
                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <Box pb={3} style={{ width: '100%' }}>
                                    <AccordionHeaderComponent title={fieldsTranslatedTextObject.declarantMaintenancePartyDetails} hideMandatory />
                                </Box>
                                <Grid container spacing={2}>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            name="name"
                                            disabled={disabled}
                                            label={fieldsTranslatedTextObject.declarantMaintenancePartyName}
                                        />
                                    </CngGridItem>
                                    <CngGridItem xs={12} sm={4}>
                                        <CngTextField
                                            name="id"
                                            label={fieldsTranslatedTextObject.declarantMaintenancePartyId}
                                            disabled={disabled}
                                        />
                                    </CngGridItem>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </CngGridItem>

                <CngGridItem xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container>
                                <AccordionHeaderComponent title={fieldsTranslatedTextObject.mappedDeclarants} hideMandatory />
                                <DeclarantMappingTable
                                    partyId={partyId}
                                    showNotification={showNotification}
                                />
                            </Grid>
                        </CardContent>
                    </Card>

                </CngGridItem>

            </Grid>
        </>

    )
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
})

export default FormProperties


