import {
  Box,
  Card,
  Grid,
  Button,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
import { components } from 'cng-web-lib'
import ComplianceTranslationText from '../ComplianceTranslationText'
import PartyDetailsDialog from './PartyDetailsDialog'
import ViewPartyDetailsDialog from './ViewPartyDetailsDialog'
import AccordionHeaderComponent from '../../common/ui/AccordionHeaderComponent'
import PartiesTable from './PartiesTable'
import Utils from '../shared/Utils'
import { useFormContext } from 'react-hook-form'

const {
  form: {
    adapter: {
      useFormAdapter: { useField, useFormikContext }
    }
  },
  table: { useDefaultNotification }
} = components


const initialValues = {
  shippingInfoContainerBoxes: [
    {
      containerId: '',
      containerNo: '',
      sealNo: '',
      containerIsoSizeType: '',
      grossWeight: ''
    }
  ]
}

const FormBody = (props) => {
  const { watch } = useFormContext()
  const { setFieldValue } = useFormikContext()
  const [isViewPartyDetailsDialogOpen, setViewPartyDetailsDialogOpen] = useState(false)

  const [isPartyDetailsDialogOpen, setPartyDetailsDialogOpen] = useState(false)
  const translatedTextsObject = ComplianceTranslationText()
  const currentRowIdx = useRef(0)
  const [rerender, setRerender] = useState(false)
  
  const [isPaymentField] = useField('payment')
  const isPaymentWatch = watch('payment')
  const [withPay, setWithPay] = useState(false)

  //useField containers for handle formik valiation and submission
  const [siCompliancePartyField, ,] = useField('shippingInfoComplianceParty')
  const [declarantNameField, ,] = useField('declarantName')

  //useRef containerList for handle containerList dynamically
  const compliancePartyList = useRef([])

  const editItemRow = (idx) => (e) => {
    console.log('editItemRow', idx)
    currentRowIdx.current = idx
    setPartyDetailsDialogOpen(true)
    e.stopPropagation()
  }

  const removeItemRow = (idx) => (e) => {
    console.log('removeItemRow', idx)
    let newList = compliancePartyList.current
    newList.splice(idx, 1)
    console.log('newList', newList)
    compliancePartyList.current = newList
    //set field value
    setRerender(!rerender)
    e.stopPropagation()
  }

  function setPartyListField(list) {
    compliancePartyList.current = list
    setFieldValue('shippingInfoCompliancePartyList', list )
  }

  const handleRowClick = (idx) => () => {
    currentRowIdx.current = idx
    setViewPartyDetailsDialogOpen(true)
  }

  function closeViewDialog() {
    setViewPartyDetailsDialogOpen(false)
  }

  function closeDialog() {
    setPartyDetailsDialogOpen(false)
  }

  function assignPartyFromAPI(){
    compliancePartyList.current = [];
    
    console.log("assignPartyFromAPI",siCompliancePartyField.value)
    if(siCompliancePartyField?.value?.declarantPersonName != null){
      let party = {
        partyType : Utils.partyType.declarant,
        name : siCompliancePartyField?.value?.declarantPersonName,
        crno: siCompliancePartyField?.value?.declarantPersonCode,
        phone: siCompliancePartyField.value?.declarantPersonPhone
      }

      compliancePartyList.current.push(party)
    }
    if(siCompliancePartyField?.value?.declaringAgentName1 != null){
      let party10 = {
        partyType : Utils.partyType.declaringAgent,
        name : siCompliancePartyField?.value?.declaringAgentName1,
        name2: siCompliancePartyField?.value?.declaringAgentName2, 
        crno: siCompliancePartyField?.value?.declaringAgentCrNo,
      }

      compliancePartyList.current.push(party10)
    }
    if(siCompliancePartyField?.value?.freightForwarderName1 != null){
      let party1 = {
        partyType : Utils.partyType.freightForwarder,
        name : siCompliancePartyField?.value?.freightForwarderName1,
        name2: siCompliancePartyField?.value?.freightForwarderName2, 
        crno: siCompliancePartyField?.value?.freightForwarderCrNo,
      }

      compliancePartyList.current.push(party1)
    }
    if(siCompliancePartyField?.value?.inCarrierAgentName1 != null){
      let party2 = {
        partyType : Utils.partyType.inCarrierAgent,
        name : siCompliancePartyField?.value?.inCarrierAgentName1,
        name2: siCompliancePartyField?.value?.inCarrierAgentName2, 
        crno: siCompliancePartyField?.value?.inCarrierAgentCrNo,
      }

      compliancePartyList.current.push(party2)
    }
    if(siCompliancePartyField?.value?.importerName1 != null){
      let party3 = {
        partyType : Utils.partyType.importer,
        name : siCompliancePartyField?.value?.importerName1,
        name2: siCompliancePartyField?.value?.importerName2, 
        crno: siCompliancePartyField?.value?.importerCrNo,
      }

      compliancePartyList.current.push(party3)
    }
    if(siCompliancePartyField?.value?.outCarrierAgentName1 != null){
      let party4 = {
        partyType : Utils.partyType.outCarrierAgent,
        name : siCompliancePartyField?.value?.outCarrierAgentName1,
        name2: siCompliancePartyField?.value?.outCarrierAgentName2, 
        crno: siCompliancePartyField?.value?.outCarrierAgentCrNo,
      }

      compliancePartyList.current.push(party4)
    }
    if(siCompliancePartyField?.value?.exporterName1 != null){
      let party5 = {
        partyType: Utils.partyType.exporter,
        crno: siCompliancePartyField?.value?.exporterCrNo,
        name: siCompliancePartyField?.value?.exporterName1,
        address: siCompliancePartyField?.value?.exporterAddress1,
        name2: siCompliancePartyField?.value?.outCarrierAgentName2,
        address2: siCompliancePartyField?.value?.exporterAddress2,

        city: siCompliancePartyField?.value?.exporterCity,
        postalCode: siCompliancePartyField?.value?.exporterPstCode,
        country: siCompliancePartyField?.value?.exporterCtyCode
      }

      compliancePartyList.current.push(party5)
    }
    if(siCompliancePartyField?.value?.consigneeName1 != null){
      let party6 = {
        partyType: Utils.partyType.consignee,
        name: siCompliancePartyField?.value?.consigneeName1,
        address: siCompliancePartyField?.value?.consigneeAddress1,
        name2: siCompliancePartyField?.value?.consigneeName2,
        address2: siCompliancePartyField?.value?.consigneeAddress2,

        city: siCompliancePartyField?.value?.consigneeCity,
        postalCode: siCompliancePartyField?.value?.consigneePstCode,
        country: siCompliancePartyField?.value?.consigneeCtyCode
      }

      compliancePartyList.current.push(party6)
    }
    if(siCompliancePartyField?.value?.endUserName1 != null){
      let party7 = {
        partyType: Utils.partyType.endUser,
        name: siCompliancePartyField?.value?.endUserName1,
        address: siCompliancePartyField?.value?.endUserAddress1,
        name2: siCompliancePartyField?.value?.endUserName2,
        address2: siCompliancePartyField?.value?.endUserAddress2,

        city: siCompliancePartyField?.value?.endUserCity,
        postalCode: siCompliancePartyField?.value?.endUserPstCode,
        country: siCompliancePartyField?.value?.endUserCtyCode
      }

      compliancePartyList.current.push(party7)
    }
    if(siCompliancePartyField?.value?.manufacturerName1 != null){
      let party8 = {
        partyType: Utils.partyType.manufacturer,
        crno: siCompliancePartyField?.value?.manufacturerCrNo,
        name: siCompliancePartyField?.value?.manufacturerName1,
        address: siCompliancePartyField?.value?.manufacturerAddress1,
        name2: siCompliancePartyField?.value?.manufacturerName2,
        address2: siCompliancePartyField?.value?.manufacturerAddress2,

        city: siCompliancePartyField?.value?.manufacturerCity,
        postalCode: siCompliancePartyField?.value?.manufacturerPstCode,
        country: siCompliancePartyField?.value?.manufacturerCtyCode
      }

      compliancePartyList.current.push(party8)
    }
    if(siCompliancePartyField?.value?.claimantName1 != null){
      let party9 = {
        partyType: Utils.partyType.claimant,
        crno: siCompliancePartyField?.value?.claimantCRNo,
        name: siCompliancePartyField?.value?.claimantName1,
        name2: siCompliancePartyField?.value?.claimantName2,

        claimantPersonCode: siCompliancePartyField?.value?.claimantPersonCode,
        claimantPersonName: siCompliancePartyField?.value?.claimantPersonName
      }

      compliancePartyList.current.push(party9)
    }
    setFieldValue('shippingInfoCompliancePartyList', compliancePartyList.current)
  }

  function setOutPermitPartyList(){
    let party = {
      partyType : Utils.partyType.declarant,
      name : declarantNameField.value
    }
    let party2 = {
      partyType : Utils.partyType.declaringAgent
    }
    let party3 = {
      partyType : Utils.partyType.exporter
    }
    let party4 = {
      partyType : Utils.partyType.outCarrierAgent
    }
    let party5 = {
      partyType : Utils.partyType.consignee
    }
    compliancePartyList.current = [party, party2, party3, party4, party5]
    setFieldValue('shippingInfoCompliancePartyList', compliancePartyList.current)
  }

  function setInPermitPartyList(){
    let party = {
      partyType : Utils.partyType.declarant,
      name : declarantNameField.value
    }
    let party2 = {
      partyType : Utils.partyType.declaringAgent
    }
    let party3 = {
      partyType : Utils.partyType.importer
    }
    let party4 = {
      partyType : Utils.partyType.inCarrierAgent
    }
    compliancePartyList.current = [party, party2, party3, party4]
    setFieldValue('shippingInfoCompliancePartyList', compliancePartyList.current)
  }

  useEffect(() => {
  console.log('Is Payment value: ', isPaymentField.value)

  if (isPaymentField.value == true) {
    setWithPay(true)
  } else {
    setWithPay(false)
  }

  console.log('isEdit ', props.isEdit)
  if (props.isEdit) {
    /**
     * there are 2 scenarios
     * 1. This is a new permit created from si, party list should be declarant only
     *
     * 2. This is a created permit, party list from the field itself
     */
    if (siCompliancePartyField.value == undefined) {
      if (!props.inPermit) {
        console.log("setOutPermitPartyList")
        setOutPermitPartyList()
      } else if (props.inPermit) {
        setInPermitPartyList()
      }
    } else {
      console.log("assignPartyFromAPI")
      assignPartyFromAPI()
    }
  }
}, [isPaymentWatch, props.isEdit])


  return (
    <Box>
      <Card>
        <Box px={2} my={1.5}>
          <Grid container justify='flex-end'>
            <AccordionHeaderComponent
              title={translatedTextsObject.partiesTitle}
              parties
              hideMandatory
            />

            <Box pr={2}>
              <Button
                variant='contained'
                classes={{ root: 'ng-button-filled-secondary' }}
                className='originalText'
                onClick={() => {
                  setPartyDetailsDialogOpen(true)
                  currentRowIdx.current = null
                }}
              >
                {translatedTextsObject.addParty}
              </Button>
            </Box>
          </Grid>
        </Box>

        <PartiesTable
          list={compliancePartyList.current}
          removeRowClick={removeItemRow}
          editRowClick={editItemRow}
          handleRowClick={handleRowClick}
          editMode={true}
          rerender={rerender}
          partiesData={props.partiesData}
        />

        <PartyDetailsDialog
          isDialogOpen={isPartyDetailsDialogOpen}
          closeDialog={closeDialog}
          showNotification={props.showNotification}
          currentRowIdx={currentRowIdx.current}
          partyListField={compliancePartyList.current}
          setPartyListField={setPartyListField}
        />

        <ViewPartyDetailsDialog
          isDialogOpen={isViewPartyDetailsDialogOpen}
          closeDialog={closeViewDialog}
          showNotification={props.showNotification}
          currentPartyInfo={compliancePartyList.current[currentRowIdx.current]}
        />
      </Card>
    </Box>
  )
}

const PartiesComponent = Object.freeze({
  FormBody: FormBody,
  initialValues: initialValues
})

export default PartiesComponent
