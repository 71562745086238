import useComplianceCargoValidationSchema from "../shared/validation/useComplianceCargoValidationSchema"
import useCompliancePermitTypeValidationSchema from "../shared/validation/useCompliancePermitTypeValidationSchema"
import useComplianceLicenceValidationSchema from "../shared/validation/useComplianceLicenceValidationSchema.js.js"
import useComplianceContainerValidationSchema from "../shared/validation/usComplianceContainerValidationSchema"
import useComplianceInvoiceValidationSchema from "../shared/validation/useComplianceInvoiceValidationSchema"

function useCreatePermitMakeValidationInPayBlanketSchema() {
    return useCompliancePermitTypeValidationSchema()
        .concat(useComplianceCargoValidationSchema())
        .concat(useComplianceContainerValidationSchema())
        .concat(useComplianceLicenceValidationSchema())
        .concat(useComplianceInvoiceValidationSchema())
}
export default useCreatePermitMakeValidationInPayBlanketSchema